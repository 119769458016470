<template>
    <section class="edit-point">
        <form
            class="edit-point-form"
            @submit="
                (e) => {
                    e.preventDefault();
                    handleSavePointClick();
                }
            "
        >
            <button
                class="edit-point-form__exit-btn"
                @click="
                    (e) => {
                        e.preventDefault();
                        this.$emit('closeEdit');
                    }
                "
            >
                <img
                    src="../../../../../assets/icons/components/exit-btn.svg"
                />
            </button>
            <section class="edit-point-form__form-wrapper">
                <h2 class="edit-point-form__title">
                    {{ isAdd ? "Dodaj" : "Edytuj" }} punkt
                </h2>
                <section class="edit-point-form__row">
                    <label class="edit-point-form__label">Nazwa punktu</label>
                    <input
                        type="text"
                        placeholder="Wpisz nazwę punktu..."
                        class="edit-point-form__input"
                        v-model="name"
                        v-error="{ errors, input_key: 'name' }"
                    />
                    <ErrorMessage
                        v-if="isError('name', this.errors)"
                        :errorMsgs="passErrorMsg('name', this.errors)"
                    />
                </section>
                <section class="edit-point-form__row">
                    <label class="edit-point-form__label">Dotyczy</label>
                    <input
                        type="text"
                        placeholder="Wpisz czego dotyczy..."
                        class="edit-point-form__input"
                        v-model="description"
                        v-error="{ errors, input_key: 'description' }"
                    />
                    <ErrorMessage
                        v-if="isError('description', this.errors)"
                        :errorMsgs="passErrorMsg('description', this.errors)"
                    />
                </section>
                <section class="edit-point-form__section-double">
                    <section class="edit-point-form__col-double">
                        <label class="edit-point-form__label"
                            >Wnioskodawca</label
                        >
                        <input
                            type="text"
                            placeholder="Wpisz imię i nazwisko..."
                            class="edit-point-form__input"
                            v-model="reporter_name"
                            v-error="{ errors, input_key: 'reporter_name' }"
                        />
                        <ErrorMessage
                            v-if="isError('reporter_name', this.errors)"
                            :errorMsgs="
                                passErrorMsg('reporter_name', this.errors)
                            "
                        />
                    </section>
                    <section class="edit-point-form__col-double">
                        <label class="edit-point-form__label">Referuje</label>
                        <input
                            type="text"
                            placeholder="Wpisz imię i nazwisko..."
                            class="edit-point-form__input"
                            v-model="applicant_name"
                            v-error="{ errors, input_key: 'applicant_name' }"
                        />
                        <ErrorMessage
                            v-if="isError('applicant_name', this.errors)"
                            :errorMsgs="
                                passErrorMsg('applicant_name', this.errors)
                            "
                        />
                    </section>
                </section>
                <section class="edit-point-form__point-status">
                    <h4
                        class="
                            edit-point-form__title edit-point-form__title--small
                        "
                    >
                        Status
                    </h4>
                    <section class="edit-point-form__checkbox-top">
                        <section class="edit-point-form__checkbox-wrapper">
                            <input
                                type="checkbox"
                                class="edit-point-form__checkbox"
                                :checked="is_done"
                            />
                            <section class="edit-point-form__checkbox-mark">
                                <img
                                    src="../../../../../assets/icons/components/accept-mark.svg"
                                />
                            </section>
                        </section>
                        <label
                            class="
                                edit-point-form__label
                                edit-point-form__label--checkbox
                            "
                            @click="is_done = !is_done"
                            >Zakończony</label
                        >
                    </section>
                    <section class="edit-point-form__checkbox-top">
                        <section class="edit-point-form__checkbox-wrapper">
                            <input
                                type="checkbox"
                                class="edit-point-form__checkbox"
                                :checked="is_hidden"
                            />
                            <section class="edit-point-form__checkbox-mark">
                                <img
                                    src="../../../../../assets/icons/components/accept-mark.svg"
                                />
                            </section>
                        </section>
                        <label
                            class="
                                edit-point-form__label
                                edit-point-form__label--checkbox
                            "
                            @click="is_hidden = !is_hidden"
                            >Ukryty</label
                        >
                    </section>
                    <section class="edit-point-form__checkbox-top">
                        <section class="edit-point-form__checkbox-wrapper">
                            <input
                                type="checkbox"
                                class="edit-point-form__checkbox"
                                :checked="is_taken_off"
                            />
                            <section class="edit-point-form__checkbox-mark">
                                <img
                                    src="../../../../../assets/icons/components/accept-mark.svg"
                                />
                            </section>
                        </section>
                        <label
                            class="
                                edit-point-form__label
                                edit-point-form__label--checkbox
                            "
                            @click="is_taken_off = !is_taken_off"
                            >Zdjęty z porządku obrad</label
                        >
                    </section>
                </section>
                <section class="edit-point-form__checkbox-section">
                    <h4
                        class="
                            edit-point-form__title edit-point-form__title--small
                        "
                    >
                        Opiniują
                    </h4>
                    <section
                        v-for="group in groups"
                        :key="group.id"
                        class="
                            edit-point-form__checkbox-top
                            edit-point-form__checkbox-top--col
                        "
                    >
                        <CheckBoxButton
                            :id="group.id"
                            :value="group.id"
                            @setGroupId="
                                (value) => (this.opinion_group_ids = value)
                            "
                            :idsArray="opinion_group_ids"
                            :checked="
                                opinion_group_ids.indexOf(group.id) !== -1
                            "
                        />
                        <label
                            class="
                                edit-point-form__label
                                edit-point-form__label--checkbox
                                edit-point-form__label--normal
                            "
                            :for="group.id"
                            >{{ group.name }}</label
                        >
                    </section>
                </section>
                <section class="edit-point-form__checkbox-section">
                    <h4
                        class="
                            edit-point-form__title
                            edit-point-form__title--comission
                            edit-point-form__title--small
                        "
                    >
                        Komisja odpowiedzialna
                    </h4>
                    <section
                        v-for="group in groups"
                        :key="group.id"
                        class="
                            edit-point-form__checkbox-top
                            edit-point-form__checkbox-top--col
                        "
                    >
                        <CheckBoxButton
                            :id="`${group.id}-comision`"
                            :value="group.id"
                            @setGroupId="
                                (value) => (this.responsible_group_ids = value)
                            "
                            :idsArray="responsible_group_ids"
                            :checked="
                                responsible_group_ids.indexOf(group.id) !== -1
                            "
                        />
                        <label
                            class="
                                edit-point-form__label
                                edit-point-form__label--checkbox
                                edit-point-form__label--normal
                            "
                            :for="`${group.id}-comision`"
                            >{{ group.name }}</label
                        >
                    </section>
                </section>
                <section class="edit-point-form__attachments" v-if="!isAdd">
                    <section class="edit-point-form__attachments-top">
                        <h4
                            class="
                                edit-point-form__title
                                edit-point-form__title--small
                                edit-point-form__title--no-padding
                            "
                        >
                            Załączniki
                        </h4>
                        <button
                            class="edit-point-form__upload-btn"
                            @click="
                                (e) => {
                                    e.preventDefault();
                                    $refs.file.click();
                                }
                            "
                        >
                            <img
                                src="../../../../../assets/icons/components/upload-attachment.svg"
                                class="edit-point-form__upload-icon"
                            /><span class="edit-point-form__upload-text"
                                >Dodaj załącznik</span
                            >
                        </button>
                        <input
                            type="file"
                            class="file-input"
                            @change="handleFileUpload"
                            ref="file"
                        />
                    </section>
                    <section v-if="pickedPoint.attachments.length > 0">
                        <Attachemnt
                            v-for="(
                                attachment, index
                            ) in pickedPoint.attachments"
                            :key="index"
                            :attachment="attachment"
                            :pointID="this.pickedPoint.id"
                            @reloadData="this.$emit('reloadData')"
                            @reloadPoint="
                                (data) => {
                                    this.$emit('reloadPoint', data);
                                    this.$emit('reloadData');
                                }
                            "
                        />
                    </section>
                    <section v-else class="edit-point-form__no-attachments">
                        <p>Brak załączników</p>
                    </section>
                </section>
                <section class="edit-point-form__buttons-bottom">
                    <button
                        class="edit-point-form__bottom-button"
                        @click="
                            (e) => {
                                e.preventDefault();
                                this.$emit('closeEdit');
                            }
                        "
                    >
                        Anuluj
                    </button>
                    <button
                        type="submit"
                        class="
                            edit-point-form__bottom-button
                            edit-point-form__bottom-button--red
                        "
                    >
                        Zapisz
                    </button>
                </section>
            </section>
        </form>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import CheckBoxButton from "../../../../BasicControls/CheckBoxButton.vue";
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import Attachemnt from "./Attachment.vue";
import ErrorMessage from "../../../../BasicControls/ErrorMessage.vue";

import MeetingService from "../../../../../services/meetings.service";
import SessionService from "../../../../../services/session.service";
import AttachmentService from "../../../../../services/attachments.service";
import moment from "moment";
import {
    passErrorMsg,
    isError,
    setErrors,
} from "../../../../../formValidation/main";

export default {
    name: "EditPoint",
    data() {
        return {
            name: "",
            description: "",
            reporter_name: "",
            applicant_name: "",
            is_done: false,
            is_hidden: false,
            is_taken_off: false,
            groups_opinion: [],
            groups_responsible: [],
            attachments: [],
            opinion_group_ids: [],
            responsible_group_ids: [],
            groups: [],
            isLoading: false,
            errors: [],
        };
    },
    components: {
        CheckBoxButton,
        LoaderInside,
        Attachemnt,
        ErrorMessage,
    },
    props: {
        pickedPoint: Object,
        isAdd: Boolean,
        order: Number,
    },
    methods: {
        handleSavePointClick() {
            this.errors = [];
            this.isLoading = true;
            if (!this.isAdd) {
                const data = this.pickedPoint;
                data.name = this.name;
                data.is_done = this.is_done;
                data.is_taken_off = this.is_taken_off;
                data.applicant_name = this.applicant_name;
                data.reporter_name = this.reporter_name;
                data.description = this.description;
                data.is_hidden = this.is_hidden;
                data.responsible_group_ids = this.responsible_group_ids;
                data.opinion_group_ids = this.opinion_group_ids;

                if (this.is_done) {
                    data.done_at = moment().format();
                }
                SessionService.editPoint(this.pickedPoint.id, data)
                    .then(() => this.requestSuccess())
                    .catch((err) => this.requestError(err));
            } else {
                const sessionId = this.$route.params.sessionid;
                const data = {
                    name: this.name,
                    is_done: this.is_done,
                    is_taken_off: this.is_taken_off,
                    applicant_name: this.applicant_name,
                    reporter_name: this.reporter_name,
                    description: this.description,
                    is_hidden: this.is_hidden,
                    responsible_group_ids: this.responsible_group_ids,
                    opinion_group_ids: this.opinion_group_ids,
                    order: this.order,
                };
                SessionService.addPoint(sessionId, data)
                    .then(() => this.requestSuccess())
                    .catch((err) => this.requestError(err));
            }
        },
        requestSuccess() {
            this.name = "";
            this.description = "";
            this.reporter_name = "";
            this.applicant_name = "";
            this.is_done = false;
            this.is_hidden = false;
            this.is_taken_off = false;
            this.groups_opinion = [];
            this.groups_responsible = [];
            this.attachments = [];
            this.opinion_group_ids = [];
            this.responsible_group_ids = [];
            this.groups = [];
            this.isLoading = false;
            this.errors = [];
            this.isLoading = false;
            this.$emit("reloadData");
            this.$emit("closeEdit");
        },
        requestError(err) {
            this.isLoading = false;
            this.$emit("reloadData");
            this.errors = setErrors(err);
        },
        handleFileUpload() {
            const file = this.$refs.file.files[0];
            const formData = new FormData();
            formData.append("file", file);
            formData.append("name", file.name);
            formData.append("public", 1);
            formData.append("show_in_register", 1);
            this.$store.commit("showLoader");
            AttachmentService.addFile(this.pickedPoint.id, formData).then(
                () => {
                    SessionService.getPoint(this.pickedPoint.id).then(
                        (response) => {
                            this.isLoading = false;
                            this.$store.commit("hideLoader");
                            this.$emit("reloadData");
                            this.$emit("reloadPoint", response.data);
                        }
                    );
                }
            );
        },
        passErrorMsg,
        isError,
        setErrors,
    },
    mounted() {
        this.isLoading = true;
        MeetingService.groups().then((response) => {
            this.isLoading = false;
            this.groups = response.data.data;
        });
        if (!this.isAdd) {
            if (Object.keys(this.pickedPoint).length > 0) {
                const {
                    name,
                    description,
                    reporter_name,
                    aplicant_name,
                    is_done,
                    is_hidden,
                    is_taken_off,
                    groups_opinion,
                    groups_responsible,
                    attachments,
                } = this.pickedPoint;
                this.name = name;
                (this.description = description),
                    (this.reporter_name = reporter_name),
                    (this.aplicant_name = aplicant_name),
                    (this.is_done = is_done);
                this.is_hidden = is_hidden;
                this.is_taken_off = is_taken_off;
                this.groups_opinion = groups_opinion;
                this.groups_responsible = groups_responsible;
                this.attachments = attachments;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.edit-point {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: #00082c75;
    z-index: 9;
}

.edit-point-form {
    padding: 32px 56px;
    background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
    border-radius: 12px;
    max-height: 90vh;
    padding-bottom: 0px;
    padding-right: 0px;

    &__form-wrapper {
        max-height: 80vh;
        overflow-y: auto;
        padding-right: 56px;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #eef1f7;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #eef1f7;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }
    }

    &__exit-btn {
        border: 0px;
        background-color: transparent;
        position: relative;
        right: calc(-100% + 48px);
    }

    &__row {
        margin-bottom: 24px;
    }

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 128%;
        color: #00082c;
        width: 100%;
        padding-bottom: 32px;
        margin-bottom: 16px;

        &--small {
            font-size: 20px;
            border-bottom: 0px;
            padding-bottom: 16px;
            margin: 0px;
        }

        &--no-padding {
            padding: 0px;
        }

        &--comission {
            width: 100%;
            margin-top: 30px;
            padding-top: 28px;
            border-top: 1px solid #dde0e7;
        }
    }

    &__section-double {
        display: flex;
        justify-content: space-between;
        width: 500px;
    }

    &__col-double {
        width: 238px;
    }

    &__input {
        width: 100%;
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        margin: 8px 0px;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;

        &:focus {
            border: 1px solid #00082c;
        }
    }

    &__label {
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;

        &--checkbox {
            padding-left: 32px;
            z-index: 10;
            cursor: pointer;
            font-weight: normal !important;
        }

        &--normal {
            font-weight: normal !important;
        }
    }

    &__point-status {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid #dde0e7;
        margin-bottom: 24px;
    }

    &__checkbox-top {
        display: flex;
        align-items: center;
        margin-top: 12px;

        &--col {
            margin-bottom: 13px;
            align-items: flex-start;
        }
    }

    &__checkbox {
        width: 0px;
        height: 0px;
        display: none;

        &:checked ~ .edit-point-form__checkbox-mark {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:checked ~ .add-contact-form__label--checkbox {
            padding-left: 0px;
        }
    }

    &__checkbox-wrapper {
        width: 0px;
        height: 24px;
        position: relative;

        &:before {
            content: "";
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 24px;
            height: 24px;
            position: relative;
            display: block;
            top: 0px;
            left: 0px;
            opacity: 1;
        }
    }

    &__checkbox-mark {
        background: #00082c;
        border: 1px solid #00082c;
        box-sizing: border-box;
        box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        width: 24px;
        height: 24px;
        position: absolute;
        z-index: 5;
        display: none;
        top: 0px;
        left: 0px;
        opacity: 1;
    }

    &__buttons-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        padding-bottom: 56px;
    }

    &__bottom-button {
        padding: 18px 40px 17px;
        width: 238px;
        height: 56px;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        color: #00082c;
        background: transparent;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: #e8ebef;
        }

        &--red {
            border: 0px;
            background: #d80418;
            color: white;

            &:hover {
                background: #ad0313 !important;
            }
        }
    }

    &__no-attachments {
        margin-bottom: 34px;
    }

    &__attachments {
        padding-top: 31px;
        border-top: 1px solid #dde0e7;
        margin-top: 26px;
    }

    &__attachments-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 27px;
    }

    &__upload-btn {
        display: flex;
        align-items: center;
        border: 0px;
        padding: 9px 16px;
        background: rgba(220, 228, 238, 0.56);
        border-radius: 4px;
    }

    &__upload-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #00082c;
        white-space: nowrap;
        margin-left: 8px;
    }
}

.file-input {
    display: none;
}
</style>
