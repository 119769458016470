<template>
    <section class="radio-wrapper">
        <input
            type="radio"
            class="radio-wrapper__input"
            :id="id"
            :checked="checked"
            :name="name"
        />
        <section class="radio-wrapper__input-overlay"></section>
    </section>
</template>

<script>
export default {
    name: "InputRadio",
    props: {
        id: String,
        checked: Boolean,
        name: String,
    },
};
</script>

<style lang="scss" scoped>
.radio-wrapper {
    position: relative;
    &__input {
        width: 0px;
        height: 20px;

        &::after {
            content: "";
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 20px;
            height: 20px;
            display: block;
            border-radius: 100%;
            position: absolute;
            cursor: pointer;
        }

        &:checked ~ .radio-wrapper__input-overlay {
            display: flex;
        }
    }

    &__input-overlay {
        width: 20px;
        height: 20px;
        background-color: #00082c;
        border-radius: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;

        &::after {
            content: "";
            width: 10px;
            background-color: white;
            height: 10px;
            border-radius: 100%;
        }
    }
}
</style>
