import ApiService from "./api.service";

class ContactService extends ApiService {
    index() {
        return this.get("/groups");
    }

    show() {
        return this.get("/groups");
    }

    showUserData(id) {
        return this.get(`/people/${id}`);
    }

    showGroupData(id) {
        return this.get(`/groups/${id}`);
    }

    createContact(data) {
        return this.post("/people", data);
    }

    createGroup(data) {
        return this.post("/groups", data);
    }

    updateContact(id, data) {
        return this.patch(`/people/${id}`, data);
    }

    updateGroup(id, data) {
        return this.put(`/groups/${id}`, data);
    }

    deleteGroup(id) {
        return this.delete(`/groups/${id}`);
    }

    deleteContact(id) {
        return this.delete(`/people/${id}`);
    }
}

export default new ContactService();
