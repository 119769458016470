import Vuex from "vuex";

import { appDataStore } from "./appDataStore";
import { userStore } from "./userStore";

const store = new Vuex.Store({
    modules: {
        appStore: appDataStore,
        userStore: userStore,
    },
});

export default store;
