<template>
    <section class="edit-point-bottom-menu">
        <section class="edit-point-bottom-menu__left">
            <section class="edit-point-bottom-menu__actions-wrapper">
                <section class="edit-point-bottom-menu__checkbox-wrapper">
                    <input
                        type="checkbox"
                        class="edit-point-bottom-menu__input-checkbox"
                        id="check-all"
                    />
                    <section class="edit-point-bottom-menu__checkbox-mark">
                        <img
                            src="../../../../assets/icons/panel/editSession/line.svg"
                            class="edit-point-bottom-menu__checkbox-icon"
                        />
                    </section>
                </section>
                <label
                    class="edit-point-bottom-menu__picked-text"
                    for="check-all"
                    @click="handlePickedClick"
                >
                    Elementy zaznaczone:
                    <span
                        class="
                            edit-point-bottom-menu__picked-text
                            edit-point-bottom-menu__picked-text--bold
                        "
                        >{{ point_ids.length }}</span
                    >
                </label>
            </section>
            <section
                class="edit-point-bottom-menu__actions-wrapper"
                v-if="point_ids.length === 1"
            >
                <button
                    class="edit-point-bottom-menu__action-btn"
                    @click="duplicatePoint"
                >
                    <img
                        src="../../../../assets/icons/panel/editSession/duplicate.svg"
                        class="edit-point-bottom-menu__action-icon"
                    />
                    <span class="edit-point-bottom-menu__action-name"
                        >Powiel</span
                    >
                </button>
                <button
                    class="edit-point-bottom-menu__action-btn"
                    @click="addPointAsChildren"
                    v-if="
                        getPointNestedLevel(
                            this.agendaItems,
                            this.point_ids[0]
                        ) < 2
                    "
                >
                    <img
                        src="../../../../assets/icons/panel/editSession/duplicate.svg"
                        class="edit-point-bottom-menu__action-icon"
                    />
                    <span class="edit-point-bottom-menu__action-name"
                        >Dodaj podpunkt</span
                    >
                </button>
                <button
                    class="edit-point-bottom-menu__action-btn"
                    @click="setPickedPointData"
                >
                    <img
                        src="../../../../assets/icons/panel/editSession/edit.svg"
                        class="edit-point-bottom-menu__action-icon"
                    />
                    <span class="edit-point-bottom-menu__action-name"
                        >Edytuj</span
                    >
                </button>
                <button
                    class="edit-point-bottom-menu__action-btn"
                    @click="
                        () => {
                            $refs.file.click();
                            this.itemId = item.id;
                        }
                    "
                >
                    <img
                        src="../../../../assets/icons/components/attachment.svg"
                        class="edit-point-bottom-menu__action-icon"
                    />
                    <span class="edit-point-bottom-menu__action-name"
                        >Dodaj załącznik</span
                    >
                </button>
                <input
                    type="file"
                    class="edit-point-bottom-menu__file-input"
                    @change="handleFileUpload"
                    ref="file"
                />
            </section>
            <section
                class="edit-point-bottom-menu__actions-wrapper"
                v-if="point_ids.length === 1"
            >
                <button
                    class="
                        edit-point-bottom-menu__action-btn
                        edit-point-bottom-menu__action-btn--arrow
                    "
                    @click="moveUp"
                >
                    <img
                        src="../../../../assets/icons/panel/editSession/arrow-top.svg"
                        class="
                            edit-point-bottom-menu__action-icon
                            edit-point-bottom-menu__action-icon--big
                        "
                    />
                </button>
                <button
                    class="
                        edit-point-bottom-menu__action-btn
                        edit-point-bottom-menu__action-btn--arrow
                    "
                    @click="moveDown"
                >
                    <img
                        src="../../../../assets/icons/panel/editSession/arrow-bottom.svg"
                        class="
                            edit-point-bottom-menu__action-icon
                            edit-point-bottom-menu__action-icon--big
                        "
                    />
                </button>
            </section>
        </section>
        <section class="edit-point-bottom-menu__right">
            <button
                class="edit-point-bottom-menu__action-btn"
                @click="this.$emit('cancel')"
            >
                <img
                    src="../../../../assets/icons/panel/editSession/cancel.svg"
                    class="edit-point-bottom-menu__action-icon"
                /><span class="edit-point-bottom-menu__action-name"
                    >Anuluj</span
                >
            </button>
            <button
                class="
                    edit-point-bottom-menu__action-btn
                    edit-point-bottom-menu__action-btn--red
                "
                @click="deletePoints"
            >
                <img
                    src="../../../../assets/icons/components/bin.svg"
                    class="
                        edit-point-bottom-menu__action-icon
                        edit-point-bottom-menu__action-icon--white
                    "
                />
                <span
                    class="
                        edit-point-bottom-menu__action-name
                        edit-point-bottom-menu__action-name--red
                    "
                    >Usuń</span
                >
            </button>
        </section>
        <EditPoint
            v-if="isEditVisible"
            @closeEdit="isEditVisible = false"
            @reloadData="
                () => {
                    this.reloadPickedPoint();
                    this.$emit('reloadData');
                }
            "
            @refreshList="
                () => {
                    this.reloadPickedPoint();
                    this.$emit('refreshList');
                }
            "
            :pickedPoint="pointToDoAction"
        />
    </section>
</template>

<script>
import AttachmentService from "../../../../services/attachments.service";
import SessionService from "../../../../services/session.service";

import EditPoint from "./EditPointModal/EditPoint.vue";

import getPointNestedLevel from "../../../../helpers/getPointNestedLevel";

export default {
    name: "EditPointsBottomMenu",
    data() {
        return {
            checkedIds: [],
            isEditVisible: false,
            pointToDoAction: {},
            pointParent: {},
        };
    },
    components: {
        EditPoint,
    },
    props: {
        point_ids: Array,
        agendaItems: Array,
    },
    mounted() {
        this.checkedIds = this.point_ids;
    },
    methods: {
        reloadPickedPoint() {
            this.$store.commit("showLoader");
            SessionService.getPoint(this.pointToDoAction.id).then(
                (response) => {
                    this.pointToDoAction = response.data;
                    this.$store.commit("hideLoader");
                }
            );
        },
        setPickedPointData() {
            this.searchForItemRecurective(this.agendaItems);
            this.isEditVisible = true;
        },
        deletePoints() {
            const actionToFire = () => {
                this.$store.commit("showLoader");
                SessionService.removeFewPoints(this.point_ids).then(() => {
                    this.$emit("refreshList");
                    this.$emit("cancel");
                });
            };

            this.$store.commit("showConfirmationAlert", actionToFire);
        },
        handlePickedClick() {
            this.isAllChecked = !this.isAllChecked;
            if (this.isAllChecked) {
                this.checkedIds = [];
                this.setAllPoints(this.agendaItems);
                this.$emit("checkAll", this.checkedIds);
            } else {
                this.$emit("cancel");
            }
        },
        setAllPoints(items) {
            return items.map((item) => {
                this.checkedIds.push(item.id);
                this.setAllPoints(item.children);
            });
        },
        duplicatePoint() {
            this.$store.commit("showLoader");
            this.searchForItemRecurective(this.agendaItems);
            const sessionId = this.$route.params.sessionid;
            SessionService.duplicatePoints(
                sessionId,
                this.pointToDoAction.id
            ).then(() => {
                this.$emit("refreshList");
            });
        },
        handleFileUpload() {
            const file = this.$refs.file.files[0];
            const formData = new FormData();
            formData.append("file", file);
            formData.append("name", file.name);
            formData.append("public", 1);
            formData.append("show_in_register", 1);
            this.$store.commit("showLoader");
            AttachmentService.addFile(this.point_ids[0], formData).then(() => {
                this.$emit("refreshList");
            });
        },
        moveUp() {
            this.searchForItemRecurective(this.agendaItems);
            const arrayAfterMove = this.agendaItems;
            if (this.pointParent === null) {
                const pointIndex = arrayAfterMove.indexOf(this.pointToDoAction);
                if (pointIndex > 0) {
                    const swap = arrayAfterMove[pointIndex];
                    arrayAfterMove[pointIndex] = arrayAfterMove[pointIndex - 1];
                    arrayAfterMove[pointIndex - 1] = swap;
                }
            } else {
                const pointIndex = this.pointParent.children.indexOf(
                    this.pointToDoAction
                );
                if (pointIndex > 0) {
                    const swap = this.pointParent.children[pointIndex];
                    this.pointParent.children[pointIndex] =
                        this.pointParent.children[pointIndex - 1];
                    this.pointParent.children[pointIndex - 1] = swap;
                }
            }
            this.$emit("pointMoved");
        },
        moveDown() {
            this.searchForItemRecurective(this.agendaItems);
            const arrayAfterMove = this.agendaItems;
            if (this.pointParent === null) {
                const pointIndex = arrayAfterMove.indexOf(this.pointToDoAction);
                if (pointIndex < arrayAfterMove.length - 1) {
                    const swap = arrayAfterMove[pointIndex];
                    arrayAfterMove[pointIndex] = arrayAfterMove[pointIndex + 1];
                    arrayAfterMove[pointIndex + 1] = swap;
                }
            } else {
                const pointIndex = this.pointParent.children.indexOf(
                    this.pointToDoAction
                );
                if (pointIndex < this.pointParent.children.length - 1) {
                    const swap = this.pointParent.children[pointIndex];
                    this.pointParent.children[pointIndex] =
                        this.pointParent.children[pointIndex + 1];
                    this.pointParent.children[pointIndex + 1] = swap;
                }
            }
            this.$emit("pointMoved");
        },
        addPointAsChildren() {
            this.$emit("addPointAsChildren");
        },
        searchForItemRecurective(items, parrent = null) {
            items.find((item) => {
                if (item.id === this.point_ids[0]) {
                    this.pointToDoAction = item;
                    this.pointParent = parrent;
                }
                this.searchForItemRecurective(item.children, item);
            });
        },
        getPointNestedLevel,
    },
};
</script>

<style lang="scss" scoped>
.edit-point-bottom-menu {
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: calc(100% - 410px);
    z-index: 8;
    background-color: white;
    display: flex;
    padding: 28px 0px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 0px 0px 32px rgba(0, 8, 44, 0.04),
        8px 12px 24px rgba(0, 8, 44, 0.08);
    border-radius: 12px;

    @media only screen and (max-width: 1600px) {
        width: calc(100% - 310px);
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__actions-wrapper {
        display: flex;
        padding: 0px 24px;
        align-items: center;
        position: relative;

        @media only screen and (max-width: 1600px) {
            padding: 0px 12px;
        }

        &:nth-child(1),
        &:nth-child(2) {
            &::after {
                content: "";
                width: 1px;
                height: 38px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0px;
                background: #dde0e7;
            }
        }
    }

    &__checkbox-wrapper {
        position: relative;
        width: 0px;
        height: 24px;

        &::after {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 24px;
            height: 24px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
        }
    }

    &__input-checkbox {
        display: none;

        &:checked ~ .edit-point-bottom-menu__checkbox-mark {
            display: flex;
            z-index: 4;
        }
    }

    &__checkbox-mark {
        display: none;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 24px;
        height: 24px;
        background: #00082c;
        border: 1px solid #00082c;
        box-sizing: border-box;
        box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }

    &__checkbox-icon {
        width: 12px;
    }

    &__picked-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;
        z-index: 5;
        cursor: pointer;
        padding-left: 36px;

        &--bold {
            font-weight: 600;
            padding-left: 0px;
        }
    }

    &__action-btn {
        background: #ffffff;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        transition: all 0.2s ease-in-out;
        padding: 12px 24px;
        display: flex;
        align-items: center;
        margin-right: 12px;

        @media only screen and (max-width: 1600px) {
            padding: 12px 11px;
        }

        &--arrow {
            width: 40px;
            padding: 0px;
            height: 40px;
            justify-content: center;
        }

        &--red {
            background: #d80418;
            border: 0px;

            @media only screen and (max-width: 1600px) {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px;
                width: 40px;
                height: 40px;
            }

            &:hover {
                background: #ad0313 !important;
            }
        }

        &:nth-last-of-type(1) {
            margin-right: 0px;
        }

        &:hover {
            background: #e8ebef;
        }
    }

    &__action-name {
        margin-left: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #00082c;

        &--red {
            color: white;

            @media only screen and (max-width: 1600px) {
                display: none;
            }
        }
    }

    &__right {
        display: flex;
        padding-right: 24px;
    }

    &__action-icon {
        width: 18px;
        height: 18px;

        &--white {
            filter: brightness(0) invert(1);
        }
    }

    &__file-input {
        display: none;
    }
}
</style>
