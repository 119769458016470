import ApiService from "./api.service";

class MeetingService extends ApiService {
    groups() {
        return this.get("/groups");
    }
    sessions() {
        return this.get("/sessions");
    }
    getSessionById(id) {
        return this.get(`/sessions/${id}`);
    }
    updateSession(id, data) {
        return this.put(`/sessions/${id}`, data);
    }
    removeSession(id) {
        return this.delete(`sessions/${id}`);
    }
    add(data) {
        return this.post("/sessions", data);
    }
    sessionsToday() {
        return this.post("/sessions/today");
    }
    sessionsTomorrow() {
        return this.post("/sessions/tomorrow");
    }
    sessionsThisWeek() {
        return this.post("/sessions/this-week");
    }
    sessionsThisMonth() {
        return this.post("/sessions/this-month");
    }
}

export default new MeetingService();
