<template>
    <section class="hint">
        <section class="hint__top">
            <img src="../../assets/icons/components/tooltip.svg" />
            <span class="hint__title">{{ title }}</span>
        </section>
        <p class="hint__text">{{ text }}</p>
    </section>
</template>

<script>
export default {
    name: "Hint",
    props: {
        title: String,
        text: String,
    },
};
</script>
<style lang="scss" scoped>
.hint {
    position: absolute;
    top: -100px;
    left: calc(100% + 10px);
    padding: 31px 24px;
    background: #ffffff;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 16px 32px 48px rgba(0, 8, 44, 0.12);
    border-radius: 12px;
    max-width: 388px;
    width: 330px;
    margin-right: 20px;
    z-index: 99;
    opacity: 0;
    display: none;
    transition: all 0.2s ease-in-out;

    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 128%;
        color: #00082c;
        margin-left: 16px;
    }

    &__text {
        font-weight: normal;
        font-size: 16px;
        line-height: 144%;
        color: #333956;
    }
}
</style>
