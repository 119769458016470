import router from "../router/main-router";

export const userStore = {
    state: () => ({
        userData: {},
        token: "",
        isLogged: false,
        isLoading: false,
    }),
    mutations: {
        setUser(state, payloads) {
            state.userData = payloads.user;
            state.isLogged = true;
            state.token = payloads.access_token;
            state.isLoading = false;
            localStorage.setItem("token", payloads.access_token);
        },
        reNew(state, payloads) {
            state.token = payloads.access_token;
            localStorage.setItem("token", payloads.access_token);
        },
        destroyUser(state) {
            state.userData = {};
            state.token = "";
            state.isLogged = false;
            state.isLoading = false;
            localStorage.removeItem("token");
            router.push("/");
        },
        showLoader(state) {
            state.isLoading = true;
        },
        hideLoader(state) {
            state.isLoading = false;
        },
    },
};
