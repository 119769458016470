<template>
    <section class="sessions" ref="list">
        <ul class="sessions__list" v-if="sessions.length > 0">
            <li
                v-for="(session, key) in sessions"
                :key="session.id"
                class="sessions__item"
                :ref="`mainContainerSessions${key}`"
                @mouseover="
                    () =>
                        setMenuElementPosition(
                            this.$refs[`mainContainerSessions${key}`],
                            this.$refs[`subMenuActions${key}`]
                        )
                "
            >
                {{
                    `Posiedzenie${
                        session.number ? ` nr ${session.number}` : ""
                    }, ${fullDate(session.meeting_date)}, ${getTime(
                        session.meeting_date
                    )}`
                }}
                <img
                    src="../../../assets/icons/components/additional-arrow.svg"
                />
                <div
                    class="sessions__sub-wrapper"
                    :ref="`subMenuActions${key}`"
                >
                    <MeetingsActions
                        :sessionId="session.id"
                        :groupID="groupID"
                    />
                </div>
            </li>
        </ul>
        <p v-else class="sessions__no-data">Brak danych</p>
        <section
            class="sessions__add"
            @click="
                () => {
                    $store.commit('setGroupId', groupID);
                    $store.commit('showAddMeeting');
                }
            "
        >
            <SmallAddButtonRed />
            <span class="sessions__add-text">Dodaj posiedzenie</span>
        </section>
    </section>
</template>

<script>
import moment from "moment";
import { isOnScreen } from "../../../helpers/isOnScreen";

import SmallAddButtonRed from "../../BasicControls/SmallAddButtonRed.vue";
import MeetingsActions from "./MeetingsActions.vue";
import setMenuElementPosition from "../../../helpers/setMenuElementPosition";

export default {
    name: "MenuSessionList",
    data() {
        return {
            sessionsList: [],
        };
    },
    props: {
        groupID: Number,
        sessions: Array,
    },
    components: {
        SmallAddButtonRed,
        MeetingsActions,
    },
    mounted() {
        this.sessionsList = this.sessions;
        isOnScreen(this.$refs.list);
    },
    methods: {
        fullDate(meeting_date) {
            return moment(meeting_date).format("DD.MM.YYYY");
        },
        getTime(meeting_date) {
            return moment(meeting_date).format("LT");
        },
        setMenuElementPosition,
    },
};
</script>

<style lang="scss" scoped>
.sessions {
    display: none;
    width: 338px;
    background: #ffffff;
    border-radius: 12px;
    box-sizing: border-box;
    box-shadow: 16px 32px 48px rgba(0, 8, 44, 0.12);
    border: 1px solid #dce4ee;
    padding: 25px;
    list-style: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 8;

    &__list {
        list-style: none;
        padding: 0px;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 20vh;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            padding-left: 100px;
        }
        &::-webkit-scrollbar-thumb {
            background: #eef1f7;
            border-radius: 30px;
            margin-left: 100px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #eef1f7;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            margin-left: 100px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 12px;
        transition: al 0.2s ease-in-out;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #00082c;

        &:hover {
            background-color: #f4f6fa;
        }

        &:hover .action-list {
            display: block;
        }
    }

    &__add {
        display: flex;
        padding-top: 24px;
        align-items: center;
        border-top: 1px solid #dce4ee;
        width: 100%;
    }

    &__add-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #d80418;
        margin-left: 12px;
    }

    &__no-data {
        padding: 10px 0px;
    }

    &__sub-wrapper {
        position: fixed;
        top: 0px;
        left: 0px;
    }
}
</style>
