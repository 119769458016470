<template>
    <section class="add-meeting">
        <form class="add-meeting__form">
            <button
                @click="
                    (e) => {
                        e.preventDefault();
                        this.$store.commit('hideAddMeeting');
                    }
                "
                class="add-meeting__exit-btn"
            >
                <img
                    src="../../../../../assets/icons/components/exit-btn.svg"
                />
            </button>
            <section class="add-meeting__form-wrapper">
                <h2 class="add-meeting__title">
                    {{ isEdit ? "Edytuj" : "Dodaj" }} posiedzienie
                </h2>
                <section class="add-meeting__row add-meeting__row--double">
                    <section class="add-meeting__col">
                        <label class="add-meeting__label"
                            >Termin posiedzenia</label
                        >
                        <section class="add-meeting__select-wrapper">
                            <input
                                type="date"
                                class="add-meeting__date-time-picker"
                                v-model="meeting_date"
                                v-error="{ errors, input_key: 'meeting_date' }"
                            />
                            <ErrorMessage
                                v-if="isError('meeting_date', this.errors)"
                                :errorMsgs="
                                    passErrorMsg('meeting_date', this.errors)
                                "
                            />
                        </section>
                    </section>
                    <section class="add-meeting__col">
                        <label class="add-meeting__label"
                            >Godzina posiedzenia</label
                        >
                        <section class="add-meeting__select-wrapper">
                            <input
                                type="time"
                                class="add-meeting__date-time-picker"
                                v-model="meeting_time"
                                v-error="{ errors, input_key: 'meeting_date' }"
                            />
                        </section>
                    </section>
                </section>
                <section class="add-meeting__row add-meeting__row--full-width">
                    <label class="add-meeting__label">Nazwa posiedzenia</label>
                    <input
                        class="add-meeting__input-text"
                        type="text"
                        placeholder="Wpisz nazwę posiedzenia"
                        v-model="name"
                        v-error="{ errors, input_key: 'name' }"
                    />
                    <ErrorMessage
                        v-if="isError('name', this.errors)"
                        :errorMsgs="passErrorMsg('name', this.errors)"
                    />
                </section>
                <section class="add-meeting__row add-meeting__row--double">
                    <section class="add-meeting__col">
                        <label class="add-meeting__label"
                            >Status posiedzenia</label
                        >
                        <section class="add-meeting__select-wrapper">
                            <select
                                class="add-meeting__select"
                                v-model="is_published"
                            >
                                <option :value="false">Niekatywne</option>
                                <option :value="true">Aktywne</option>
                            </select>
                        </section>
                    </section>

                    <section class="add-meeting__col">
                        <label class="add-meeting__label"
                            >Numer posiedzenia</label
                        >
                        <input
                            class="
                                add-meeting__input-text
                                add-meeting__input-text--adjust-width
                            "
                            type="text"
                            placeholder="Wpisz numer posiedzenia"
                            v-model="session_number"
                            v-error="{ errors, input_key: 'number' }"
                        />
                    </section>
                </section>
                <section class="add-meeting__row">
                    <label class="add-meeting__label"
                        >Komentarz do posiedzenia
                        <span
                            class="
                                add-meeting__label
                                add-meeting__label--weight-normal
                            "
                            >(opcjonalne)</span
                        ></label
                    >
                    <textarea
                        class="add-meeting__textarea"
                        placeholder="Wpisz treść komentarza"
                        v-model="comment"
                    ></textarea>
                </section>
                <section class="add-meeting__row">
                    <label class="add-meeting__label">Grupa</label>
                    <ErrorMessage
                        v-if="isError('group_ids', this.errors)"
                        :errorMsgs="passErrorMsg('group_ids', this.errors)"
                    />
                    <section
                        class="add-meeting__checkbox-section"
                        v-for="group in canSession()"
                        :key="group.id"
                    >
                        <CheckBoxButton
                            :value="group.id"
                            :id="group.id"
                            :checked="checkedGroupsIds.indexOf(group.id) !== -1"
                            @setGroupId="
                                (value) => (this.checkedGroupsIds = value)
                            "
                            :idsArray="checkedGroupsIds"
                        />
                        <label
                            class="
                                add-meeting__label add-meeting__label--checkbox
                            "
                            :for="group.id"
                            >{{ group.name }}</label
                        >
                    </section>
                </section>
                <section class="add-meeting__bottom">
                    <button
                        class="add-meeting__button-bottom"
                        @click="
                            (e) => {
                                e.preventDefault();
                                this.$store.commit('hideAddMeeting');
                            }
                        "
                    >
                        Anuluj
                    </button>
                    <button
                        class="
                            add-meeting__button-bottom
                            add-meeting__button-bottom--red
                        "
                        @click="
                            (e) => {
                                e.preventDefault();
                                handleSaveMeeting(e);
                            }
                        "
                    >
                        Zapisz posiedzenie
                    </button>
                </section>
            </section>
            <LoaderInside v-if="isLoading" />
        </form>
    </section>
</template>

<script>
import moment from "moment";

import MeetingsService from "../../../../../services/meetings.service";

import MeetingService from "../../../../../services/meetings.service";
import CheckBoxButton from "../../../../BasicControls/CheckBoxButton.vue";
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import ErrorMessage from "../../../../BasicControls/ErrorMessage.vue";
import {
    passErrorMsg,
    isError,
    setErrors,
} from "../../../../../formValidation/main";
import meetingsService from "../../../../../services/meetings.service";

export default {
    name: "AddMeeting",
    data() {
        return {
            name: "",
            is_published: false,
            is_public: false,
            is_authorized: false,
            signature: "",
            meeting_date: "",
            meeting_time: "",
            meeting_place: "",
            comment: "",
            checkedGroupsIds: [],
            groups: [],
            isLoading: false,
            errors: [],
            session_number: "",
        };
    },
    components: {
        CheckBoxButton,
        LoaderInside,
        ErrorMessage,
    },
    mounted() {
        this.isLoading = true;
        if (this.$store.state.appStore.groupId !== null) {
            this.checkedGroupsIds.push(this.$store.state.appStore.groupId);
        }
        if (this.$store.state.appStore.meetingDate !== "") {
            this.meeting_date = moment(
                this.$store.state.appStore.meetingDate
            ).format("yyyy-MM-DD");
        }
        if (this.$store.state.appStore.sessionId !== null) {
            this.isLoading = true;
            meetingsService
                .getSessionById(this.$store.state.appStore.sessionId)
                .then((response) => {
                    const {
                        meeting_date,
                        name,
                        is_published,
                        comment,
                        groups,
                    } = response.data;
                    this.name = name;
                    this.meeting_date =
                        moment(meeting_date).format("YYYY-MM-DD");
                    this.meeting_time = moment(meeting_date).format("hh:mm");
                    this.is_published = is_published;
                    this.comment = comment;
                    this.isLoading = false;
                    this.checkedGroupsIds = groups.map((group) => group.id);
                });
        }
        MeetingService.groups().then((response) => {
            this.groups = response.data.data;
            this.isLoading = false;
        });
    },
    computed: {
        isEdit() {
            return this.$store.state.appStore.sessionId !== null;
        },
    },
    methods: {
        handleSaveMeeting(e) {
            e.preventDefault();
            const data = {
                name: this.name,
                is_published: this.is_published,
                is_public: true,
                meeting_date: `${moment(this.meeting_date).format(
                    "DD.MM.YYYY"
                )} ${this.meeting_time}`,
                comment: this.comment,
                group_ids: this.checkedGroupsIds,
            };

            if (this.session_number.length > 0) {
                data["number"] = this.session_number;
            }

            this.isLoading = true;
            this.errors = [];
            if (!this.isEdit) {
                MeetingsService.add(data)
                    .then(() => {
                        this.finishRequest();
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.errors = setErrors(err);
                    });
            } else {
                MeetingsService.updateSession(
                    this.$store.state.appStore.sessionId,
                    data
                )
                    .then(() => {
                        this.isLoading = false;
                        MeetingService.groups().then((response) => {
                            this.$store.commit("hideAddMeeting");
                            this.$store.commit("setGroups", response.data.data);
                        });
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.errors = setErrors(err);
                    });
            }
        },
        finishRequest() {
            this.isLoading = false;
            this.name = "";
            this.is_published = false;
            this.is_public = false;
            this.is_authorized = false;
            this.signature = "";
            this.meeting_date = "";
            this.meeting_time = "";
            this.meeting_place = "";
            this.comment = "";
            this.checkedGroupsIds = [];
            this.session_number = "";
            this.isLoading = false;
            this.$store.commit("hideAddMeeting");

            MeetingService.groups().then((response) =>
                this.$store.commit("setGroups", response.data.data)
            );
        },
        canSession() {
            return this.groups.filter((group) => group.can_sessions);
        },
        passErrorMsg,
        isError,
        setErrors,
    },
};
</script>

<style lang="scss" scoped>
.add-meeting {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: #00082c75;
    z-index: 9;

    &__form {
        padding: 32px 56px;
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        padding-right: 0px;
        padding-bottom: 56px;
    }

    &__form-wrapper {
        max-height: 80vh;
        overflow-y: auto;
        padding-right: 56px;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #eef1f7;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #eef1f7;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }
    }

    &__exit-btn {
        border: 0px;
        background-color: transparent;
        position: relative;
        right: -90%;
    }

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 128%;
        color: #00082c;
        margin-bottom: 32px;
    }

    &__col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__row {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        align-items: flex-start;
        justify-content: flex-start;

        &--double {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
        }
    }

    &__label {
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
        margin-bottom: 8px;

        &--checkbox {
            padding-left: 36px;
            font-weight: normal;
            z-index: 6;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            color: #333956;
            margin-bottom: 0px;
            cursor: pointer;
        }

        &--weight-normal {
            font-weight: normal;
        }
    }

    &__checkbox-section {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }

    &__input-text {
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        min-width: 500px;
        height: 56px;
        font-size: 16px;
        color: #00082c;

        &:focus {
            border: 1px solid #00082c;
        }

        &::placeholder {
            font-size: 16px;
            line-height: 144%;
            color: #808396;
        }

        &--adjust-width {
            width: 100%;
            min-width: 238px;
        }
    }

    &__textarea {
        padding: 17px 16px 8px 20px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        min-width: 500px;
        height: 160px;
        resize: none;
        font-size: 16px;
        color: #00082c;

        &::placeholder {
            font-size: 16px;
            line-height: 144%;
            color: #808396;
        }
    }

    &__select {
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        min-width: 238px;
        height: 56px;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("../../../../../assets/icons/components/select-arrow.svg");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 20px);
        background-position-y: 50%;
    }

    &__date-time-picker {
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        min-width: 238px;
        height: 56px;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;

        &[type="date"]::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url("../../../../../assets/icons/components/callendar.svg")
                no-repeat;
            background-position-x: 100%;
            width: 22px;
            height: 22px;
            border-width: thin;
        }

        &[type="time"]::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url("../../../../../assets/icons/components/clock.svg")
                no-repeat;
            background-position-x: 100%;
            width: 22px;
            height: 22px;
            border-width: thin;
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
    }

    &__button-bottom {
        padding: 18px 40px 17px;
        min-width: 238px;
        height: 56px;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        color: #00082c;
        background-color: transparent;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e8ebef;
        }

        &--red {
            background: #d80418;
            color: white;
            border: 0px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #ad0313;
            }
        }
    }

    &__select-wrapper {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 8px;
            background: #e2e5eb;
            width: 1px;
            height: 40px;
            right: 56px;
            z-index: 99;
        }
    }
}
</style>
