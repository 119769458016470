<template>
    <section class="add-contact">
        <form class="add-contact-form">
            <button
                class="add-contact-form__exit-btn"
                @click="(e) => handler(e, 'none')"
            >
                <img
                    src="../../../../../../assets/icons/components/exit-btn.svg"
                />
            </button>
            <section class="add-contact-form__form-wrapper">
                <h2 class="add-contact-form__title">
                    {{ isEdit ? "Edytuj" : "Dodaj" }} kontakt
                </h2>
                <section class="add-contact-form__top">
                    <h3
                        class="
                            add-contact-form__title
                            add-contact-form__title--small
                        "
                    >
                        Dane kontaktowe
                    </h3>
                    <button class="import-from-db">
                        <img
                            src="../../../../../../assets/icons/components/import-from-db.svg"
                        /><span class="import-from-db__text"
                            >Importuj z bazy</span
                        >
                    </button>
                </section>
                <section class="add-contact-form__inputs">
                    <section class="add-contact-form__row">
                        <section class="add-contact-form__col">
                            <label class="add-contact-form__label">Imię</label>
                            <input
                                type="text"
                                v-model="firstName"
                                class="add-contact-form__input-text"
                                v-error="{ errors, input_key: 'first_name' }"
                            />
                            <ErrorMessage
                                v-if="isError('first_name', this.errors)"
                                :errorMsgs="
                                    passErrorMsg('first_name', this.errors)
                                "
                            />
                        </section>
                        <section class="add-contact-form__col">
                            <label class="add-contact-form__label"
                                >Nazwisko</label
                            >
                            <input
                                type="text"
                                v-model="lastName"
                                class="add-contact-form__input-text"
                                v-error="{ errors, input_key: 'last_name' }"
                            />
                            <ErrorMessage
                                v-if="isError('last_name', this.errors)"
                                :errorMsgs="
                                    passErrorMsg('last_name', this.errors)
                                "
                            />
                        </section>
                    </section>
                    <section class="add-contact-form__row">
                        <section class="add-contact-form__col">
                            <label class="add-contact-form__label">Tytuł</label>
                            <input
                                type="text"
                                v-model="title"
                                class="add-contact-form__input-text"
                            />
                        </section>
                        <section class="add-contact-form__col">
                            <label class="add-contact-form__label"
                                >Funkcja</label
                            >
                            <input
                                type="text"
                                v-model="role"
                                class="add-contact-form__input-text"
                                v-error="{ errors, input_key: 'role' }"
                            />
                            <ErrorMessage
                                v-if="isError('role', this.errors)"
                                :errorMsgs="passErrorMsg('role', this.errors)"
                            />
                        </section>
                    </section>
                    <section class="add-contact-form__row">
                        <section class="add-contact-form__col">
                            <label class="add-contact-form__label"
                                >Telefon</label
                            >
                            <input
                                type="number"
                                v-model="phoneNumber"
                                class="add-contact-form__input-text"
                                v-error="{ errors, input_key: 'phone_number' }"
                            />
                            <ErrorMessage
                                v-if="isError('phone_number', this.errors)"
                                :errorMsgs="
                                    passErrorMsg('phone_number', this.errors)
                                "
                            />
                        </section>
                        <section class="add-contact-form__col">
                            <label class="add-contact-form__label"
                                >E-mail</label
                            >
                            <input
                                type="mail"
                                v-model="mail"
                                class="add-contact-form__input-text"
                                v-error="{ errors, input_key: 'email' }"
                            />
                            <ErrorMessage
                                v-if="isError('email', this.errors)"
                                :errorMsgs="passErrorMsg('email', this.errors)"
                            />
                        </section>
                    </section>
                    <section class="add-contact-form__row">
                        <section class="add-contact-form__col">
                            <label class="add-contact-form__label">Adres</label>
                            <input
                                type="text"
                                v-model="street"
                                class="add-contact-form__input-text"
                                v-error="{ errors, input_key: 'street' }"
                            />
                            <ErrorMessage
                                v-if="isError('street', this.errors)"
                                :errorMsgs="passErrorMsg('street', this.errors)"
                            />
                        </section>
                        <section class="add-contact-form__col-duo">
                            <section class="add-contact-form__col-double">
                                <label class="add-contact-form__label"
                                    >Nr domu</label
                                >
                                <input
                                    type="text"
                                    v-model="houseNumber"
                                    class="
                                        add-contact-form__input-text
                                        add-contact-form__input-text--small
                                    "
                                />
                            </section>
                            <section class="add-contact-form__col-double">
                                <label class="add-contact-form__label"
                                    >Nr lokalu</label
                                >
                                <input
                                    type="text"
                                    v-model="localNumber"
                                    class="
                                        add-contact-form__input-text
                                        add-contact-form__input-text--small
                                    "
                                />
                            </section>
                        </section>
                    </section>
                    <section class="add-contact-form__row">
                        <section class="add-contact-form__col">
                            <label class="add-contact-form__label"
                                >Miejscowość</label
                            >
                            <input
                                type="text"
                                v-model="city"
                                class="add-contact-form__input-text"
                                v-error="{ errors, input_key: 'city' }"
                            />
                            <ErrorMessage
                                v-if="isError('city', this.errors)"
                                :errorMsgs="passErrorMsg('city', this.errors)"
                            />
                        </section>
                        <section class="add-contact-form__col">
                            <label class="add-contact-form__label"
                                >Kod pocztowy</label
                            >
                            <input
                                type="text"
                                v-model="postal"
                                class="add-contact-form__input-text"
                                v-error="{ errors, input_key: 'postal' }"
                            />
                            <ErrorMessage
                                v-if="isError('postal', this.errors)"
                                :errorMsgs="passErrorMsg('postal', this.errors)"
                            />
                        </section>
                    </section>
                </section>
                <section class="add-contact-form__groups">
                    <h3
                        class="
                            add-contact-form__title
                            add-contact-form__title--small
                        "
                    >
                        Członkostwo w grupach
                    </h3>
                    <ErrorMessage
                        v-if="isError('group_ids', this.errors)"
                        :errorMsgs="passErrorMsg('group_ids', this.errors)"
                    />
                    <section
                        v-for="group in groups"
                        :key="group.id"
                        class="add-contact-form__checkbox"
                    >
                        <CheckBoxButton
                            :id="group.id"
                            :value="group.id"
                            @setGroupId="
                                (value) => (this.checkedGroupsId = value)
                            "
                            :idsArray="checkedGroupsId"
                            :checked="
                                group.id === storedGroupId ||
                                checkedGroupsId.indexOf(group.id) !== -1
                            "
                        />
                        <label
                            class="
                                add-contact-form__label
                                add-contact-form__label--checkbox
                            "
                            :for="group.id"
                            >{{ group.name }}</label
                        >
                    </section>
                </section>
                <section class="add-contact-form__bottom-btns">
                    <button
                        class="add-contact-form__action-btn"
                        @click="(e) => handler(e, 'none')"
                    >
                        Anuluj
                    </button>
                    <button
                        class="
                            add-contact-form__action-btn
                            add-contact-form__action-btn--red
                        "
                        @click="(e) => handleAddContactClick(e)"
                    >
                        {{ isEdit ? "Edytuj" : "Dodaj" }} kontakt
                    </button>
                </section>
            </section>
            <LoaderInside v-if="isLoading" />
        </form>
    </section>
</template>

<script>
import CheckBoxButton from "../../../../../BasicControls/CheckBoxButton.vue";
import ContactService from "../../../../../../services/contact.service";
import LoaderInside from "../../../../../BasicControls/LoaderInside.vue";
import ErrorMessage from "../../../../../BasicControls/ErrorMessage.vue";

import {
    passErrorMsg,
    isError,
    setErrors,
} from "../../../../../../formValidation/main";

export default {
    name: "AddContact",
    data() {
        return {
            firstName: "",
            lastName: "",
            title: "",
            role: "",
            phoneNumber: "",
            mail: "",
            street: "",
            houseNumber: "",
            localNumber: "",
            city: "",
            postal: "",
            checkedGroupsId: [],
            isEdit: false,
            isLoading: false,

            city_error: {
                status: false,
                msg: "",
            },
            errors: [],
        };
    },
    components: {
        CheckBoxButton,
        LoaderInside,
        ErrorMessage,
    },
    props: {
        groups: Array,
        handler: Function,
        storedGroupId: Number,
        storedUserEditData: Object,
    },
    mounted() {
        if (this.storedGroupId !== null) {
            this.checkedGroupsId.push(this.storedGroupId);
        }
        if (Object.keys(this.storedUserEditData).length > 0) {
            const {
                first_name,
                last_name,
                role,
                phone_number,
                email,
                street,
                postal,
                city,
                groups,
            } = this.storedUserEditData;

            this.firstName = first_name;
            this.lastName = last_name;
            this.role = role;
            this.phoneNumber = phone_number;
            this.mail = email;
            this.street = street;
            this.postal = postal;
            this.city = city;
            this.checkedGroupsId = groups;
            this.isEdit = true;
        }
    },
    methods: {
        handleAddContactClick(e) {
            e.preventDefault();
            this.errors = [];

            this.isLoading = true;

            const data = {
                first_name: this.firstName,
                last_name: this.lastName,
                role: this.role,
                phone_number: this.phoneNumber,
                fax: "2222222222",
                email: this.mail,
                street: this.street,
                postal: this.postal,
                city: this.city,
                group_ids: this.checkedGroupsId,
            };
            if (Object.keys(this.storedUserEditData).length > 0) {
                ContactService.updateContact(this.storedUserEditData.id, data)
                    .then(() => {
                        this.reloadContacts();
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.errors = setErrors(err);
                    });
            } else {
                ContactService.createContact(data)
                    .then(() => {
                        this.reloadContacts();
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.errors = setErrors(err);
                    });
            }
        },
        reloadContacts() {
            ContactService.show().then((response) => {
                this.$emit("renewGroups", response.data.data);
                this.firstName = "";
                this.lastName = "";
                this.title = "";
                this.role = "";
                this.phoneNumber = "";
                this.mail = "";
                this.street = "";
                this.houseNumber = "";
                this.localNumber = "";
                this.city = "";
                this.postal = "";
                this.checkedGroupsId = [];
                this.handler(null, "none");
                this.isLoading = false;
            });
        },
        passErrorMsg,
        setErrors,
        isError,
    },
};
</script>

<style lang="scss" scoped>
.add-contact {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 8;
    background: #00082c75;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-contact-form {
    background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
    border-radius: 12px;
    padding: 32px 56px;
    min-width: 612px;
    max-height: 90vh;
    padding-right: 0px;

    &__form-wrapper {
        max-height: 80vh;
        overflow-y: auto;
        padding-right: 56px;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #eef1f7;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #eef1f7;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }
    }

    &__exit-btn {
        border: 0px;
        background-color: transparent;
        position: relative;
        right: calc(-100% + 48px);
    }

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 128%;
        color: #00082c;
        margin-bottom: 32px;

        &--small {
            font-size: 20px;
            margin-bottom: 27px;
        }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #dde0e7;
        padding-top: 32px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
    }

    &__col {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        &:nth-child(1) {
            margin-right: 24px;
        }
    }

    &__col-duo {
        display: flex;
    }

    &__label {
        font-weight: 500;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
        margin-bottom: 8px;

        &--checkbox {
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            color: #333956;
            margin-left: 12px;
            margin-bottom: 0px;
            padding-left: 24px;
            z-index: 6;
            cursor: pointer;
        }
    }

    &__input-text {
        margin-bottom: 10px;
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        width: 100%;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;
        min-width: 238px;

        &:focus {
            border: 1px solid #00082c;
        }

        &--small {
            min-width: 107px;
            margin-top: 7px;
        }
    }

    &__col-double {
        &__label {
            font-weight: 500;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-bottom: 8px;
        }
        &:nth-child(1) {
            margin-right: 24px;
        }
    }

    &__inputs {
        max-width: 500px;
        border-bottom: 1px solid #dde0e7;
        padding-bottom: 38px;
    }

    &__groups {
        margin-top: 28px;
    }

    &__checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 13px;
    }

    &__check-icon {
        width: 0px;
        height: 24px;
        position: relative;
        opacity: 1;

        &:checked ~ .checkbox-mark {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:checked ~ .add-contact-form__label--checkbox {
            padding-left: 0px;
        }

        &:before {
            content: "";
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 24px;
            height: 24px;
            position: relative;
            display: block;
            top: 0px;
            left: 0px;
            opacity: 1;
        }
    }

    &__bottom-btns {
        margin-top: 32.5px;
        padding-bottom: 56px;
    }

    &__action-btn {
        padding: 18px 40px 17px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        min-width: 238px;
        border: 1px solid #bbc2ce;
        background-color: transparent;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e8ebef;
        }

        &--red {
            border: 0px;
            background: #d80418;
            color: white;
            margin-left: 24px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #ad0313;
            }
        }
    }
}

.import-from-db {
    margin-bottom: 27px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #e9eef5;
    border-radius: 4px;
    padding: 9px 16px;
    border: 0px;

    &__text {
        margin-left: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #00082c;
    }
}

.checkbox-mark {
    background: #00082c;
    border: 1px solid #00082c;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
    border-radius: 4px;
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 99;
    display: none;
    top: 0px;
    left: 0px;
    opacity: 1;
}
</style>
