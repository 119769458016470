<template>
    <aside class="side-bar">
        <section class="side-bar__top">
            <router-link to="home">
                <img
                    src="../../../assets/imgs/logo.png"
                    class="side-bar__logo"
                />
            </router-link>
            <nav class="side-bar__navigation">
                <section v-for="link in categoryLink" :key="link.id">
                    <NavBlock
                        :blockTitle="link.name"
                        :links="link.links"
                        :additionalMenu="link.additionalMenu"
                    />
                </section>
            </nav>
        </section>
        <section class="side-bar__bottom">
            <router-link to="/panel/help">
                <section class="link-button link-button--left">
                    <img
                        src="../../../assets/icons/navMenu/help.svg"
                        class="link-button__icon"
                    /><span class="link-button__link">Pomoc</span>
                </section>
            </router-link>
            <section class="link-button link-button--left" @click="logOut">
                <img
                    src="../../../assets/icons/navMenu/logout.svg"
                    class="link-button__icon"
                /><span class="link-button__link">Wyloguj</span>
            </section>
        </section>
    </aside>
</template>

<script>
import NavBlock from "./NavBlock.vue";
import { NavLinksData } from "../../../componentsData/navData.js";
import UserService from "../../../services/user.service";

export default {
    name: "NavMenu",
    data() {
        return {
            categoryLink: NavLinksData,
        };
    },
    methods: {
        logOut() {
            UserService.logout(this.$store.state.userStore.token);
        },
    },
    components: {
        NavBlock,
    },
};
</script>

<style lang="scss" scoped>
.side-bar {
    height: 100vh;
    background: linear-gradient(139.17deg, #00082c 0%, #004dcc 148.71%);
    min-width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 7;

    @media only screen and(max-width:1600px) {
        min-width: auto;
        width: 310px;
    }

    &__navigation {
        overflow-y: auto;
        max-height: calc(100vh - 200px);
        &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #001347;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #001347;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff00;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f000;
        }
    }

    &__logo {
        width: 160px;
        margin: 24px 0px 48px 32px;

        @media only screen and(max-width:1600px) {
            margin: 24px 0px 18px 18px;
            height: 40px;
            width: auto;
        }
    }

    &__bottom {
        margin-bottom: 10px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -20px;
            width: calc(100% - 64px);
            height: 1px;
            background: #ffffff;
            opacity: 0.24;
            left: 50%;
            transform: translateX(-50%);

            @media only screen and(max-width:1600px) {
                width: 90%;
                top: -5px;
            }
        }
    }
}
</style>
