<template>
    <header class="header">
        <section class="header-left">
            <img
                src="../../../assets/icons/panel/header/info.svg"
                class="header-left__icon"
            />
            <span class="header-left__text"
                >Witaj w systemie
                <strong class="header-left__text--bold">eSesja!</strong> zobacz
                aktualną
                <a href="" class="header-left__text--link"
                    >listę posiedzeń.</a
                ></span
            >
        </section>
        <section class="header-right">
            <section class="header-right__icon-box">
                <img
                    src="../../../assets/icons/panel/header/users.svg"
                    class="header-right__icon"
                />
            </section>
            <section class="header-right__icon-box">
                <img
                    src="../../../assets/icons/panel/header/notification.svg"
                    class="header-right__icon"
                />
                <section
                    class="header-right__notification"
                    v-if="notifications_number > 0"
                >
                    {{ notifications_number }}
                </section>
            </section>
            <section class="header-right__user">
                <img
                    src="../../../assets/imgs/user_avatar.png"
                    class="header-right__user-pic"
                />
                <section class="header-right__container">
                    <h5 class="header-right__logged-as">Zalogowano jako:</h5>
                    <p class="header-right__user-name">
                        {{ userFullName }}
                    </p>
                </section>
                <img
                    src="../../../assets/icons/panel/header/arrow.svg"
                    class="header-right__arrow"
                />
            </section>
        </section>
    </header>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            notifications_number: 0,
        };
    },
    computed: {
        userFullName() {
            if (this.$store.state.userStore.isLogged) {
                return `${this.$store.state.userStore.userData.first_name} ${this.$store.state.userStore.userData.last_name}`;
            }
            return null;
        },
        getLoaderStatus() {
            return this.$store.state.userData.isLoading;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/breakpoints.scss";

.header {
    width: 100%;
    box-sizing: border-box;
    padding: 26px 45px 23px 49px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 6;

    &::after {
        content: "";
        position: absolute;
        background: #dde0e7;
        width: 100%;
        height: 1px;
        bottom: -1px;
        left: 0px;
    }
}

.header-left {
    display: flex;
    align-items: center;

    &__icon {
        margin-right: 11px;
    }

    &__text {
        font-size: 16px;
        line-height: 144%;
        color: #333956;

        &--bold {
            font-weight: 600;
        }

        &--link {
            color: #d80418;
            text-decoration: underline;
            font-weight: 600;
        }
    }

    @media only screen and(max-width:1850px) {
        width: 100%;
    }
}

.header-right {
    display: flex;
    align-items: center;

    &__user {
        padding-left: 17px;
        height: 46px;
        border-left: 1px solid #e8ebef;
        display: flex;
        align-items: center;
    }

    &__icon-box {
        position: relative;
        height: 48px;
        border-left: 1px solid #e8ebef;
        display: flex;
        align-items: center;
        padding: 0px 24px;
    }

    &__notification {
        position: absolute;
        top: 5px;
        right: 14px;
        width: 22px;
        height: 16px;
        background: #d80418;
        box-shadow: 3px 4px 8px rgba(173, 3, 19, 0.16);
        border-radius: 100px;
        color: white;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__user-pic {
        width: 44px;
        height: 44px;
        margin-right: 7px;
    }

    &__logged-as {
        font-weight: 600;
        font-size: 11px;
        line-height: 120%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #808396;
        margin: 4px 0px;
        white-space: nowrap;
    }

    &__user-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 132%;
        color: #00082c;
        padding-left: 16px;
        position: relative;
        white-space: nowrap;

        &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: #60a800;
            box-shadow: 1px 2px 4px rgba(65, 114, 0, 0.16);
            border-radius: 100%;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__arrow {
        margin-left: 31px;
    }
}
</style>
