<template>
    <section class="alert alert--error">
        <section class="alert__left">
            <img
                src="../../assets/icons/components/error-alert.svg"
                class="alert__icon"
            />
            <span class="alert__text"
                >W trakcie przetwarzania akcji wystąpił nieoczekiwany błąd.
            </span>
        </section>
        <button
            class="alert__close alert__close--error"
            @click="this.$store.commit('hideError')"
        >
            <img src="../../assets/icons/components/close-alert.svg" />
        </button>
    </section>
</template>

<script>
export default {
    name: "SuccessAlert",
};
</script>

<style lang="scss" scoped>
.alert--error {
    background: #f9d4d8;
    box-shadow: 0px 0px 16px 2px rgba(108, 108, 108, 0.16);
    min-width: 586px;
    height: 71px;
    border: 1.5px solid #f5c0c5;
}

.alert__close--error {
    background: #d80418;
    box-shadow: -4px 4px 12px rgba(130, 1, 13, 0.16);
}
</style>
