<template>
    <section class="home-content">
        <h2 class="home-content__header">Panel główny</h2>
        <section class="home-content__wrapper">
            <section class="home-content-left">
                <section class="home-content-left__action-boxes">
                    <section
                        v-for="box in actionBoxes"
                        :key="box.id"
                        class="home-content-left__box-wrapper"
                    >
                        <ActionBox
                            :title="box.title"
                            :icon="box.icon"
                            @click="box.event"
                        />
                    </section>
                </section>
                <Meetings />
            </section>
            <section class="home-content-right">
                <Calendar />
                <News />
            </section>
        </section>
    </section>
</template>

<script>
import ActionBox from "./ActionBoxes.vue";
import Calendar from "./Calendar.vue";
import Meetings from "./Meetings/Meetings.vue";
import News from "./News/News.vue";

export default {
    name: "Home",
    data() {
        return {
            actionBoxes: [
                {
                    id: 0,
                    icon: "add_meeting",
                    title: "Dodaj posiedzenie",
                    event: (e) => this.handleAddMeetingClick(e),
                },
                {
                    id: 1,
                    icon: "add_annoucment",
                    title: "Dodaj komunikat",
                    event: () => console.log("event"),
                },
                {
                    id: 2,
                    icon: "send_sms",
                    title: "Wyślij SMS",
                    event: () => console.log("event"),
                },
                {
                    id: 3,
                    icon: "send_e-mail",
                    title: "Wyślij e-mail",
                    event: () => console.log("event"),
                },
            ],
        };
    },
    components: {
        ActionBox,
        Calendar,
        Meetings,
        News,
    },
    methods: {
        handleAddMeetingClick(e) {
            e.preventDefault();
            this.$store.commit("showAddMeeting");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/breakpoints.scss";

.home-content {
    padding: 49px 24px 49px 48px;
    font-weight: 600;
    font-size: 28px;
    line-height: 128%;
    color: #00082c;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

.home-content-left {
    margin-top: 24px;

    @media only screen and(max-width:1850px) {
        width: 100%;
    }

    &__box-wrapper {
        margin-right: 24px;
    }

    &__action-boxes {
        display: flex;

        @media only screen and(max-width:1850px) {
            width: 100%;
            margin-right: 0px;
        }
    }
}

.home-content-right {
    margin-top: 24px;
    box-sizing: border-box;

    @media only screen and(max-width:1850px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}
</style>
