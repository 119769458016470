<template>
    <section class="documents-options">
        <section class="documents-options__row">
            <label class="documents-options__label">Nazwa rejestru</label>
            <input
                type="text"
                class="documents-options__input"
                placeholder="Wpisz nazwę rejestru..."
                v-model="registerName"
                v-error="{ errors, input_key: 'registry_name' }"
                @keyup="this.$emit('registryNameChanged', registerName)"
            />
            <ErrorMessage
                v-if="isError('registry_name', this.currentErrors)"
                :errorMsgs="passErrorMsg('registry_name', this.currentErrors)"
            />
        </section>
        <section class="documents-options__row">
            <label class="documents-options__label">Nazwa dokumetu</label>
            <input
                type="text"
                class="documents-options__input"
                placeholder="Wpisz nazwę dokumentu..."
                v-model="docName"
                v-error="{ errors, input_key: 'registry_document_name' }"
                @keyup="this.$emit('registryDocNameChanged', docName)"
            />
            <ErrorMessage
                v-if="isError('registry_document_name', this.currentErrors)"
                :errorMsgs="
                    passErrorMsg('registry_document_name', this.currentErrors)
                "
            />
        </section>
        <section class="documents-options__row">
            <label class="documents-options__label">Schemat numeracji</label>
            <input
                type="number"
                class="documents-options__input"
                placeholder="Wpisz schemat numeracji..."
                v-model="schemaName"
                @keyup="this.$emit('resolution_number_format_id', schemaName)"
            />
        </section>
    </section>
</template>

<script>
import { passErrorMsg, isError } from "../../../../../../formValidation/main";

import ErrorMessage from "../../../../../BasicControls/ErrorMessage.vue";

export default {
    name: "DocumentOption",
    data() {
        return {
            registerName: "",
            docName: "",
            schemaName: "",
            currentErrors: [],
        };
    },
    components: {
        ErrorMessage,
    },
    props: {
        registryName: String,
        registryDocName: String,
        registryNumberFormat: Number,
        errors: Array,
    },
    mounted() {
        this.currentErrors = this.errors;
        if (this.registryName.length > 0) {
            this.registerName = this.registryName;
            this.docName = this.registryDocName;
            this.schemaName = this.registryNumberFormat;
        }
    },
    updated() {
        this.currentErrors = this.errors;
    },
    methods: {
        passErrorMsg,
        isError,
    },
};
</script>

<style lang="scss" scoped>
.documents-options {
    padding: 24px 16px;
    border-top: 1px solid #d1d6df;

    &__row {
        margin-bottom: 20px;
        width: 100%;

        &:nth-last-child(1) {
            margin-bottom: 0px;
        }
    }

    &__label {
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
    }

    &__input {
        padding: 12.5px 16px;
        width: 100%;
        min-height: 48px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        font-size: 16px;
        line-height: 144%;
        margin-top: 8px;

        &::placeholder {
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            color: #808396;
        }
    }
}
</style>
