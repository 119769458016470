<template>
    <section class="alert alert--success">
        <section class="alert__left">
            <img
                src="../../assets/icons/components/success-alert.svg"
                class="alert__icon"
            />
            <span class="alert__text"
                >Akcja wykonana pomyślnie. Zmiany zostały zapisane.</span
            >
        </section>
        <button
            class="alert__close alert__close--success"
            @click="this.$store.commit('hideSuccess')"
        >
            <img src="../../assets/icons/components/close-alert.svg" />
        </button>
    </section>
</template>

<script>
export default {
    name: "SuccessAlert",
    mounted() {
        this.$store.commit("hideError");
        setTimeout(() => {
            this.$store.commit("hideSuccess");
        }, 5000);
    },
};
</script>

<style lang="scss" scoped>
.alert--success {
    background: #caffc1;
    box-shadow: 2px 2px 12px rgba(59, 96, 9, 0.08),
        12px 12px 28px rgba(59, 96, 9, 0.12);
    min-width: 586px;
    height: 71px;
    border: 1.5px solid #a0cb66;
}
.alert__close--success {
    background: #60a800;
    border: 1px solid #60a800;
}
</style>

<style lang="scss">
.alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 24px;
    box-sizing: border-box;
    margin-bottom: 28px;
    border-radius: 12px;
    position: relative;

    &__left {
        display: flex;
        align-items: center;
    }

    &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 145%;
        color: #1a2141;
        margin-left: 17px;
    }

    &__close {
        border: 0px;
        background-color: transparent;
        position: absolute;
        top: -6px;
        right: -6px;
        border-radius: 100%;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
