export const appDataStore = {
    state: () => ({
        sessions: [],
        groups: [],
        isAddMeetingVisible: false,
        sessionId: null,
        groupId: null,
        meetingDate: "",
        errorVisible: false,
        noticeVisible: false,
        successVisible: false,
        isConfirmationAlertVisible: false,
        confirmationFunctionHandler: () => {},
    }),
    mutations: {
        setSessions(state, payloads) {
            state.sessions = payloads;
        },
        setSessionId(state, payloads) {
            state.sessionId = payloads;
        },
        setGroups(state, payloads) {
            state.groups = payloads;
        },
        setGroupId(state, payloads) {
            state.groupId = payloads;
        },
        showAddMeeting(state) {
            state.isAddMeetingVisible = true;
        },
        hideAddMeeting(state) {
            state.isAddMeetingVisible = false;
            state.groupId = null;
            state.sessionId = null;
            state.meetingDate = "";
        },
        setMeetingDate(state, payloads) {
            state.meetingDate = payloads;
        },
        showError(state) {
            state.errorVisible = true;
        },
        hideError(state) {
            state.errorVisible = false;
        },
        showNotice(state) {
            state.noticeVisible = true;
        },
        hideNotice(state) {
            state.noticeVisible = false;
        },
        showSuccess(state) {
            state.successVisible = true;
        },
        hideSuccess(state) {
            state.successVisible = false;
        },
        showConfirmationAlert(state, payloads) {
            state.isConfirmationAlertVisible = true;
            state.confirmationFunctionHandler = () => {
                payloads();
                state.isConfirmationAlertVisible = false;
                state.confirmationFunctionHandler = () => {};
            };
        },
        hideConfirmationAlert(state) {
            state.isConfirmationAlertVisible = false;
            state.confirmationFunctionHandler = () => {};
        },
    },
};
