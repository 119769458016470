<template>
    <button class="action-button-contacts">
        <img
            :src="require(`../../assets/icons/components/${icon}.svg`)"
            class="
                action-button-contacts__icon
                action-button-contacts__icon--margin
            "
        /><span class="action-button-contacts__text">{{ text }}</span>
    </button>
</template>

<script>
export default {
    name: "ButtonWithIconContacts",
    props: {
        icon: String,
        text: String,
    },
};
</script>

<style lang="scss" scoped>
.action-button-contacts {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: #ffffff;
    border: 1px solid #bbc2ce;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 8px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #e8ebef;
    }

    &__icon {
        width: 16px;
        height: 16px;

        &--margin {
            margin-right: 8px;
            width: 17px;
        }
    }
}
</style>
