const getPointNestedLevel = (items, itemID) => {
    let elementNestedLevel = 0;

    const searchForItemRecurective = (items, nestedLevel) => {
        nestedLevel++;
        items.find((item) => {
            if (item.id === itemID) {
                elementNestedLevel = nestedLevel;
            }
            searchForItemRecurective(item.children, nestedLevel);
        });
    };

    items.forEach((item) => {
        let nestedLevel = 0;
        searchForItemRecurective(item.children, nestedLevel);
    });

    return elementNestedLevel;
};

export default getPointNestedLevel;
