import axios from "axios";
import store from "../store/mainStore";
import router from "../router/main-router";

export default function axiosSetup() {
    axios.interceptors.request.use(
        function (config) {
            const token = localStorage.getItem("token");
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data

            const urlsToSkipAlert = [
                "/me",
                "/sessions/between",
                "/login",
                "/refresh",
                "/me",
                "/bulk",
            ];
            let containUrl = false;
            urlsToSkipAlert.forEach((url) => {
                if (response.config.url.indexOf(url) !== -1) {
                    containUrl = true;
                }
            });

            if (response.config.method !== "get" && !containUrl) {
                store.commit("showSuccess");
            }
            return response;
        },
        function (error) {
            if (error.response.config.url !== "/me") {
                store.commit("showError");
            }
            store.commit("hideLoader");

            if (error.response && error.response.status == 401) {
                store.commit("destroyUser");
                throw new axios.Cancel(error.response).message.data;
            }

            if (error.response && error.response.status == 403) {
                router.push("home");
            }

            if (error.response && error.response.status == 422) {
                throw new axios.Cancel(error.response).message.data;
            }

            // // Clear local storage user if unauthorized

            // // Reload if it's set
            // if (error.response && error.response.data.reload) {
            //     location.reload();
            // }

            return Promise.resolve(error);
        }
    );
}
