import store from "../store/mainStore";
import ApiService from "./api.service";
import { expireTimeMinus } from "../config/config";
import router from "../router/main-router";

class UserService extends ApiService {
    login(login, password) {
        store.commit("showLoader");
        return this.post("/login", {
            login,
            password,
        }).then((response) => {
            store.commit("setUser", response.data);
            this.reNewTimeOut(response.data.expires_in);
            router.push("home");
        });
    }

    logout() {
        store.state.userStore.isLoading = true;
        return this.post("/logout")
            .then(() => store.commit("destroyUser"))
            .catch(() => store.commit("destroyUser"));
    }

    refreshToken() {
        return this.post("/refresh")
            .then((response) => {
                store.commit("reNew", response.data);
            })
            .catch(() => {
                store.commit("destroyUser");
            });
    }

    reNewTimeOut(expire_time) {
        const expire = (expire_time - expireTimeMinus) * 1000;
        setInterval(() => {
            this.refreshToken(store.state.token);
        }, expire);
    }

    me() {
        store.commit("showLoader");
        return this.post("/me")
            .then((response) => {
                store.commit("hideLoader");
                store.commit("setUser", response.data);
                this.reNewTimeOut(response.data.expires_in);
            })
            .catch(() => {
                store.commit("destroyUser");
            });
    }
}

export default new UserService();
