<template>
    <section class="contacts">
        <h1 class="contacts__title">Kontakty</h1>
        <section class="contact-top">
            <section class="contact-top__search">
                <form
                    class="contact-top__form"
                    @submit="(e) => handleSearchSubmit(e)"
                >
                    <input
                        type="text"
                        v-model="searchBar"
                        placeholder="Wyszukaj kontakt..."
                        class="contact-top__input"
                        @keyup="filterGroups"
                    />
                    <button class="contact-top__button-search">
                        <img
                            src="../../../../assets/icons/panel/Contacts/search.svg"
                            class="contact-top__search-icon"
                        />
                    </button>
                </form>
            </section>
            <section class="contact-top__action-buttons">
                <ButtonWithIcon
                    icon="filter"
                    text="Filtruj listę"
                    :classModified="false"
                />
                <ButtonWithIcon
                    icon="add-group"
                    text="Dodaj grupę"
                    :classModified="true"
                    @click="(e) => handleChangeView(e, 'add-group')"
                />
                <ButtonWithIcon
                    icon="add-contact"
                    text="Dodaj kontakt"
                    :classModified="true"
                    @click="(e) => handleChangeView(e, 'add-contact')"
                />
            </section>
        </section>
        <section class="contacts__list" v-if="groups.length > 0">
            <ContactList
                :groups="groups"
                :addContactHandler="handleChangeView"
                :searchBar="searchBar"
                @reloadGroups="(groups) => (this.groups = groups)"
            />
            <button
                class="contacts__add-user-btn"
                @click="(e) => handleChangeView(e, 'add-contact')"
            >
                <img
                    src="../../../../assets/icons/components/add-contact.svg"
                    class="contacts__add-user-btn-icon"
                />
            </button>
        </section>
        <section v-else class="contact-list-no-result">Brak grup</section>
        <section v-if="currentView !== 'none'">
            <AddContact
                :groups="groups"
                v-if="currentView === 'add-contact'"
                :handler="handleChangeView"
                @renewGroups="
                    (groups) => {
                        this.groups = groups;
                        this.searchBar = '';
                    }
                "
                :storedGroupId="storedGroupId"
                :storedUserEditData="storedUserEditData"
            />
            <AddGroup
                v-if="currentView === 'add-group'"
                :handler="handleChangeView"
                :storedGroupData="storedGroupData"
                @setGroups="(groups) => (this.groups = groups)"
                @reloadGroups="
                    (groups) => {
                        this.groups = groups;
                        this.searchBar = '';
                        this.$store.commit('setGroups', groups);
                    }
                "
            />
        </section>
    </section>
</template>

<script>
import ContactService from "../../../../services/contact.service";

import ButtonWithIcon from "../../../BasicControls/ButtonWithIcon.vue";
import ContactList from "./ContactList/ContactList.vue";
import AddContact from "./Modals/AddContact/AddContact.vue";
import AddGroup from "./Modals/AddGroup/AddGroup.vue";

export default {
    name: "Kontakt",
    data() {
        return {
            searchBar: "",
            groups: [],
            currentView: "none",
            storedGroupId: null,
            storedUserEditData: {},
            storedGroupData: {},
        };
    },
    methods: {
        filterGroups() {
            if (this.searchBar.length > 0) {
                this.groups = this.groups.map((group) => {
                    const matched_people = group.people.filter((person) => {
                        const full_name = `${person.first_name} ${person.last_name}`;
                        if (
                            full_name.toLowerCase().indexOf(this.searchBar) ===
                            0
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    group.people = matched_people;
                    return group;
                });
            } else {
                this.$store.commit("showLoader");
                ContactService.index()
                    .then((response) => {
                        this.groups = response.data.data;
                        this.$store.commit("setGroups", response.data.data);
                        this.$store.commit("hideLoader");
                    })
                    .catch((err) => console.log(err));
            }
        },
        handleSearchSubmit(e) {
            e.preventDefault();
        },
        handleChangeView(
            e,
            status,
            additionalData = { id: null, action: "none" }
        ) {
            if (e !== null) {
                e.preventDefault();
            }
            if (status === "none") {
                this.storedGroupId = null;
                this.storedUserEditData = {};
                this.currentView = status;
                this.storedGroupData = {};
            }

            if (
                additionalData.id !== null &&
                additionalData.action === "add-contact-to-group"
            ) {
                this.storedGroupId = additionalData.id;
                this.currentView = status;
            }

            if (
                additionalData.id !== null &&
                additionalData.action === "edit-contact"
            ) {
                this.$store.state.userStore.isLoading = true;
                ContactService.showUserData(additionalData.id).then(
                    (response) => {
                        this.storedUserEditData = response.data;
                        this.storedUserEditData.groups =
                            this.storedUserEditData.groups.map(
                                (group) => group.id
                            );
                        this.$store.state.userStore.isLoading = false;
                        this.currentView = status;
                    }
                );
            }

            if (
                additionalData.id !== null &&
                additionalData.action === "edit-group"
            ) {
                this.$store.state.userStore.isLoading = true;
                ContactService.showGroupData(additionalData.id).then(
                    (response) => {
                        this.storedGroupData = response.data;
                        this.$store.state.userStore.isLoading = false;
                        this.currentView = status;
                    }
                );
            }

            if (additionalData.id === null) {
                this.currentView = status;
            }
        },
    },
    components: {
        ButtonWithIcon,
        ContactList,
        AddContact,
        AddGroup,
    },
    mounted() {
        this.$store.commit("showLoader");
        ContactService.index().then((response) => {
            this.groups = response.data.data;
            this.$store.commit("setGroups", response.data.data);
            this.$store.commit("hideLoader");
        });
    },
};
</script>

<style lang="scss" scoped>
.contacts {
    margin: 49px 48px;

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 128%;
        color: #00082c;
        margin-bottom: 20px;
    }
    &__add-user-btn {
        position: fixed;
        bottom: 40px;
        right: 48px;
        width: 56px;
        height: 56px;
        border: 0px;
        background: #d80418;
        box-shadow: 8px 8px 28px rgba(173, 3, 19, 0.16);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2 ease-in-out;

        &:hover {
            background: #ad0313 !important;
        }
    }

    &__add-user-btn-icon {
        width: 25px;
        height: 24px;
    }
}

.contact-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &__input {
        height: 48px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
        border-radius: 4px;
        width: 326px;
        box-sizing: border-box;
        padding: 12.5px 16px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 0px;

        &:focus {
            border: 1px solid #00082c;
        }
    }

    &__button-search {
        height: 48px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 0px;
        position: relative;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 32px;
            background: #e2e5eb;
            top: 8px;
            left: 0px;
        }
    }

    &__form {
        display: flex;
        align-items: center;
    }

    &__search-icon {
        padding: 14px;
    }

    &__action-buttons {
        display: flex;
    }
}

.contact-list-no-result {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
