<template>
    <section class="show-note">
        <section class="show-note__wrapper">
            <button
                class="show-note__exit-btn"
                @click="
                    (e) => {
                        e.preventDefault();
                        this.$emit('closeNote');
                    }
                "
            >
                <img src="../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <h2 class="show-note__name">{{ noteData.name }}</h2>
            <section
                class="show-note__description"
                v-html="noteDescription"
            ></section>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import LoaderInside from "../../../BasicControls/LoaderInside.vue";

import AttachmentService from "../../../../services/attachments.service";

export default {
    name: "ShowNote",
    data() {
        return {
            isLoading: false,
            noteData: {},
            noteDescription: "",
        };
    },
    components: {
        LoaderInside,
    },
    props: {
        noteID: Number,
    },
    mounted() {
        this.isLoading = true;
        AttachmentService.getNote(this.noteID).then((response) => {
            this.noteData = response.data;
            this.isLoading = false;
            this.noteDescription = this.noteData.note.description;
        });
    },
};
</script>

<style lang="scss" scoped>
.show-note {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: #00082c75;
    z-index: 9;

    &__wrapper {
        padding: 32px 34px;
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        max-height: 90vh;
        overflow-y: auto;
        min-width: 612px;
        min-height: 50vh;
    }

    &__exit-btn {
        border: 0px;
        background-color: transparent;
        position: relative;
        right: -95%;
        top: -10px;
    }

    &__name {
        font-weight: 600;
        font-size: 28px;
        line-height: 128%;
        color: #00082c;
        margin-bottom: 32px;
        background: #ffffff;
        padding: 12.5px 16px;
        border-radius: 4px;
        border: 1px solid #d1d6df;
        width: 100%;
        box-sizing: border-box;
    }

    &__description {
        max-height: 40vh;
        height: 40vh;
        border: 1px solid #d1d6df;
        overflow-y: auto;
        line-height: 128%;
        color: #00082c;
        background: #ffffff;
        padding: 12.5px 16px;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;

        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 24px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #eef1f7;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #eef1f7;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }
    }
}
</style>
