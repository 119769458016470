<template>
    <section class="agenda-item-context-menu">
        <section
            class="agenda-item-context-menu__item"
            @click="this.$emit('editPoint', this.item)"
        >
            <img
                src="../../assets/icons/components/edit.svg"
                class="agenda-item-context-menu__icon"
            />
            <span class="agenda-item-context-menu__text">Edytuj punkt</span>
        </section>
        <section
            class="agenda-item-context-menu__item"
            @click="this.$emit('addAttachment')"
        >
            <img
                src="../../assets/icons/components/attachment.svg"
                class="agenda-item-context-menu__icon"
            />
            <span class="agenda-item-context-menu__text">Dodaj załącznik</span>
        </section>
        <section
            class="agenda-item-context-menu__item"
            @click="this.$emit('addNote', this.item)"
        >
            <img
                src="../../assets/icons/components/note.svg"
                class="agenda-item-context-menu__icon"
            />
            <span class="agenda-item-context-menu__text">Dodaj notatkę</span>
        </section>
        <section
            class="agenda-item-context-menu__item"
            @click="this.$emit('delete', this.item)"
        >
            <img
                src="../../assets/icons/components/bin.svg"
                class="agenda-item-context-menu__icon"
            />
            <span
                class="
                    agenda-item-context-menu__text
                    agenda-item-context-menu__text--red
                "
                >Usuń</span
            >
        </section>
    </section>
</template>

<script>
export default {
    name: "AgendaItemContextMenu",
    props: {
        item: Object,
    },
};
</script>

<style lang="scss" scoped>
.agenda-item-context-menu {
    padding: 12px 24px;
    position: absolute;
    z-index: 8;
    background-color: white;
    border-radius: 12px;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 16px 32px 48px rgba(0, 8, 44, 0.12);
    display: none;

    &__icon {
        width: 20px;
        height: 20px;
    }

    &__item {
        padding: 12px 0px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__text {
        margin-left: 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #00082c;

        &--red {
            color: #d80418;
        }
    }
}
</style>
