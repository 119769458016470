<template>
    <ul class="action-list" ref="list">
        <router-link
            :to="{
                name: action.action,
                params: { sessionid: sessionId, groupid: this.groupID },
            }"
            v-for="action in actions"
            :key="action.id"
        >
            <li class="action-list__item" @click="action.handler(sessionId)">
                <img
                    :src="
                        require(`../../../assets/icons/meetingsActions/${action.icon}.svg`)
                    "
                />
                <span class="action-list__name">{{ action.name }}</span>
            </li>
        </router-link>
    </ul>
</template>

<script>
import { meetingsActions } from "../../../componentsData/meetingsActions.js";

import { isOnScreen } from "../../../helpers/isOnScreen";

export default {
    name: "MenuActions",
    data() {
        return {
            actions: meetingsActions,
        };
    },
    props: {
        sessionId: Number,
        groupID: Number,
    },
    mounted() {
        isOnScreen(this.$refs.list);
    },
};
</script>

<style lang="scss" scoped>
.action-list {
    display: none;
    position: fixed;
    background: #ffffff;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 16px 32px 48px rgba(0, 8, 44, 0.12);
    padding: 12px;
    list-style: none;
    width: 365px;
    z-index: 7;
    border-radius: 12px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 30vh;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background: #eef1f7;
        border-radius: 30px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #eef1f7;
    }
    &::-webkit-scrollbar-track {
        background: #ffffff;
        border: 0.8px solid #dce4ee;
        border-radius: 0px;
        box-shadow: inset 0px 0px 0px 0px #f0f0f0;
    }

    &__item {
        border-radius: 4px;
        padding: 15px 13px;
        transition: all 0.2s ease-in-out;
        display: flex;
        align-items: center;

        &:hover {
            background: #f4f6fa;
        }
    }

    &__name {
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #00082c;
        margin-left: 13px;
    }
}
</style>
