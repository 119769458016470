<template>
    <button
        class="btn-component"
        :class="{ 'btn-component--red': classModified }"
    >
        <img
            :src="require(`../../assets/icons/components/${icon}.svg`)"
            class="btn-component__icon"
        />
        <span class="btn-component__text">{{ text }}</span>
    </button>
</template>

<script>
export default {
    name: "ButtonWithIcon",
    props: {
        icon: String,
        text: String,
        classModified: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.btn-component {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border-radius: 4px;
    padding: 13.5px 32px;
    cursor: pointer;
    height: 46px;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    background-color: #ffffff;
    border: 1px solid #bbc2ce;

    &:hover {
        background-color: #e8ebef;
    }

    &--red {
        background-color: #d80418;
        color: #ffffff;
        border: 0px;

        &:hover {
            background-color: #ad0313;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        margin-left: 8px;
    }

    &__icon {
        width: 25px;
        height: 24px;
    }
}
</style>
