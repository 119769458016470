<template>
    <section class="add-group">
        <form class="add-group-form">
            <button
                class="add-group-form__close"
                @click="(e) => handler(e, 'none')"
            >
                <img
                    src="../../../../../../assets/icons/components/exit-btn.svg"
                />
            </button>
            <section class="add-group-form__form-wrapper">
                <h3 class="add-group-form__title">
                    {{ isEdit ? "Edytuj" : "Dodaj" }} grupę
                </h3>
                <label class="add-group-form__label">Nazwa grupy</label>
                <ErrorMessage
                    v-if="isError('name', this.errors)"
                    :errorMsgs="passErrorMsg('name', this.errors)"
                />
                <input
                    type="text"
                    class="add-group-form__input-text"
                    v-model="groupName"
                    v-error="{ errors, input_key: 'name' }"
                />
                <p class="add-group-form__sub-title">Wybierz opcje dla grupy</p>
                <section class="checkbox-section">
                    <section class="checkbox-section__checkbox">
                        <section class="checkbox-section__checkbox-wrapper">
                            <CheckBoxButton
                                value="posiedzenia"
                                id="posiedzenia"
                                :checked="canSession"
                            />
                            <label
                                class="add-group-form__label--checkbox"
                                @click="this.canSession = !this.canSession"
                                >Obsługa posiedzień</label
                            >
                        </section>
                        <MeetingsOprions
                            v-if="canSession"
                            :meeting_name="session_default_name"
                            :isSessionNumbered="is_sessions_numbered"
                            :sessionNumberFormat="session_number_format"
                            :agendaNumberFormat1="agenda_number_format_1_id"
                            :agendaNumberFormat2="agenda_number_format_2_id"
                            :agendaNumberFormat3="agenda_number_format_3_id"
                            :errors="errors"
                            @meetingName="
                                (value) => (this.session_default_name = value)
                            "
                            @setIsSessionNumbered="
                                (value) => (this.is_sessions_numbered = value)
                            "
                            @setSessionNumberFormat="
                                (value) => {
                                    this.session_number_format = value;
                                }
                            "
                            @setAgendaNumberFormat1="
                                (value) =>
                                    (this.agenda_number_format_1_id = value)
                            "
                            @setAgendaNumberFormat2="
                                (value) =>
                                    (this.agenda_number_format_2_id = value)
                            "
                            @setAgendaNumberFormat3="
                                (value) =>
                                    (this.agenda_number_format_3_id = value)
                            "
                        />
                    </section>
                    <section class="checkbox-section__checkbox">
                        <section class="checkbox-section__checkbox-wrapper">
                            <CheckBoxButton
                                value="czlonkowie"
                                id="czlonkowie"
                                :checked="is_debatters"
                            />
                            <label
                                class="add-group-form__label--checkbox"
                                @click="this.is_debatters = !this.is_debatters"
                                >Członkowie grupy widoczni na liście
                                gości</label
                            >
                        </section>
                    </section>
                    <section class="checkbox-section__checkbox">
                        <section class="checkbox-section__checkbox-wrapper">
                            <CheckBoxButton
                                value="rejestr"
                                id="rejestr"
                                :checked="canRegistry"
                            />
                            <label
                                class="add-group-form__label--checkbox"
                                @click="this.canRegistry = !this.canRegistry"
                                >Rejestr dokumentów</label
                            >
                        </section>
                        <DocumentsOptions
                            v-if="canRegistry"
                            :registryName="registryName"
                            :registryDocName="registry_document_name"
                            :registryNumberFormat="resolution_number_format_id"
                            :errors="errors"
                            @registryNameChanged="
                                (value) => (this.registryName = value)
                            "
                            @registryDocNameChanged="
                                (value) => (this.registry_document_name = value)
                            "
                            @resolution_number_format_id="
                                (value) =>
                                    (this.resolution_number_format_id = value)
                            "
                        />
                    </section>
                </section>

                <section class="add-group-form__bottom">
                    <button
                        class="add-group-form__action-btn"
                        @click="(e) => handler(e, 'none')"
                    >
                        Anuluj
                    </button>
                    <button
                        class="
                            add-group-form__action-btn
                            add-group-form__action-btn--red
                        "
                        @click="handleAddClick"
                    >
                        {{ isEdit ? "Zapisz zmiany" : "Dodaj grupę" }}
                    </button>
                </section>
            </section>
            <LoaderInside v-if="isLoading" />
        </form>
    </section>
</template>

<script>
import ContactService from "../../../../../../services/contact.service";
import LoaderInside from "../../../../../BasicControls/LoaderInside.vue";
import ErrorMessage from "../../../../../BasicControls/ErrorMessage.vue";

import CheckBoxButton from "../../../../../BasicControls/CheckBoxButton.vue";
import MeetingsOprions from "./MeetingsOptions.vue";
import DocumentsOptions from "./DocumentsOption.vue";

import {
    passErrorMsg,
    isError,
    setErrors,
} from "../../../../../../formValidation/main";

export default {
    name: "AddGroup",
    data() {
        return {
            groupName: "",
            type: 4,
            canRegistry: false,
            registryName: "",
            registry_document_name: "",
            resolution_number_format_id: 1,
            canSession: false,
            is_sessions_numbered: false,
            session_number_format: 1,
            session_default_name: "",
            agenda_number_format_1_id: 1,
            agenda_number_format_2_id: 1,
            agenda_number_format_3_id: 1,
            is_debatters: false,
            isEdit: false,
            isLoading: false,

            errors: [],
        };
    },
    components: {
        CheckBoxButton,
        MeetingsOprions,
        DocumentsOptions,
        LoaderInside,
        ErrorMessage,
    },
    props: {
        handler: Function,
        storedGroupData: Object,
    },
    mounted() {
        if (Object.keys(this.storedGroupData).length > 0) {
            const {
                name,
                can_registry,
                registry_name,
                registry_document_name,
                resolution_number_format_id,
                can_sessions,
                is_sessions_numbered,
                session_number_format_id,
                session_default_name,
                agenda_number_format_1_id,
                agenda_number_format_2_id,
                agenda_number_format_3_id,
                is_debaters,
            } = this.storedGroupData;

            this.groupName = name;
            this.canRegistry = can_registry;
            this.registryName = registry_name;
            this.registry_document_name = registry_document_name;
            this.resolution_number_format_id = resolution_number_format_id;
            this.canSession = can_sessions;
            this.is_sessions_numbered = is_sessions_numbered;
            this.session_number_format = session_number_format_id;
            this.session_default_name = session_default_name;
            this.agenda_number_format_1_id = agenda_number_format_1_id;
            this.agenda_number_format_2_id = agenda_number_format_2_id;
            this.agenda_number_format_3_id = agenda_number_format_3_id;
            this.is_debatters = is_debaters;
            this.isEdit = true;
        }
    },
    methods: {
        handleAddClick(e) {
            e.preventDefault();

            this.isLoading = true;
            this.errors = [];

            const data = {
                name: this.groupName,
                type: this.type,
                can_registry: this.canRegistry,
                registry_name: this.registryName,
                registry_document_name: this.registry_document_name,
                resolution_number_format_id: this.resolution_number_format_id,
                can_sessions: this.canSession,
                is_sessions_numbered: this.is_sessions_numbered,
                session_number_format_id: this.session_number_format,
                session_default_name: this.session_default_name,
                agenda_number_format_1_id: this.agenda_number_format_1_id,
                agenda_number_format_2_id: this.agenda_number_format_2_id,
                agenda_number_format_3_id: this.agenda_number_format_3_id,
                is_debaters: this.is_debatters,
                cadence_id: 1,
            };

            if (this.isEdit) {
                ContactService.updateGroup(this.storedGroupData.id, data)
                    .then(() => {
                        ContactService.show().then((response) => {
                            this.finishRequest(response.data.data);
                        });
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.errors = setErrors(err);
                    });
            } else {
                ContactService.createGroup(data)
                    .then(() => {
                        ContactService.show().then((response) => {
                            this.finishRequest(response.data.data);
                        });
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.errors = setErrors(err);
                    });
            }
        },
        finishRequest(data) {
            this.$emit("setGroups", data);
            this.handler(null, "none");
            this.isLoading = false;
            this.$store.commit("setGroups", data);
        },
        passErrorMsg,
        isError,
        setErrors,
    },
};
</script>

<style lang="scss" scoped>
.add-group {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 8;
    background: #00082c75;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-group-form {
    background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
    border-radius: 12px;
    padding: 28px 56px;
    max-height: 90vh;
    position: relative;
    padding-right: 0px;
    padding-bottom: 0px;

    &__form-wrapper {
        max-height: 80vh;
        overflow-y: auto;
        padding-right: 56px;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #eef1f7;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #eef1f7;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }
    }

    &__close {
        position: relative;
        right: calc(-100% + 48px);
        border: 0px;
        background-color: transparent;
    }

    &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 128%;
        color: #00082c;
        margin-bottom: 48px;
    }

    &__label {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
        margin-bottom: 8px;

        &--checkbox {
            padding-left: 36px;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            z-index: 9;
            color: #333956;
            cursor: pointer;
        }
    }

    &__input-text {
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        width: 100%;
        margin-bottom: 40px;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;

        &:focus {
            border: 1px solid #00082c;
        }
    }

    &__sub-title {
        font-weight: 600;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #333956;
        margin-bottom: 16px;
    }

    &__bottom {
        margin-top: 28px;
        padding-bottom: 56px;
    }

    &__action-btn {
        min-width: 238px;
        padding: 18px 40px 17px;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: transparent;
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        color: #00082c;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e8ebef;
        }

        &--red {
            margin-left: 24px;
            color: #ffffff;
            background: #d80418;
            border: 0px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #ad0313;
            }
        }
    }
}

.checkbox-section {
    &__checkbox {
        background: #f0f4fb;
        border: 1px solid #dde0e7;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
    }

    &__checkbox-wrapper {
        display: flex;
        padding: 16px;
    }
}
</style>
