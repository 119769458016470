<template>
    <section class="meeting-wrapper" v-if="list.length > 0">
        <p class="meeting-wrapper__title">{{ name }}</p>
        <section v-for="item in list" :key="item.id">
            <Meeting
                :name="item.name"
                :number="item.number"
                :dateTime="item.meeting_date"
                :id="item.id"
                :groupid="item.groups[0].id"
            />
        </section>
    </section>
    <section v-else class="meeting-wrapper">
        <p class="meeting-wrapper__title">{{ name }}</p>
        <p class="meeting-wrapper__no-meetings">Brak spotkań</p>
    </section>
</template>

<script>
import Meeting from "./Meeting.vue";

export default {
    name: "MeetingsWrapper",
    components: {
        Meeting,
    },
    props: {
        name: String,
        list: Array,
    },
};
</script>

<style lang="scss" scoped>
.meeting-wrapper {
    margin: 28px;

    &__title {
        font-weight: 600;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #666b80;
    }

    &__no-meetings {
        font-size: 20px;
        margin-top: 12px;
    }
}
</style>
