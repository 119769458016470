import AdditonalMenuMeetings from "../components/Panel/NavMenu/AdditionalMenuMeetings.vue";

export const NavLinksData = [
    {
        id: 0,
        name: "Zarządzanie",
        accesLevel: 0,
        links: [
            {
                id: 0,
                name: "Panel głowny",
                link: "/home",
                icon_src: "home.svg",
            },
            {
                id: 1,
                name: "Posiedzenia",
                icon_src: "posiedzenia.svg",
                additionalMenu: AdditonalMenuMeetings,
            },
            {
                id: 2,
                name: "Kontakty",
                link: "/kontakty",
                icon_src: "contact.svg",
            },
            {
                id: 3,
                name: "Rejestr",
                link: "/rejestr",
                icon_src: "rejestr.svg",
            },
            {
                id: 4,
                name: "Informacje i zapytania",
                link: "/infomacje",
                icon_src: "informations.svg",
            },
        ],
    },
    {
        id: 1,
        name: "Komunikacja",
        accesLevel: 0,
        links: [
            {
                id: 0,
                name: "Komunikaty",
                link: "/komunikaty",
                icon_src: "message.svg",
            },
            {
                id: 1,
                name: "Aktualności",
                link: "/aktualnosci",
                icon_src: "news.svg",
            },
            {
                id: 2,
                name: "Wiadomości SMS",
                link: "/sms",
                icon_src: "sms.svg",
            },
            {
                id: 3,
                name: "Wiadomości e-mail",
                link: "/email",
                icon_src: "message.svg",
            },
        ],
    },
    {
        id: 2,
        name: "Narzędzia",
        accesLevel: 0,
        links: [
            {
                id: 0,
                name: "Repozytorium dokumentów",
                link: "/dokumenty",
                icon_src: "directory.svg",
            },
            {
                id: 1,
                name: "Raporty obecności",
                link: "/raport",
                icon_src: "circle-chart.svg",
            },
            {
                id: 2,
                name: "Aktywność uytkowników",
                link: "/aktywnosc",
                icon_src: "user_activiti.svg",
            },
        ],
    },
];
