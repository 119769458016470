const setMenuElementPosition = (menuItem, subMenu) => {
    if (menuItem !== undefined && subMenu !== undefined) {
        const menuItemPos = menuItem.getBoundingClientRect();

        subMenu.style.top = menuItemPos.y - 12 + "px";
        subMenu.style.left =
            menuItemPos.x + Math.round(menuItem.offsetWidth) + "px";
    }
};

export default setMenuElementPosition;
