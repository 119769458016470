<template>
    <section class="panel-content">
        <Header />
        <AddMeeting v-if="visibleStatus" />
        <router-view></router-view>
    </section>
</template>

<style lang="scss" scoped>
.panel-content {
    min-height: 100vh;
    background-color: #f4f6fa;
    width: 100%;
    box-sizing: border-box;
}
</style>

<script>
import Header from "./Header.vue";
import AddMeeting from "./Home/Modals/AddMeetting.vue";

export default {
    name: "Content",
    computed: {
        visibleStatus() {
            return this.$store.state.appStore.isAddMeetingVisible;
        },
    },
    components: {
        Header,
        AddMeeting,
    },
};
</script>
