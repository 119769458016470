<template>
    <router-link :to="`${link}`" v-if="link !== undefined">
        <section
            class="link-button"
            ref="mainContainer"
            @mouseover="
                () =>
                    setMenuElementPosition(
                        this.$refs.mainContainer,
                        this.$refs.subMenu
                    )
            "
        >
            <section class="link-button__wrapper">
                <img
                    :src="require(`../../../assets/icons/navMenu/${icon}`)"
                    class="link-button__icon"
                />
                <span class="link-button__link">{{ name }}</span>
            </section>
            <img
                class="link-button__arrow"
                src="../../../assets/icons/navMenu/arrow.svg"
                v-if="additionalMenu !== undefined"
            />
            <div class="link-button__submenu-wrapper" ref="subMenu">
                <AdditionalMenuMeetings v-if="additionalMenu !== undefined" />
            </div>
        </section>
    </router-link>
    <section
        class="link-button"
        ref="mainContainer2"
        @mouseover="
            () =>
                setMenuElementPosition(
                    this.$refs.mainContainer2,
                    this.$refs.subMenu2
                )
        "
        v-else
    >
        <section class="link-button__wrapper">
            <img
                :src="require(`../../../assets/icons/navMenu/${icon}`)"
                class="link-button__icon"
            />
            <span class="link-button__link">{{ name }}</span>
        </section>
        <img
            class="link-button__arrow"
            src="../../../assets/icons/navMenu/arrow.svg"
            v-if="additionalMenu !== undefined"
        />
        <div class="link-button__submenu-wrapper" ref="subMenu2">
            <AdditionalMenuMeetings v-if="additionalMenu !== undefined" />
        </div>
    </section>
</template>

<script>
import AdditionalMenuMeetings from "./AdditionalMenuMeetings.vue";
import setMenuElementPosition from "../../../helpers/setMenuElementPosition";

export default {
    name: "NavLink",
    props: {
        name: String,
        link: String,
        icon: String,
        additionalMenu: Object,
    },
    components: {
        AdditionalMenuMeetings,
    },
    methods: {
        setMenuElementPosition,
    },
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables.scss";
.router-link-active {
    .link-button {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 0px 4px 4px 0px;

        &__link {
            color: white;
        }

        &__icon {
            filter: brightness(0) invert(1);
        }
    }
}
.link-button {
    align-items: center;
    padding: 12px 0px 11px 0px;
    margin-right: 16px;
    padding-left: 32px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;

    @media only screen and(max-width:1600px) {
        padding: 10px 0px;
        padding-left: 19px;
        margin-right: 12px;
    }

    &:hover {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 0px 4px 4px 0px;
    }

    &:hover .link-button__link {
        color: white;
    }

    &:hover .link-button__icon {
        filter: brightness(0) invert(1);
    }

    &:hover .additional-menu {
        display: block;
    }

    &--left {
        justify-content: flex-start;
    }

    &__link {
        color: $navLink;
        font-weight: 600;
        font-size: 16px;
        line-height: 132%;
        margin-left: 12px;

        @media only screen and(max-width:1600px) {
            font-size: 14px;
        }
    }

    &__icon {
        width: 20px;
        height: 20px;
    }

    &__arrow {
        margin-right: 16px;
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__submenu-wrapper {
        position: fixed;
        top: 0px;
        left: 0px;
    }
}
</style>
