<template>
    <ul class="errors">
        <li
            v-for="(error, index) in errorMsgs"
            :key="index"
            class="errors__msg"
        >
            {{ error }}
        </li>
    </ul>
</template>

<script>
export default {
    name: "ErrorMessage",
    props: {
        errorMsgs: Array,
    },
};
</script>

<style lang="scss" scoped>
.errors {
    list-style: none;
    margin-bottom: 12px;
    padding: 0px;
    margin-top: 8px;
    &__msg {
        color: #d80418;
        font-size: 14px;
    }
}
</style>
