<template>
    <section>
        <router-view />

        <section v-if="isLoading" class="main-loader">
            <Loader />
        </section>
        <section class="alerts-container" v-if="isAlertVisible">
            <SuccessAlert v-if="isSuccessVisible" />
            <ErrorAlert v-if="isErrorVisible" />
            <NoticeAlert v-if="isNoticeVisible" />
        </section>
        <ConfirmationAlert v-if="isConfirmationAlertVisible" />
    </section>
</template>

<style lang="scss" scoped>
.alerts-container {
    position: fixed;
    bottom: 118px;
    right: 48px;
    z-index: 30;
    display: flex;
    flex-direction: column;
}
</style>

<style lang="scss">
* {
    margin: 0px;
    font-family: "Inter", sans-serif;
}

a {
    text-decoration: none;

    &:visited {
        text-decoration: none;
        color: initial;
    }
}
button,
input,
select,
textarea {
    &:focus {
        outline: none;
    }
}

button {
    cursor: pointer;
}

.main-loader {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
}
</style>

<script>
import Loader from "./components/Loader.vue";
import SuccessAlert from "./components/BasicControls/SuccessAlert.vue";
import ErrorAlert from "./components/BasicControls/ErrorAlert.vue";
import NoticeAlert from "./components/BasicControls/NoticeAlert.vue";
import ConfirmationAlert from "./components/Alerts/ConfirmationAlert.vue";

export default {
    components: {
        Loader,
        SuccessAlert,
        ErrorAlert,
        NoticeAlert,
        ConfirmationAlert,
    },
    computed: {
        isLoading() {
            return this.$store.state.userStore.isLoading;
        },
        isSuccessVisible() {
            return this.$store.state.appStore.successVisible;
        },
        isErrorVisible() {
            return this.$store.state.appStore.errorVisible;
        },
        isNoticeVisible() {
            return this.$store.state.appStore.noticeVisible;
        },
        isAlertVisible() {
            return (
                this.$store.state.appStore.successVisible ||
                this.$store.state.appStore.errorVisible ||
                this.$store.state.appStore.noticeVisible
            );
        },
        isConfirmationAlertVisible() {
            return this.$store.state.appStore.isConfirmationAlertVisible;
        },
    },
};
</script>
