<template>
    <section class="alert alert--notice">
        <section class="alert__left">
            <img
                src="../../assets/icons/components/notice-alert.svg"
                class="alert__icon"
            />
            <span class="alert__text">
                Lorem ipsum dolor. Lorem ipsum dolor.
            </span>
        </section>
        <button
            class="alert__close alert__close--notice"
            @click="this.$store.commit('hideNotice')"
        >
            <img src="../../assets/icons/components/close-alert.svg" />
        </button>
    </section>
</template>

<script>
export default {
    name: "SuccessAlert",
    mounted() {
        setTimeout(() => {
            this.$store.commit("hideNotice");
        }, 5000);
    },
};
</script>

<style lang="scss" scoped>
.alert--notice {
    background: #f9edd1;
    box-shadow: 2px 2px 12px rgba(165, 124, 18, 0.08),
        12px 12px 28px rgba(165, 124, 18, 0.12);
    min-width: 586px;
    height: 71px;
    border: 1.5px solid #ffd466;
}
.alert__close--notice {
    background: #ffb800;
    box-shadow: -4px 4px 12px rgba(192, 142, 13, 0.32);
}
</style>
