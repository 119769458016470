<template>
    <section class="action-box">
        <section class="action-box__icon-wrapper">
            <img
                :src="
                    require(`../../../../assets/icons/panel/home/${icon}.svg`)
                "
                class="action-box__icon"
            />
            <img
                :src="
                    require(`../../../../assets/icons/panel/home/${icon}_hover.svg`)
                "
                class="action-box__icon action-box__icon--hover"
            />
        </section>
        <section class="action-box__bottom">
            <span class="action-box__title">{{ title }}</span>
            <img
                src="../../../../assets/icons/panel/home/arrow.svg"
                class="action-box__arrow"
            />
        </section>
    </section>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/breakpoints.scss";

.action-box {
    background: #ffffff;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
    border-radius: 12px;
    width: 231px;
    height: 165px;
    box-sizing: border-box;
    padding: 27px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &::after {
        content: "";
        width: 64px;
        height: 64px;
        background-color: gray;
    }

    @media only screen and(max-width:1850px) {
        margin-right: 0px;
    }

    &:hover {
        border: 2px solid #d80418;
    }

    &:hover .action-box__icon-wrapper {
        background: linear-gradient(135deg, #fbe6e8 14.84%, #fdf3f4 85.16%);
    }

    &:hover .action-box__icon--hover {
        opacity: 1;
    }

    &__bottom {
        display: flex;
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 132%;
        color: #00082c;
    }

    &__arrow {
        margin-left: 12px;
    }

    &__icon {
        width: 64px;
        height: 64px;
        z-index: 0;

        &--hover {
            position: absolute;
            width: 28px;
            height: 28px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            z-index: 4;
            transition: all 0.2s ease-in-out;
        }
    }

    &__icon-wrapper {
        margin-bottom: 20px;
        background: linear-gradient(135deg, #e8ecf2 14.84%, #f4f6fa 85.16%);
        width: 64px;
        height: 64px;
        border-radius: 100%;
        transition: all 0.2s ease-in-out;
        position: relative;
    }
}
</style>

<script>
export default {
    name: "ActionBox",
    props: {
        title: String,
        icon: String,
    },
};
</script>
