<template>
    <section
        class="points-list"
        :class="{ 'points-list--no-children': !haveChildren && level > 1 }"
    >
        <draggable
            class="dragArea"
            tag="ul"
            :list="items"
            :group="{
                name: 'g1',
                put: (to, from, element, event) =>
                    canBePlaced(to, from, element, event),
            }"
            :class="{ 'max-stack': level === 2 }"
            :ghost-class="level > 0 ? 'ghost' : 'ghost--first-level'"
            :drag-class="
                level > 0 ? 'dragged-item' : 'dragged-item--first-level'
            "
            @refreshList="this.$emit('refreshList')"
            @reloadData="this.$emit('reloadData')"
            @setPointIds="
                (value) => {
                    this.point_ids = value;
                    this.$emit('setPointIds', value);
                }
            "
        >
            <li
                v-for="(item, index) in items"
                :key="(index += 1)"
                :class="{
                    'no-drag children children--last': level === 2,
                    children: level > 0,
                    'has-children': level < 2 && item.children.length > 0,
                    'has-max-children': childrenCheck(item.children) === 2,
                    'session-point--selected':
                        this.point_ids.indexOf(item.id) !== -1 && level === 0,
                }"
                class="session-point"
            >
                <section class="points-list__data-wrapper">
                    <img
                        :src="
                            index === 1
                                ? require('../../../../assets/icons/panel/editSession/last-element-arrow.svg')
                                : require('../../../../assets/icons/panel/editSession/next-point-arrow.svg')
                        "
                        v-if="level === 2"
                        class="points-list__arrow"
                        :class="{ 'points-list__arrow--next': index > 1 }"
                    />
                    <section
                        class="points-list__point-box"
                        :class="{
                            'points-list__point-box--no-border': level === 0,
                            'last-element': level === 2,
                            'points-list__point-box--selected':
                                this.point_ids.indexOf(item.id) !== -1 &&
                                level > 0,
                        }"
                        @contextmenu="openContextMenu"
                    >
                        <AgendaItemContextMenu
                            @editPoint="
                                (element) =>
                                    this.handleEditPointClick(null, element)
                            "
                            @addAttachment="
                                () => {
                                    $refs.file.click();
                                    this.itemId = item.id;
                                }
                            "
                            @addNote="
                                (element) => handleAddNoteClick(null, element)
                            "
                            @delete="
                                (element) => handleDeleteClick(null, element.id)
                            "
                            :item="item"
                        />
                        <section class="points-list__point-box-left">
                            <section>
                                <section class="points-list__checkbox">
                                    <CheckBoxButton
                                        :value="item.id"
                                        :id="item.id"
                                        :checked="
                                            point_ids.indexOf(item.id) !== -1
                                        "
                                        :idsArray="point_ids"
                                        @setGroupId="
                                            (value) => {
                                                this.point_ids = value;
                                                this.$emit(
                                                    'setPointIds',
                                                    value
                                                );
                                            }
                                        "
                                    />
                                    <label
                                        class="points-list__checkbox-label"
                                        :for="item.id"
                                    ></label>
                                    <span
                                        class="points-list__number"
                                        :class="{
                                            'points-list__name--bold':
                                                level === 0,
                                        }"
                                        >{{ filterIndex(index) }}</span
                                    >
                                    <span
                                        class="points-list__name"
                                        :class="{
                                            'points-list__name--bold':
                                                level === 0,
                                        }"
                                    >
                                        {{ item.name }}
                                    </span>
                                </section>
                            </section>
                            <section class="points-list__attachments">
                                <section
                                    class="points-list__attachment-wrapper"
                                    v-for="(
                                        attachment, index
                                    ) in item.attachments"
                                    :key="index"
                                >
                                    <img
                                        src="../../../../assets/icons/components/attachment.svg"
                                        v-if="attachment.type_id === 1"
                                    />
                                    <img
                                        src="../../../../assets/icons/components/note.svg"
                                        v-else
                                    />
                                    <a
                                        class="points-list__attachment-link"
                                        download
                                        v-if="attachment.type_id === 1"
                                        :href="`https://esesja.mda.pl/api/attachment-download/${attachment.id}?token=${getToken}`"
                                    >
                                        {{ attachment.name }}
                                    </a>
                                    <a
                                        class="points-list__attachment-link"
                                        v-else
                                        @click="
                                            () => {
                                                showNote = true;
                                                noteID = attachment.id;
                                            }
                                        "
                                    >
                                        {{ attachment.name }}
                                    </a>
                                </section>
                            </section>
                        </section>
                        <section class="points-list__actions">
                            <button
                                class="points-list__action-btn"
                                @click="handleEditPointClick(e, item)"
                            >
                                <img
                                    src="../../../../assets/icons/components/edit.svg"
                                    class="points-list__action-icon"
                                />
                            </button>
                            <button
                                class="points-list__action-btn"
                                @click="
                                    () => {
                                        $refs.file.click();
                                        this.itemId = item.id;
                                    }
                                "
                            >
                                <img
                                    src="../../../../assets/icons/panel/editSession/attachement-top.svg"
                                    class="points-list__action-icon"
                                />
                            </button>
                            <button
                                class="points-list__action-btn"
                                @click="handleAddNoteClick(e, item)"
                            >
                                <img
                                    src="../../../../assets/icons/panel/editSession/add-note.svg"
                                    class="points-list__action-icon"
                                />
                            </button>
                            <input
                                type="file"
                                class="points-list__file-input"
                                @change="handleFileUpload"
                                ref="file"
                            />
                            <button
                                class="
                                    points-list__action-btn
                                    points-list__action-btn--red
                                "
                                @click="handleDeleteClick(e, item.id)"
                            >
                                <img
                                    src="../../../../assets/icons/components/delete-point.svg"
                                    class="points-list__action-icon"
                                />
                            </button>
                        </section>
                    </section>
                </section>
                <SesionPointsList
                    :items="item.children"
                    :haveChildren="item.children.length > 0"
                    :level="level + 1"
                    :session="session"
                    :firstLevel="firstLevel"
                    :secondLevel="secondLevel"
                    :thirdLevel="thirdLevel"
                    :checkedPointIds="point_ids"
                    v-if="level < 2"
                    @refreshList="this.$emit('refreshList')"
                    @reloadData="this.$emit('reloadData')"
                    @setPointIds="
                        (value) => {
                            this.point_ids = value;
                            this.$emit('setPointIds', value);
                        }
                    "
                />
            </li>
        </draggable>
        <EditPoint
            v-if="editPointVisible"
            :pickedPoint="pickedPoint"
            @closeEdit="
                () => {
                    this.editPointVisible = false;
                    this.pickedPoint = {};
                }
            "
            @reloadData="this.$emit('reloadData')"
            @reloadPoint="(data) => (pickedPoint = data)"
        />
        <AddNote
            v-if="addNoteVisible"
            @closeAddNote="addNoteVisible = false"
            :pointID="pickedPoint.id"
            @reload="this.$emit('refreshList')"
        />
        <ShowNote
            v-if="showNote"
            @closeNote="showNote = false"
            :noteID="noteID"
        />
    </section>
</template>

<script>
import CheckBoxButton from "../../../BasicControls/CheckBoxButton.vue";
import EditPoint from "./EditPointModal/EditPoint.vue";
import AddNote from "./AddNote.vue";
import ShowNote from "./ShowNote.vue";
import { VueDraggableNext } from "vue-draggable-next";
import AgendaItemContextMenu from "../../../BasicControls/AgendaItemContextMenu.vue";

import AttachmentService from "../../../../services/attachments.service";
import SessionService from "../../../../services/session.service";
import {
    switchToRoman,
    switchIndexToLetter,
} from "../../../../helpers/index.conversion";

export default {
    name: "SesionPointsList",
    data() {
        return {
            point_ids: [],
            itemId: null,
            editPointVisible: false,
            pickedPoint: {},
            addNoteVisible: false,
            showNote: false,
            noteID: null,
        };
    },
    components: {
        draggable: VueDraggableNext,
        CheckBoxButton,
        EditPoint,
        AddNote,
        ShowNote,
        AgendaItemContextMenu,
    },
    props: {
        items: Array,
        level: {
            type: Number,
            default: 0,
        },
        session: Object,
        firstLevel: Number,
        secondLevel: Number,
        thirdLevel: Number,
        checkedPointIds: Array,
        haveChildren: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.point_ids = this.checkedPointIds;

        document.addEventListener("click", (e) => {
            const agendaContext = document.getElementById("js-context-agenda");
            if (agendaContext !== null) {
                const isClickInside = agendaContext.contains(e.target);
                if (!isClickInside) {
                    document
                        .querySelectorAll(".calendar-data")
                        .forEach((box) => (box.style.display = "none"));
                    agendaContext.style.display = "none";
                    agendaContext.removeAttribute("id");
                }
            }
        });
    },
    updated() {
        this.point_ids = this.checkedPointIds;
    },
    computed: {
        getToken() {
            return this.$store.state.userStore.token;
        },
    },
    methods: {
        openContextMenu(e) {
            e.preventDefault();

            var rect = e.target.getBoundingClientRect();
            var x = e.clientX - rect.left;
            var y = e.clientY - rect.top;
            const agendaContext = document.querySelectorAll(
                ".agenda-item-context-menu"
            );
            agendaContext.forEach((block) => (block.style.display = "none"));
            const contextMenu = e.currentTarget.children[0];
            contextMenu.style.display = "block";
            contextMenu.id = "js-context-agenda";
            const element = document.getElementById(contextMenu.id);
            const viewportOffset = element.getBoundingClientRect();
            const top = viewportOffset.top;
            const left = viewportOffset.left;

            if (left < 0 || top > window.innerHeight) {
                contextMenu.style.position = "absolute";
                contextMenu.style.bottom = "auto";
                contextMenu.style.left = "auto";
                contextMenu.style.top = "0px";
                contextMenu.style.right = "0px";
            } else {
                contextMenu.style.position = "absolute";
                contextMenu.style.bottom = "auto";
                contextMenu.style.top = `${y}px`;
                contextMenu.style.left = `${x}px`;
                contextMenu.style.right = "auto";
            }
        },
        handleDeleteClick(e, id) {
            const actionToFire = () => {
                this.$store.commit("showLoader");
                SessionService.removePoint(id).then(() => {
                    this.$emit("refreshList");
                    this.point_ids = this.point_ids.filter(
                        (point) => point !== id
                    );
                    this.$emit("setPointIds", this.point_ids);
                });
            };
            this.$store.commit("showConfirmationAlert", actionToFire);
        },
        handleAddNoteClick(e, item) {
            this.addNoteVisible = true;
            this.pickedPoint = item;
        },
        handleEditPointClick(e, item) {
            this.pickedPoint = item;
            this.editPointVisible = true;
        },
        canBePlaced(to, from, element) {
            const isMaxStack = to.options.class.split(" ").indexOf("max-stack");
            const hasChildren = element.classList.contains("has-children");
            const hasMaxChildrens =
                element.classList.contains("has-max-children");

            if ((isMaxStack !== -1 && hasChildren) || hasMaxChildrens) {
                return false;
            }
            return true;
        },
        childrenCheck(items) {
            let itemLevels = 1;
            items.forEach((item) => {
                if (item.children.length > 0) {
                    itemLevels += 1;
                    this.childrenCheck(item.children);
                }
            });
            return itemLevels;
        },
        handleFileUpload() {
            const file = this.$refs.file.files[0];
            const formData = new FormData();
            formData.append("file", file);
            formData.append("name", file.name);
            formData.append("public", 1);
            formData.append("show_in_register", 1);
            this.$store.commit("showLoader");
            AttachmentService.addFile(this.itemId, formData).then(() => {
                this.$emit("refreshList");
            });
        },
        filterIndex(index) {
            switch (this.level) {
                case 0:
                    return this.conversionLevel(this.firstLevel, index);
                case 1:
                    return this.conversionLevel(this.secondLevel, index);
                case 2:
                    return this.conversionLevel(this.thirdLevel, index);
                default:
                    return `${index}.`;
            }
        },
        conversionLevel(optionLevel, index) {
            switch (optionLevel) {
                case 1:
                    return `${switchToRoman(index)}`;
                case 2:
                    return `${index}.`;
                case 3:
                    return `${index})`;
                case 4:
                    return `${switchIndexToLetter(index)})`;
                default:
                    return `${index}.`;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.points-list {
    width: 100%;
    position: relative;

    &__point-box-left {
        display: flex;
        flex-direction: column;
    }

    &__arrow {
        position: absolute;
        top: -50px;
        left: -37px;
        display: block;

        &--next {
            top: -120px;
        }
    }
    &__arrow-next {
        position: absolute;
        top: 60px;
    }

    &__attachments {
        margin-left: 50px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__attachment-link {
        font-weight: normal;
        font-size: 14px;
        line-height: 145%;
        color: #333956;
        cursor: pointer;
        margin-left: 11px;

        &:hover {
            text-decoration-line: underline;
            color: #808396;
        }
    }

    &__attachment-wrapper {
        margin-right: 42px;
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 10px;

        &:nth-child(1)::after {
            width: 0px;
            height: 0px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -21px;
            width: 1px;
            height: 16px;
            background: #dde0e7;
        }
    }

    &__actions {
        opacity: 0;
        transition: all 0.2s ease-in-out;
        display: flex;
        align-items: flex-start;
    }

    &__data-wrapper {
        display: flex;
        position: relative;
        width: 100%;
    }

    &__checkbox {
        position: relative;
        display: flex;
    }

    &__checkbox-label {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 6;
    }

    &__point-box {
        padding: 18px;
        padding-bottom: 18px;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        transition: all 0.2s ease-in-out;
        width: 100%;
        cursor: move;
        position: relative;

        &--selected {
            border: 1px solid #00082c;

            &:hover {
                border-color: #00082c !important;
            }
        }

        &:hover .points-list__actions {
            opacity: 1;
        }

        &:hover {
            border-color: #bbc2ce;
        }

        &--no-border {
            padding: 10px 10px 0px 10px;
            border: 0px;
            margin: 0px !important;
        }
    }

    &__number {
        margin-left: 48px;
    }

    &__name {
        font-weight: normal;
        font-size: 16px;
        line-height: 132%;
        overflow-wrap: anywhere;

        &--bold {
            font-weight: 600;
            font-size: 18px;
            line-height: 132%;
            color: #00082c;
        }
    }

    &__number {
        font-weight: 600;
        margin-right: 8px;
    }

    &__file-input {
        display: none;
    }

    &__action-btn {
        width: 32px;
        height: 32px;
        margin-left: 8px;
        background-color: white;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e8ebef;
        }

        &--red {
            border: 1px solid hsla(354, 96%, 43%, 0.317);
        }
    }
}

.session-point {
    padding: 18px;
    padding-bottom: 0px;
    background: #ffffff;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: all 0.2s ease-in-out;

    &--selected {
        border: 1px solid #00082c !important;
        &:hover {
            border-color: #00082c;
        }
    }

    &:hover {
        border-color: #bbc2ce;
    }
}

.dragged-item {
    .points-list--no-children {
        display: none;
    }
}

.dragArea {
    padding-bottom: 18px;
    list-style: none;
    padding-left: 0px;
}

.no-drag {
    padding-bottom: 18px;
}

.children {
    margin-left: 28px;
    border: 0px;
    margin-bottom: 0px;
    padding: 0px;
    margin-top: 0px;

    &:nth-child(1) {
        margin-top: 18px;
    }

    &--last {
        margin-top: 18px;

        .points-list__point-box {
            padding-bottom: 18px;
        }
    }
}

.ghost,
.ghost--first-level {
    background-color: #eef1f7;
    margin-bottom: 18px;

    .points-list--no-children {
        display: none;
    }

    .points-list__arrow {
        display: none;
    }

    .points-list {
        .session-point {
            background-color: #eef1f7;
        }
    }
}
</style>
