<template>
    <section class="edit-meeting">
        <form class="edit-meeting__form">
            <button
                class="edit-meeting__close"
                @click="
                    (e) => {
                        e.preventDefault();
                        this.$emit('setVisible');
                    }
                "
            >
                <img src="../../../../../assets/icons/components/exit.svg" />
            </button>
            <section class="edit-meeting__form-wrapper">
                <h2 class="edit-meeting__section-title">Edytuj sesję</h2>
                <section class="edit-meeting__top">
                    <h3
                        class="
                            edit-meeting__section-title
                            edit-meeting__section-title--small
                        "
                    >
                        Posiedzenie
                    </h3>
                    <section class="edit-meeting__row-double">
                        <section class="edit-meeting__col-double">
                            <label class="edit-meeting__label"
                                >Termin posiedzenia</label
                            >
                            <section class="edit-meeting__picker-wrapper">
                                <input
                                    type="date"
                                    class="edit-meeting__picker"
                                    v-model="meeting_date"
                                    v-error="{
                                        errors,
                                        input_key: 'meeting_date',
                                    }"
                                />
                                <ErrorMessage
                                    v-if="isError('meeting_date', this.errors)"
                                    :errorMsgs="
                                        passErrorMsg(
                                            'meeting_date',
                                            this.errors
                                        )
                                    "
                                />
                            </section>
                        </section>
                        <section class="edit-meeting__col-double">
                            <label class="edit-meeting__label">Godzina</label>
                            <section class="edit-meeting__picker-wrapper">
                                <input
                                    type="time"
                                    class="edit-meeting__picker"
                                    v-model="meeting_time"
                                    v-error="{
                                        errors,
                                        input_key: 'meeting_date',
                                    }"
                                />
                            </section>
                        </section>
                    </section>
                    <section class="edit-meeting__row">
                        <label class="edit-meeting__label"
                            >Nazwa posiedzenia</label
                        >
                        <input
                            type="text"
                            class="edit-meeting__input-text"
                            placeholder="Wpisz nazwę posiedzenia..."
                            v-model="name"
                            v-error="{ errors, input_key: 'name' }"
                        />
                        <ErrorMessage
                            v-if="isError('name', this.errors)"
                            :errorMsgs="passErrorMsg('name', this.errors)"
                        />
                    </section>
                    <section class="edit-meeting__row">
                        <label class="edit-meeting__label"
                            >Miejsce posiedzenia</label
                        >
                        <input
                            type="text"
                            class="edit-meeting__input-text"
                            placeholder="Wpisz miejsce posiedzenia..."
                            v-model="meeting_place"
                            v-error="{ errors, input_key: 'meeting_place' }"
                        />
                        <ErrorMessage
                            v-if="isError('meeting_place', this.errors)"
                            :errorMsgs="
                                passErrorMsg('meeting_place', this.errors)
                            "
                        />
                    </section>
                </section>
                <section class="edit-meeting__groups">
                    <h3
                        class="
                            edit-meeting__section-title
                            edit-meeting__section-title--small
                        "
                    >
                        Uczestniczące grupy
                    </h3>
                    <ErrorMessage
                        v-if="isError('group_ids', this.errors)"
                        :errorMsgs="passErrorMsg('group_ids', this.errors)"
                    />
                    <section
                        class="edit-meeting__checkbox-section"
                        v-for="group in filterGroups()"
                        :key="group.id"
                    >
                        <CheckBoxButton
                            :value="group.id"
                            :id="group.id"
                            :checked="checkedGroupsIds.indexOf(group.id) !== -1"
                            @setGroupId="
                                (value) => (this.checkedGroupsIds = value)
                            "
                            :idsArray="checkedGroupsIds"
                        />
                        <label
                            class="
                                edit-meeting__label
                                edit-meeting__label--checkbox
                            "
                            :for="group.id"
                            >{{ group.name }}</label
                        >
                    </section>
                </section>
                <section class="edit-meeting__identity">
                    <h3
                        class="
                            edit-meeting__section-title
                            edit-meeting__section-title--small
                        "
                    >
                        Dane identyfikacyjne
                    </h3>
                    <section class="edit-meeting__row-double">
                        <section class="edit-meeting__col-double">
                            <label class="edit-meeting__label"
                                >Numer posiedzenia</label
                            >
                            <input
                                type="text"
                                class="
                                    edit-meeting__input-text
                                    edit-meeting__input-text--small
                                "
                                placeholder="Wpisz numer..."
                                v-model="number"
                                v-error="{ errors, input_key: 'number' }"
                            />
                            <ErrorMessage
                                v-if="isError('number', this.errors)"
                                :errorMsgs="passErrorMsg('number', this.errors)"
                            />
                        </section>
                        <section class="edit-meeting__col-double">
                            <label class="edit-meeting__label">Sygnatura</label>
                            <input
                                type="text"
                                class="
                                    edit-meeting__input-text
                                    edit-meeting__input-text--small
                                "
                                placeholder="Wpisz sygnaturę..."
                                v-model="signature"
                                v-error="{ errors, input_key: 'signature' }"
                            />
                            <ErrorMessage
                                v-if="isError('signature', this.errors)"
                                :errorMsgs="
                                    passErrorMsg('signature', this.errors)
                                "
                            />
                        </section>
                    </section>
                    <section class="edit-meeting__switch-section">
                        <SwitchButton
                            label="Sesja publiczna"
                            :isPublic="isPublic"
                            @setIsPublic="(value) => (isPublic = value)"
                        />
                        <section
                            name="externalTrigger"
                            class="edit-meeting__tip-btn"
                        >
                            <img
                                src="../../../../../assets/icons/components/tooltip.svg"
                                class="edit-meeting__tooltip"
                            />
                            <Hint
                                title="Sesja publiczna"
                                text="Aktywna sesja publiczna oznacza, że jest ona widoczna dla wszystkich, także niezarejestrowanych użytkowników."
                            />
                        </section>
                    </section>
                </section>
                <section class="edit-meeting__bottom">
                    <button
                        class="edit-meeting__bottom-btn"
                        @click="
                            (e) => {
                                e.preventDefault();
                                this.$emit('setVisible');
                            }
                        "
                    >
                        Anuluj
                    </button>
                    <button
                        @click="(e) => editSession(e)"
                        class="
                            edit-meeting__bottom-btn
                            edit-meeting__bottom-btn--red
                        "
                    >
                        Zapisz
                    </button>
                    <LoaderInside v-if="isLoading" />
                </section>
            </section>
        </form>
    </section>
</template>

<script>
import MeetingService from "../../../../../services/meetings.service";
import SessionService from "../../../../../services/session.service";
import moment from "moment";
import {
    passErrorMsg,
    isError,
    setErrors,
} from "../../../../../formValidation/main";

import CheckBoxButton from "../../../../BasicControls/CheckBoxButton.vue";
import SwitchButton from "../../../../BasicControls/SwitchButton.vue";
import Hint from "../../../../BasicControls/Hint.vue";
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import ErrorMessage from "../../../../BasicControls/ErrorMessage.vue";

export default {
    name: "EditMeeting",
    data() {
        return {
            groups: [],
            checkedGroupsIds: [],
            name: "",
            isPublic: false,
            comment: "",
            is_authorized: false,
            is_published: false,
            meeting_date: "",
            meeting_time: "",
            meeting_place: "",
            number: "",
            signature: "",
            isLoading: false,
            errors: [],
        };
    },
    props: {
        session: Object,
    },
    components: {
        CheckBoxButton,
        SwitchButton,
        Hint,
        LoaderInside,
        ErrorMessage,
    },
    mounted() {
        this.isLoading = true;
        MeetingService.groups().then((response) => {
            this.groups = response.data.data;
            this.isLoading = false;
        });
        const {
            name,
            number,
            signature,
            meeting_place,
            meeting_date,
            is_published,
            is_public,
            is_authorized,
            groups,
            comment,
        } = this.session;
        this.checkedGroupsIds = groups.map((group) => group.id);
        this.name = name;
        this.number = number;
        this.signature = signature;
        this.meeting_place = meeting_place;
        this.meeting_date = this.getDate(meeting_date);
        this.meeting_time = this.getTime(meeting_date);
        this.is_published = is_published;
        this.isPublic = is_public;
        this.is_authorized = is_authorized;
        this.comment = comment;
    },
    methods: {
        editSession(e) {
            e.preventDefault();
            const data = {
                name: this.name,
                number: this.number,
                signature: this.signature,
                meeting_place: this.meeting_place,
                meeting_date: this.meeting_date,
                is_published: this.is_published,
                is_public: this.isPublic,
                is_authorized: this.is_authorized,
                group_ids: this.checkedGroupsIds,
                comment: this.comment,
            };
            this.isLoading = true;
            this.errors = [];
            SessionService.update(this.session.id, data)
                .then(() => {
                    SessionService.getSession(this.session.id).then(
                        (response) => {
                            this.$emit("setSession", response.data);
                            SessionService.getGroups().then((response) => {
                                this.$store.commit(
                                    "setGroups",
                                    response.data.data
                                );
                                this.isLoading = false;
                            });
                        }
                    );
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.errors = setErrors(err);
                });
        },
        getDate(date) {
            return moment(date).format("yyyy-MM-DD");
        },
        getTime(date) {
            return moment(date).format("LT");
        },
        filterGroups() {
            return this.groups.filter((group) => group.can_sessions);
        },
        passErrorMsg,
        isError,
        setErrors,
    },
};
</script>

<style lang="scss" scoped>
.edit-meeting {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: #00082c75;
    z-index: 11;

    &__form-wrapper {
        max-height: 80vh;
        overflow-y: auto;
        padding-right: 56px;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #eef1f7;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #eef1f7;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }
    }

    &__form {
        max-height: 90vh;
        padding: 28px 56px;
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        max-width: 612px;
        box-sizing: border-box;
        padding-bottom: 0px;
        padding-right: 0px;
    }

    &__close {
        border: 0px;
        position: relative;
        right: calc(-100% + 48px);
        background-color: transparent;
    }

    &__section-title {
        font-weight: 600;
        font-size: 28px;
        line-height: 128%;
        color: #00082c;
        margin-bottom: 32px;

        &--small {
            font-size: 20px;
            margin-bottom: 24px;
        }
    }

    &__top {
        padding-top: 28px;
        padding-bottom: 28px;
        border-top: 1px solid #dde0e7;
        border-bottom: 1px solid #dde0e7;
    }

    &__row-double {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    &__col-double {
        display: flex;
        flex-direction: column;
    }

    &__label {
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
        margin-bottom: 8px;

        &--checkbox {
            padding-left: 32px;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            color: #333956;
            margin-bottom: 0px;
            z-index: 8;
            cursor: pointer;
        }
    }

    &__picker {
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        min-width: 238px;
        height: 56px;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;

        &[type="date"]::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url("../../../../../assets/icons/components/callendar.svg")
                no-repeat;
            background-position-x: 100%;
            width: 22px;
            height: 22px;
            border-width: thin;
        }

        &[type="time"]::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url("../../../../../assets/icons/components/clock.svg")
                no-repeat;
            width: 22px;
            height: 22px;
            border-width: thin;
        }
    }

    &__picker-wrapper {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 8px;
            background: #e2e5eb;
            width: 1px;
            height: 40px;
            right: 56px;
            z-index: 99;
        }
    }

    &__row {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }

    &__input-text {
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        min-width: 500px;
        height: 56px;
        font-size: 16px;
        color: #00082c;

        &:focus {
            border: 1px solid #00082c;
        }

        &::placeholder {
            font-size: 16px;
            line-height: 144%;
            color: #808396;
        }

        &--small {
            min-width: 238px;
        }
    }

    &__groups {
        margin-top: 28px;
        padding-bottom: 38px;
        border-bottom: 1px solid #dde0e7;
    }

    &__checkbox-section {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }

    &__identity {
        margin-top: 28px;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        padding-bottom: 56px;
    }

    &__bottom-btn {
        padding: 18px 40px 17px;
        width: 238px;
        height: 56px;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: transparent;
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        color: #00082c;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e8ebef;
        }

        &--red {
            background-color: #d80418;
            border: 0px;
            color: white;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #ad0313;
            }
        }
    }

    &__switch-section {
        display: flex;
        margin-bottom: 40px;
    }

    &__tooltip {
        position: relative;
        left: 5px;
        top: -3px;
    }

    &__tip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        background-color: transparent;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;

        &:hover .hint {
            opacity: 1;
            display: block;
        }
    }
}
</style>
