<template>
    <section class="additional-menu">
        <ul class="additional-menu__groups">
            <li
                v-for="(group, key) in canSession()"
                :key="key"
                class="additional-menu__link-wrapper"
                :ref="`mainContainer${key}`"
                @mouseover="
                    () =>
                        setMenuElementPosition(
                            this.$refs[`mainContainer${key}`],
                            this.$refs[`subMenu${key}`]
                        )
                "
            >
                <span class="additional-menu__link">{{ group.name }}</span>
                <img
                    src="../../../assets/icons/components/additional-arrow.svg"
                    class="additional-menu__arrow"
                />
                <div
                    class="additional-menu__sub-wrapper"
                    :ref="`subMenu${key}`"
                >
                    <MenuSessionList
                        :groupID="group.id"
                        :sessions="group.sessions"
                    />
                </div>
            </li>
            <SmallLoader v-if="isLoading" />
        </ul>
        <section
            class="additional-menu__add"
            @click="$store.commit('showAddMeeting')"
        >
            <SmallAddButtonRed />
            <span class="additional-menu__add-text">Dodaj posiedzenie</span>
        </section>
    </section>
</template>

<script>
import SmallAddButtonRed from "../../BasicControls/SmallAddButtonRed.vue";
import NavService from "../../../services/nav.service";
import MenuSessionList from "./MenuSessionList.vue";
import SmallLoader from "../../BasicControls/SmallLoader.vue";
import setMenuElementPosition from "../../../helpers/setMenuElementPosition";

export default {
    name: "AdditionalMenuMeetings",
    data() {
        return {
            isLoading: true,
        };
    },
    components: {
        SmallAddButtonRed,
        MenuSessionList,
        SmallLoader,
    },
    mounted() {
        NavService.groups().then((response) => {
            this.isLoading = false;
            this.$store.commit("setGroups", response.data.data);
        });
    },
    methods: {
        canSession() {
            return this.$store.state.appStore.groups.filter(
                (group) => group.can_sessions
            );
        },
        setMenuElementPosition,
    },
};
</script>

<style lang="scss" scoped>
.additional-menu {
    display: none;
    background-color: white;
    z-index: 999;
    background: #ffffff;
    border: 1px solid #dce4ee;
    border-radius: 12px;
    padding: 25px;
    list-style: none;
    width: 270px;

    &__link {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #00082c;
    }

    &__link-wrapper {
        padding: 13px;
        transition: all 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &:hover {
            background-color: #f4f6fa;
        }

        &:hover .sessions {
            display: flex;
        }
    }

    &__groups {
        list-style: none;
        padding: 0px;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 20vh;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #eef1f7;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #eef1f7;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }
    }

    &__add {
        display: flex;
        padding-top: 24px;
        align-items: center;
        border-top: 1px solid #dce4ee;
    }

    &__add-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #d80418;
        margin-left: 12px;
    }

    &__sub-wrapper {
        position: fixed;
        top: 0px;
        left: 0px;
    }
}
</style>
