<template>
    <section class="attachment-edit">
        <section class="attachment-edit__top">
            <section class="attachment-edit__name">
                <img
                    :src="
                        require(`../../../../../assets/icons/components/${
                            attachment.type_id === 1 ? 'attachment' : 'note'
                        }.svg`)
                    "
                    class="attachment-edit__type-icon"
                />
                <span class="attachment-edit__name-text">{{ name }}</span>
            </section>
            <section class="attachment-edit__actions-wrapper">
                <button
                    v-if="!isEditVisible"
                    class="attachment-edit__action-btn"
                    @click="
                        (e) => {
                            e.preventDefault();
                            isEditVisible = !isEditVisible;
                        }
                    "
                >
                    <img
                        src="../../../../../assets/icons/components/edit.svg"
                        class="attachment-edit__action-icon"
                    />
                </button>
                <button
                    class="
                        attachment-edit__action-btn
                        attachment-edit__action-btn--red
                    "
                    @click="removeAttachment"
                >
                    <img
                        src="../../../../../assets/icons/components/bin.svg"
                        class="attachment-edit__action-icon"
                    />
                </button>
            </section>
        </section>
        <EditAttachment
            v-if="isEditVisible"
            :attachment="attachment"
            @closeEdit="isEditVisible = false"
            @reload="
                (attachmentName) => {
                    this.name = attachmentName;
                    this.getPointData();
                }
            "
        />
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import EditAttachment from "./EditAttachment.vue";
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";

import AttachmentService from "../../../../../services/attachments.service";
import SessionService from "../../../../../services/session.service";

export default {
    name: "Attachment",
    data() {
        return {
            name: "",
            isEditVisible: false,
            isLoading: false,
        };
    },
    props: {
        attachment: Object,
        pointID: Number,
    },
    components: {
        EditAttachment,
        LoaderInside,
    },
    methods: {
        removeAttachment(e) {
            e.preventDefault();
            const actionToFire = () => {
                this.isLoading = true;
                if (this.attachment.type_id === 1) {
                    AttachmentService.deleteFile(this.attachment.id).then(
                        () => {
                            this.$emit("reloadData");
                            this.getPointData();
                        }
                    );
                } else {
                    AttachmentService.deleteNote(this.attachment.id).then(
                        () => {
                            this.$emit("reloadData");
                            this.getPointData();
                        }
                    );
                }
            };

            this.$store.commit("showConfirmationAlert", actionToFire);
        },
        getPointData() {
            this.isLoading = true;
            SessionService.getPoint(this.pointID).then((response) => {
                this.$emit("reloadPoint", response.data);
                this.isLoading = false;
            });
        },
    },
    mounted() {
        this.name = this.attachment.name;
    },
};
</script>

<style lang="scss" scoped>
.attachment-edit {
    width: 100%;
    background: white;
    border: 1px solid #d1d6df;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
    border-radius: 4px;
    margin-bottom: 16px;

    &:hover .attachment-edit__actions-wrapper {
        opacity: 1;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px 8px 16px;
    }

    &__name {
        display: flex;
        align-items: center;
    }

    &__type-icon {
        margin-right: 11px;
    }

    &__name-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;
    }

    &__actions-wrapper {
        display: flex;
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }

    &__action-btn {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        background: #ffffff;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e8ebef;
        }

        &--red {
            border: 1px solid hsla(354, 96%, 43%, 0.324);
        }
    }
}
</style>
