<template>
    <section class="loader">
        <section class="loader__spin"></section>
    </section>
</template>

<script>
export default {
    name: "LoaderInside",
};
</script>

<style lang="scss" scoped>
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 0px;
    left: 0px;
    position: fixed;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.482);
    z-index: 9999;

    &__spin {
        border: 8px solid #f3f3f3;
        border-top: 8px solid #004dcc;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: spin 2s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
