import meetingsService from "../services/meetings.service";
import store from "../store/mainStore";

export const meetingsActions = [
    {
        id: 0,
        action: "sessionEdit",
        name: "Edytuj porządek obrad",
        icon: "edit-session-list",
        link: "sessions",
        handler: () => {},
    },
    {
        id: 1,
        action: "",
        name: "Tryb sesji interaktywnej",
        icon: "display",
        handler: () => {},
    },
    {
        id: 2,
        action: "",
        name: "Ustawienia posiedzenia",
        icon: "settings",
        handler: (id) => {
            store.commit("setSessionId", id);
            store.commit("showAddMeeting");
        },
    },
    {
        id: 3,
        action: "",
        name: "Przygotuj zaproszenia na posiedzenie",
        icon: "wallet",
        handler: () => {},
    },
    {
        id: 4,
        action: "",
        name: "Pobierz projekt protokołu",
        icon: "download",
        handler: () => {},
    },
    {
        id: 5,
        action: "",
        name: "Dodaj protokół",
        icon: "add-protocol",
        handler: () => {},
    },
    {
        id: 6,
        action: "",
        name: "Eksportuj posządek obrad do pliku .DOC",
        icon: "export-to-doc",
        handler: () => {},
    },
    {
        id: 7,
        action: "",
        name: "Poka matryczkę posiedzenia",
        icon: "timer",
        handler: () => {},
    },
    {
        id: 8,
        action: "",
        name: "Usuń posiedzenie",
        icon: "delete",
        handler: (id) => {
            const actionToFire = () => {
                store.commit("showLoader");
                meetingsService.removeSession(id).then(() => {
                    meetingsService.groups().then((response) => {
                        store.commit("hideLoader");
                        store.commit("setGroups", response.data.data);
                        store.commit("showSuccess");
                    });
                });
            };
            store.commit("showConfirmationAlert", actionToFire);
        },
    },
];
