<template>
    <section class="contact-box">
        <section
            class="contact-box__top"
            @click="this.isBoxOpen = !this.isBoxOpen"
        >
            <section>
                <img
                    src="../../../../../assets/icons/panel/Contacts/contact-icon.svg"
                    class="contact-box__icon"
                />
                <span class="contact-box__title"
                    >{{ name }} ({{ people.length }})</span
                >
                <img
                    src="../../../../../assets/icons/panel/Contacts/list-arrow.svg"
                    class="contact-box__arrow"
                    :class="{ 'contact-box__arrow--open': isBoxOpen }"
                />
            </section>
            <section class="contact-box-actions">
                <ButtonWithIconContacts
                    icon="add-contact-small-black"
                    text="Dodaj kontakt"
                    @click="
                        (e) =>
                            addContactHandler(e, 'add-contact', {
                                id: groupId,
                                action: 'add-contact-to-group',
                            })
                    "
                />
                <ButtonOnlyWithIcon
                    icon="edit"
                    @click="
                        (e) => {
                            addContactHandler(e, 'add-group', {
                                id: groupId,
                                action: 'edit-group',
                            });
                        }
                    "
                />
                <ButtonOnlyWithIcon
                    icon="delete"
                    @click="() => handleDeleteClick(this.groupId)"
                />
            </section>
        </section>
        <section
            class="contact-box__people contact-box__people--open"
            v-if="isBoxOpen || isFilter"
        >
            <section v-if="people.length > 0">
                <section class="contact-box-description">
                    <span class="contact-box-description__data"
                        >imię i nazwisko</span
                    >
                    <span class="contact-box-description__data"
                        >adres e-mail</span
                    >
                    <span class="contact-box-description__data">telefon</span>
                </section>
                <section class="contact-box__people-container">
                    <People
                        v-for="person in people"
                        :key="person.id"
                        :name="person.first_name"
                        :lastName="person.last_name"
                        :mail="person.email"
                        :number="person.phone_number"
                        :id="person.id"
                        :isActive="true"
                        :isLocked="false"
                        @reloadGroups="
                            (groups) => this.$emit('reloadGroups', groups)
                        "
                        :addContactHandler="addContactHandler"
                    />
                </section>

                <section
                    class="add-contact-button"
                    @click="
                        (e) =>
                            addContactHandler(e, 'add-contact', {
                                id: groupId,
                                action: 'add-contact-to-group',
                            })
                    "
                >
                    <SmallAddButtonRed />
                    <span class="add-contact-button__text">Dodaj kontakt</span>
                </section>
            </section>
            <section v-else class="contacts-no-data">
                <p>Brak kontaktów</p>
                <section
                    class="add-contact-button add-contact-button--no-margin"
                    @click="
                        (e) =>
                            addContactHandler(e, 'add-contact', {
                                id: groupId,
                                action: 'add-contact-to-group',
                            })
                    "
                >
                    <SmallAddButtonRed />
                    <span class="add-contact-button__text">Dodaj kontakt</span>
                </section>
            </section>
        </section>
    </section>
</template>

<script>
import ContactService from "../../../../../services/contact.service";

import People from "./People.vue";
import SmallAddButtonRed from "../../../../BasicControls/SmallAddButtonRed.vue";
import ButtonOnlyWithIcon from "../../../../BasicControls/ButtonOnlyWithIcon.vue";
import ButtonWithIconContacts from "../../../../BasicControls/ButtonWithIconContacts.vue";

export default {
    name: "ContactBox",
    data() {
        return {
            isBoxOpen: false,
        };
    },
    props: {
        name: String,
        groupId: Number,
        people: Array,
        addContactHandler: Function,
        isFilter: Boolean,
    },
    components: {
        People,
        SmallAddButtonRed,
        ButtonOnlyWithIcon,
        ButtonWithIconContacts,
    },
    methods: {
        handleDeleteClick(id) {
            const actionToFire = () => {
                this.$store.state.userStore.isLoading = true;
                ContactService.deleteGroup(id).then(() => {
                    ContactService.show().then((response) => {
                        this.$store.state.userStore.isLoading = false;
                        this.$emit("reloadGroups", response.data.data);
                        this.$store.commit("setGroups", response.data.data);
                    });
                });
            };

            this.$store.commit("showConfirmationAlert", actionToFire);
        },
    },
};
</script>

<style lang="scss" scoped>
.contact-box {
    background: #ffffff;
    width: 100%;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
    border-radius: 12px;
    margin-bottom: 12px;
    transition: all 0.2s ease-in-out;

    &:hover .contact-box-actions {
        opacity: 1;
    }

    &:hover {
        border: 1px solid #bbc2ce;
    }

    &__title {
        margin-left: 16px;
        margin-right: 16px;
        font-weight: 600;
        font-size: 18px;
        line-height: 132%;
        color: #00082c;
    }

    &__top {
        display: flex;
        align-items: center;
        padding: 29px;
        border-bottom: 1px solid #dce4ee;
        cursor: pointer;
        justify-content: space-between;
    }

    &__people {
        transition: all 0.3s ease-in-out;
        &--close {
            max-height: 0px;
            display: none;
        }

        &--open {
            max-height: 10000px;
        }
    }

    &__people-container {
        padding: 16px;
        background: #f4f6fa;
        border-radius: 12px;
        margin: 0px 16px;

        &--close {
            max-height: 0px;
        }
    }

    &__arrow {
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;

        &--open {
            transform: rotate(180deg);
        }
    }
}

.contact-box-description {
    margin: 25px 34px 16px 34px;

    &__data {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #666b80;

        &:nth-child(1) {
            margin-right: 228px;

            @media only screen and (max-width: 1600px) {
                margin-right: 138px;
            }
        }

        &:nth-child(2) {
            margin-right: 193px;
        }
    }
}

.add-contact-button {
    display: flex;
    align-items: center;
    margin: 20px 32px;
    cursor: pointer;

    &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #00082c;
        margin-left: 8px;
    }

    &--no-margin {
        margin: 37px 0px 0px 0px;
    }
}

.contacts-no-data {
    display: flex;
    margin: 27px;
    flex-direction: column;
}

.contact-box-actions {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}
</style>
