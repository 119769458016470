<template>
    <button class="small-add-button-red">
        <img
            src="../../assets/icons/components/plus-small.svg"
            class="small-add-button-red__icon"
        />
    </button>
</template>

<script>
export default {
    name: "SmallAddButtonRed",
};
</script>

<style lang="scss" scoped>
.small-add-button-red {
    background-color: #d80418;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    width: 20px;
    height: 20px;

    &__icon {
        padding: 6px;
    }
}
</style>
