<template>
    <router-link
        class="meeting-box"
        :to="{
            name: 'sessionEdit',
            params: { sessionid: id, groupid: groupid },
        }"
    >
        <section class="meeting-box__left">
            <h4 class="meeting-box__title">{{ name }}</h4>
            <p class="meeting-box__under-text" v-if="number">
                Posiedzenie nr {{ number }}
            </p>
        </section>
        <section class="meeting-box__right">
            <section class="meeting-box__info-box">
                <img
                    src="../../../../../assets/icons/panel/home/callendar.svg"
                    class="meeting-box__icon"
                />
                <span
                    class="meeting-box__info-text meeting-box__info-text--date"
                    >{{ getDate(dateTime) }}</span
                >
            </section>
            <section class="meeting-box__info-box">
                <img
                    src="../../../../../assets/icons/panel/home/time.svg"
                    class="meeting-box__icon"
                />
                <span class="meeting-box__info-text"
                    >Godzina: {{ getTime(dateTime) }}</span
                >
            </section>
        </section>
    </router-link>
</template>

<script>
import moment from "moment";

export default {
    name: "Meeting",
    props: {
        name: String,
        number: String,
        dateTime: String,
        id: Number,
        groupid: Number,
    },
    methods: {
        getDate(dateTime) {
            const day = moment(dateTime).format("dddd");
            const full_date = moment(dateTime).format("DD.MM.YYYY");

            return `${day}, ${full_date}`;
        },
        getTime(dateTime) {
            const hour = moment(dateTime).format("LT");
            return hour;
        },
    },
};
</script>

<style lang="scss" scoped>
.meeting-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
    border-radius: 12px;
    padding: 19px 28px;
    margin-top: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #f4f6fa;
        border: 1px solid #bbc2ce;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 132%;
        color: #00082c;
    }

    &__under-text {
        font-size: 14px;
        line-height: 145%;
        color: #333956;
    }

    &__right {
        display: flex;
    }

    &__info-box {
        margin-left: 41px;
        display: flex;
        align-items: center;
    }

    &__icon {
        width: 18px;
        height: 18px;
    }

    &__info-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
        margin-left: 11px;

        &--date::first-letter {
            text-transform: uppercase;
        }
    }
}
</style>
