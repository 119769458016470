<template>
    <section class="panel">
        <NavMenu v-if="isLoading" />
        <Content v-if="isLoading" />
    </section>
</template>

<script>
import NavMenu from "../components/Panel/NavMenu/NavMenu.vue";
import Content from "../components/Panel/Content/Content.vue";

export default {
    name: "Panel",
    computed: {
        isLoading() {
            if (this.$store.state.userStore.token === "") {
                return false;
            } else {
                return true;
            }
        },
    },
    components: {
        NavMenu,
        Content,
    },
};
</script>

<style lang="scss" scoped>
.panel {
    display: flex;
}
</style>
