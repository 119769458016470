<template>
    <section class="meetings-options">
        <label class="meetings-options__label">Nazwa posiedzenia</label>
        <ErrorMessage
            v-if="isError('session_default_name', this.currentErrors)"
            :errorMsgs="
                passErrorMsg('session_default_name', this.currentErrors)
            "
        />
        <input
            type="text"
            class="meetings-options__input-text"
            v-model="meetingName"
            @keyup="this.$emit('meetingName', meetingName)"
            v-error="{ errors, input_key: 'session_default_name' }"
        />
        <label class="meetings-options__label"
            >Formmat numeracji posiedzeń</label
        >
        <section class="meetings-options__radio-section">
            <p class="meetings-options__number-format-presentation">
                Posiedzenie numer:
                <span
                    class="meetings-options__number-format-presentation--blue"
                    >{{ format }}</span
                >
            </p>
            <section
                class="meetings-options__radio-wrapper"
                @click="
                    () => {
                        this.format = 'III';
                        this.pickedFormat = 1;
                        this.isNumbered = true;
                        this.$emit('setIsSessionNumbered', this.isNumbered);
                        this.$emit('setSessionNumberFormat', this.pickedFormat);
                    }
                "
            >
                <InputRadio
                    id="roman-type"
                    :checked="format === 'III'"
                    name="foramt"
                />
                <label for="roman-type" class="meetings-options__label-radio"
                    >Cyfry rzymskie [ I, II, III ]</label
                >
            </section>
            <section
                class="meetings-options__radio-wrapper"
                @click="
                    () => {
                        this.format = '3';
                        this.pickedFormat = 2;
                        this.isNumbered = true;
                        this.$emit('setIsSessionNumbered', this.isNumbered);
                        this.$emit('setSessionNumberFormat', this.pickedFormat);
                    }
                "
            >
                <InputRadio
                    id="arab-type"
                    :checked="format === '3'"
                    name="foramt"
                />
                <label for="arab-type" class="meetings-options__label-radio"
                    >Cyfry arabskie [ 1, 2, 3 ]</label
                >
            </section>
            <section
                class="meetings-options__radio-wrapper"
                @click="
                    () => {
                        this.format = '3)';
                        this.pickedFormat = 3;
                        this.isNumbered = true;
                        this.$emit('setIsSessionNumbered', this.isNumbered);
                        this.$emit('setSessionNumberFormat', this.pickedFormat);
                    }
                "
            >
                <InputRadio
                    id="arab-type-with"
                    :checked="format === '3)'"
                    name="foramt"
                />
                <label
                    for="arab-type-with"
                    class="meetings-options__label-radio"
                    >Cyfry z nawiasem [ 1), 2), 3) ]</label
                >
            </section>
            <section
                class="meetings-options__radio-wrapper"
                @click="
                    () => {
                        this.format = 'c)';
                        this.pickedFormat = 4;
                        this.isNumbered = true;
                        this.$emit('setIsSessionNumbered', this.isNumbered);
                        this.$emit('setSessionNumberFormat', this.pickedFormat);
                    }
                "
            >
                <InputRadio
                    id="letter-type"
                    :checked="format === 'c)'"
                    name="foramt"
                />
                <label for="letter-type" class="meetings-options__label-radio"
                    >Litery [ a), b), c) ]</label
                >
            </section>
        </section>
        <label class="meetings-options__label"
            >Formmat numeracji punktów porządku obrad</label
        >
        <section class="preview">
            <section class="preview__row">
                <section class="preview__left">
                    <span class="preview__text preview__text--blue">{{
                        meetingsFormatTop
                    }}</span>
                    <span class="preview__text">Lorem ipsum dolor</span>
                </section>
                <section class="preview__right">
                    <span class="preview__desc">Wiersz główny</span>
                </section>
            </section>
            <section class="preview__row">
                <section class="preview__left">
                    <img
                        src="../../../../../../assets/icons/components/L-top.svg"
                        class="preview__icon"
                    />
                    <span class="preview__text preview__text--blue">{{
                        meetingsFormatFirst
                    }}</span>
                    <span class="preview__text">Lorem ipsum dolor</span>
                </section>
                <section class="preview__right">
                    <span class="preview__desc">Pierwsze wcięcie</span>
                </section>
            </section>
            <section class="preview__row">
                <section class="preview__left">
                    <img
                        src="../../../../../../assets/icons/components/L-bottom.svg"
                        class="preview__icon preview__icon--large"
                    />
                    <span class="preview__text preview__text--blue">{{
                        meetingsFormatSec
                    }}</span>
                    <span class="preview__text">Lorem ipsum dolor</span>
                </section>
                <section class="preview__right">
                    <span class="preview__desc">Drugie wcięcie</span>
                </section>
            </section>
        </section>
        <section class="meetings-settings">
            <section class="meetings-settings__row">
                <label
                    class="
                        meetings-options__label
                        meetings-options__label--no-margin
                    "
                    for="main-row"
                    >Wiersz główny</label
                >
                <section class="meetings-settings__wrapper">
                    <select
                        id="main-row"
                        class="meetings-settings__select"
                        :value="meetingsFormatTop"
                        @change="
                            (e) => {
                                this.meetingsFormatTop = e.target.value;
                                this.meetingsFormatTopPicked =
                                    this.handleSelectChange(e.target.value);
                                this.$emit(
                                    'setAgendaNumberFormat1',
                                    this.meetingsFormatTopPicked
                                );
                            }
                        "
                    >
                        <option value="III">
                            Cyfry rzymskie [ I, II, III ]
                        </option>
                        <option value="1.">Cyfry arabskie [ 1, 2, 3 ]</option>
                        <option value="2)">
                            Cyfry z nawiasem [ 1), 2), 3) ]
                        </option>
                        <option value="a)">Litery [ a), b), c) ]</option>
                    </select>
                </section>
            </section>
            <section class="meetings-settings__row">
                <label
                    class="
                        meetings-options__label
                        meetings-options__label--no-margin
                    "
                    for="main-row"
                    >Pierwsze wcięcie</label
                >
                <section class="meetings-settings__wrapper">
                    <select
                        id="main-row"
                        class="meetings-settings__select"
                        :value="meetingsFormatFirst"
                        @change="
                            (e) => {
                                this.meetingsFormatFirst = e.target.value;
                                this.meetingsFormatFirstPicked =
                                    this.handleSelectChange(e.target.value);
                                this.$emit(
                                    'setAgendaNumberFormat2',
                                    this.meetingsFormatFirstPicked
                                );
                            }
                        "
                    >
                        <option value="III">
                            Cyfry rzymskie [ I, II, III ]
                        </option>
                        <option value="1.">Cyfry arabskie [ 1, 2, 3 ]</option>
                        <option value="2)">
                            Cyfry z nawiasem [ 1), 2), 3) ]
                        </option>
                        <option value="a)">Litery [ a), b), c) ]</option>
                    </select>
                </section>
            </section>
            <section class="meetings-settings__row">
                <label
                    class="
                        meetings-options__label
                        meetings-options__label--no-margin
                    "
                    for="main-row"
                    >Drugie wcięcie</label
                >
                <section class="meetings-settings__wrapper">
                    <select
                        id="main-row"
                        class="meetings-settings__select"
                        :value="meetingsFormatSec"
                        @change="
                            (e) => {
                                this.meetingsFormatSec = e.target.value;
                                this.meetingsFormatSecPicked =
                                    this.handleSelectChange(e.target.value);
                                this.$emit(
                                    'setAgendaNumberFormat3',
                                    this.meetingsFormatSecPicked
                                );
                            }
                        "
                    >
                        <option value="III">
                            Cyfry rzymskie [ I, II, III ]
                        </option>
                        <option value="1.">Cyfry arabskie [ 1, 2, 3 ]</option>
                        <option value="2)">
                            Cyfry z nawiasem [ 1), 2), 3) ]
                        </option>
                        <option value="a)">Litery [ a), b), c) ]</option>
                    </select>
                </section>
            </section>
        </section>
    </section>
</template>

<script>
import InputRadio from "../../../../../BasicControls/InputRadio.vue";
import ErrorMessage from "../../../../../BasicControls/ErrorMessage.vue";

import { AddContactOptions } from "../../../../../../config/config";
import { passErrorMsg, isError } from "../../../../../../formValidation/main";

export default {
    name: "MeetingsOptions",
    data() {
        return {
            isNumbered: false,
            meetingName: "",
            format: AddContactOptions.defaultRomanum,
            pickedFormat: "",
            meetingsFormatTop: AddContactOptions.defaultRomanum,
            meetingsFormatTopPicked: 1,
            meetingsFormatFirst: AddContactOptions.defaultRomanum,
            meetingsFormatFirstPicked: 1,
            meetingsFormatSec: AddContactOptions.defaultRomanum,
            meetingsFormatSecPicked: 1,
            currentErrors: [],
        };
    },
    components: {
        InputRadio,
        ErrorMessage,
    },
    props: {
        meeting_name: String,
        isSessionNumbered: Boolean,
        sessionNumberFormat: Number,
        agendaNumberFormat1: Number,
        agendaNumberFormat2: Number,
        agendaNumberFormat3: Number,
        errors: Array,
    },
    mounted() {
        this.currentErrors = this.errors;
        if (this.meeting_name.length > 0) {
            this.meetingName = this.meeting_name;
            this.isNumbered = this.isSessionNumbered;
            this.pickedFormat = this.sessionNumberFormat;
            this.meetingsFormatTopPicked = this.agendaNumberFormat1;
            this.meetingsFormatFirstPicked = this.agendaNumberFormat2;
            this.meetingsFormatSecPicked = this.agendaNumberFormat3;
            this.meetingsFormatTop = this.formatFilter(
                this.agendaNumberFormat1
            );
            this.meetingsFormatFirst = this.formatFilter(
                this.agendaNumberFormat2
            );
            this.meetingsFormatSec = this.formatFilter(
                this.agendaNumberFormat3
            );

            switch (this.sessionNumberFormat) {
                case 1:
                    this.format = AddContactOptions.defaultRomanium;
                    break;
                case 2:
                    this.format = AddContactOptions.defaultNumeric;
                    break;
                case 3:
                    this.format = AddContactOptions.defaultNumericWithBracket;
                    break;
                case 4:
                    this.format = AddContactOptions.defaultLetters;
                    break;
                default:
                    return null;
            }
        }
    },
    methods: {
        handleSelectChange(value) {
            switch (value) {
                case AddContactOptions.pickValueDefaultRomanum:
                    return 1;
                case AddContactOptions.pickValueDefaultNumberDot:
                    return 2;
                case AddContactOptions.pickValueDefaultNumberBracket:
                    return 3;
                case AddContactOptions.pickValueDefaultLetter:
                    return 4;
                default:
                    return null;
            }
        },
        formatFilter(id) {
            switch (id) {
                case 1:
                    return AddContactOptions.pickValueDefaultRomanum;
                case 2:
                    return AddContactOptions.pickValueDefaultNumberDot;
                case 3:
                    return AddContactOptions.pickValueDefaultNumberBracket;
                case 4:
                    return AddContactOptions.pickValueDefaultLetter;
                default:
                    return null;
            }
        },
        passErrorMsg,
        isError,
    },
};
</script>

<style lang="scss" scoped>
.meetings-options {
    padding: 24px 16px;
    border-top: 1px solid #d1d6df;

    &__label {
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        display: block;
        color: #00082c;
        margin-bottom: 8px;

        &--no-margin {
            margin-bottom: 0px;
        }
    }

    &__input-text {
        width: 100%;
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;
        margin-bottom: 24px;
        &:focus {
            border: 1px solid #00082c;
        }
    }

    &__radio-section {
        padding-top: 24px;
        border-top: 1px solid #d1d6df;
        padding-bottom: 24px;
        border-bottom: 1px solid #d1d6df;
        margin-bottom: 24px;
    }

    &__number-format-presentation {
        padding: 15px 16.38px;
        border: 1px dashed #3380ff;
        background: #e5efff;
        border-radius: 4px;
        font-size: 14px;
        line-height: 132%;
        color: #333956;
        margin-bottom: 21px;

        &--blue {
            color: #004dcc;
            font-weight: 600;
        }
    }

    &__radio-wrapper {
        display: flex;
        margin-bottom: 11px;
        align-items: center;
        cursor: pointer;
    }

    &__label-radio {
        margin-left: 31px;
        font-size: 16px;
        line-height: 144%;
        color: #333956;
        cursor: pointer;
    }
}

.preview {
    padding: 15px 16px;
    background: #e5efff;
    border: 1px dashed #3380ff;
    border-radius: 4px;
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 7px;

        &:nth-last-child(1) {
            margin-bottom: 0px;
        }
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__text {
        color: #333956;
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;

        &--blue {
            color: #004dcc;
            margin-right: 16px;
        }
    }

    &__icon {
        margin-right: 6px;

        &--large {
            position: relative;
            top: -8px;
        }
    }

    &__desc {
        font-weight: 500;
        font-size: 11px;
        line-height: 120%;
        text-align: right;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #808396;
    }
}

.meetings-settings {
    margin-top: 20px;

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    &__wrapper {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 8px;
            background: #e2e5eb;
            width: 1px;
            height: 24px;
            right: 40px;
            z-index: 99;
        }
    }

    &__select {
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
        border-radius: 4px;
        padding: 8px 10px 8px 16px;
        font-weight: normal;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("../../../../../../assets/icons/components/select-arrow.svg");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 14px);
        background-position-y: 50%;
        min-width: 300px;
        min-height: 40px;

        &:focus {
            outline: none;
        }
    }
}
</style>
