import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/main-router.js";
import store from "./store/mainStore.js";
import axios from "axios";
import axiosSetup from "./helpers/axios.interceptor";
import UserService from "./services/user.service";
import ErrorDirective from "./directives/form.error.directive";
import moment from "moment";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUserSecret);

axiosSetup();

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
moment.locale("pl");

if (localStorage.getItem("token") !== null) {
    const token = localStorage.getItem("token");
    UserService.me(token);
} else {
    store.commit("destroyUser");
}

createApp(App)
    .use(store)
    .use(router)
    .directive("error", ErrorDirective)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");
