<template>
    <section class="people-box">
        <section class="people-box-data-wrapper">
            <section class="people-box__profile-wrapper">
                <img
                    src="../../../../../assets/icons/panel/Contacts/user-profile.svg"
                    class="people-box-data-wrapper__profile-pic"
                />
                <section class="people-box__lock-icon" v-if="isLocked">
                    <img
                        src="../../../../../assets/icons/components/lock.svg"
                    />
                </section>
            </section>
            <span
                class="
                    people-box-data-wrapper__text
                    people-box-data-wrapper__text--bold
                "
                :class="{
                    'people-box-data-wrapper__text--active': isActive,
                    'people-box-data-wrapper__text--unactive': !isActive,
                }"
                >{{ name }} {{ lastName }}</span
            >
            <span
                class="
                    people-box-data-wrapper__text
                    people-box-data-wrapper__text--mail
                "
                >{{ mail }}</span
            >
            <span class="people-box-data-wrapper__text">{{ number }}</span>
        </section>
        <section class="people-box-action-wrapper">
            <section class="people-box-action-wrapper__on-hover">
                <ButtonWithIconContacts icon="send-sms" text="Wyślij SMS" />
                <ButtonWithIconContacts icon="send-mail" text="Wyślij e-mail" />
            </section>
            <ButtonOnlyWithIcon
                icon="edit"
                @click="
                    () =>
                        addContactHandler(null, 'add-contact', {
                            id: id,
                            action: 'edit-contact',
                        })
                "
            />
            <ButtonOnlyWithIcon
                icon="delete"
                @click="() => handleDeleteClick(this.id)"
            />
        </section>
    </section>
</template>

<script>
import ContactService from "../../../../../services/contact.service";

import ButtonOnlyWithIcon from "../../../../BasicControls/ButtonOnlyWithIcon.vue";
import ButtonWithIconContacts from "../../../../BasicControls/ButtonWithIconContacts.vue";

export default {
    name: "People",
    props: {
        name: String,
        lastName: String,
        mail: String,
        number: String,
        id: Number,
        addContactHandler: Function,
        isActive: Boolean,
        isLocked: Boolean,
    },
    components: {
        ButtonOnlyWithIcon,
        ButtonWithIconContacts,
    },
    methods: {
        handleDeleteClick(id) {
            const actionToFire = () => {
                this.$store.state.userStore.isLoading = true;
                ContactService.deleteContact(id).then(() => {
                    ContactService.show().then((response) => {
                        this.$store.state.userStore.isLoading = false;
                        this.$emit("reloadGroups", response.data.data);
                    });
                });
            };
            this.$store.commit("showConfirmationAlert", actionToFire);
        },
    },
};
</script>

<style lang="scss" scoped>
.people-box-data-wrapper {
    display: flex;
    align-items: center;
    &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;

        &--bold {
            font-weight: 600;
            width: 248px;
            margin-left: 30px;
            position: relative;

            @media only screen and (max-width: 1600px) {
                width: 158px;
            }
        }

        &--active {
            &::after {
                content: "";
                position: absolute;
                left: -20px;
                top: 50%;
                transform: translateY(-50%);
                width: 8px;
                height: 8px;
                background: #60a800;
                box-shadow: 1px 2px 4px rgba(65, 114, 0, 0.16);
                border-radius: 100%;
            }
        }

        &--unactive {
            &::after {
                content: "";
                position: absolute;
                left: -20px;
                top: 50%;
                transform: translateY(-50%);
                width: 8px;
                height: 8px;
                background: #ffb800;
                box-shadow: 1px 2px 4px rgba(65, 114, 0, 0.16);
                border-radius: 100%;
            }
        }

        &--mail {
            width: 291px;

            @media only screen and (max-width: 1600px) {
                width: 238px;
            }
        }
    }
}

.people-box {
    padding: 14px 16px;
    background: #ffffff;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
    border-radius: 12px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover .people-box-action-wrapper__on-hover {
        opacity: 1;
    }

    &__profile-wrapper {
        position: relative;
    }

    &__lock-icon {
        position: absolute;
        top: -8px;
        left: -10px;
        width: 22px;
        height: 22px;
        background-color: #e8ecf2;
        border: 2px solid white;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.people-box-action-wrapper {
    display: flex;
    flex-wrap: wrap;

    &__on-hover {
        display: flex;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
}
</style>
