<template>
    <section class="add-point-bottom">
        <img
            src="../../../../assets/icons/components/go-back-arrow.svg"
            class="add-point-bottom__go-back"
            v-if="isAddPointToParent"
            @click="this.$emit('cancelAddPointToParent')"
        />
        <form
            class="add-point-bottom__input-wrapper"
            v-error="{ errors, input_key: 'name' }"
            @submit="
                (e) => {
                    e.preventDefault();
                    this.addPoint();
                }
            "
        >
            <input
                type="text"
                class="add-point-bottom__input"
                placeholder="Dodaj nazwę kolejnego punktu obrad..."
                v-model="pointName"
            />
            <button class="add-point-bottom__add-btn" type="submit">
                <img
                    src="../../../../assets/icons/panel/editSession/plus.svg"
                />
                <span class="add-point-bottom__btn-text"
                    >Dodaj punkt obrad</span
                >
            </button>
        </form>
        <ErrorMessage
            v-if="isError('name', this.errors)"
            :errorMsgs="passErrorMsg('name', this.errors)"
        />
    </section>
</template>

<script>
import ErrorMessage from "../../../BasicControls/ErrorMessage.vue";

import { passErrorMsg, isError } from "../../../../formValidation/main";

export default {
    name: "AddPointVottomMenu",
    data() {
        return {
            pointName: "",
        };
    },
    props: {
        session: Object,
        errors: Array,
        isAddPointToParent: Boolean,
    },
    components: {
        ErrorMessage,
    },
    methods: {
        addPoint() {
            this.$emit("addPoint", this.pointName);
            this.pointName = "";
        },
        passErrorMsg,
        isError,
    },
};
</script>

<style lang="scss" scoped>
.add-point-bottom {
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: calc(100% - 410px);
    z-index: 3;
    background-color: white;
    padding: 16px;
    border-radius: 12px;
    box-sizing: border-box;
    align-items: center;
    box-shadow: 0px 0px 24px rgba(76, 82, 107, 0.16);
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1600px) {
        width: calc(100% - 310px);
    }

    &__input-wrapper {
        background: #f4f6fa;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
        border-radius: 4px;
        display: flex;
        width: 100%;
    }

    &__input {
        border: 0px;
        width: 100%;
        font-size: 16px;
        line-height: 144%;
        background: #f4f6fa;
        padding: 0px 24px;
        border: 1px solid #dde0e7;

        &:focus {
            border: 1px solid #00082c;
        }

        &::placeholder {
            font-size: 16px;
            line-height: 144%;
            color: #808396;
        }
    }

    &__add-btn {
        width: 234px;
        height: 48px;
        background: #d80418;
        border-radius: 0px 4px 4px 0px;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #ad0313;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
    }

    &__button {
        width: 44px;
        height: 44px;
        background: #ffffff;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        margin-left: 8px;
    }

    &__btn-text {
        color: white;
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        margin-left: 10.5px;
    }

    &__go-back {
        margin-right: 20px;
        cursor: pointer;
    }
}
</style>
