import { createRouter, createWebHistory } from "vue-router";

import Login from "../views/Login.vue";
import Panel from "../views/Panel.vue";
import Home from "../components/Panel/Content/Home/Home.vue";
import EditMeetingPoints from "../components/Panel/Content/SessionPoints/EditMeetingPoints.vue";
import Kontakt from "../components/Panel/Content/Contacts/Contacts.vue";

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/:view",
        name: "Panel",
        component: Panel,
        children: [
            {
                path: "/home",
                component: Home,
            },
            {
                path: "/sessions/:sessionid/edit/groups/:groupid/",
                name: "sessionEdit",
                component: EditMeetingPoints,
            },
            {
                path: "/kontakty",
                component: Kontakt,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // try to access a restricted page + not logged in
    const publicPages = ["/"];
    const authRequired = !publicPages.includes(to.path);
    const token = localStorage.getItem("token");

    if (to.path === "/" && token) {
        return next("home");
    }

    if (authRequired && !token) {
        return next("/");
    }
    return next();
});

export default router;
