<template>
    <section class="edit-fileds">
        <section class="edit-fileds__name-wrapper">
            <label class="edit-fileds__label">Nazwa</label>
            <input
                type="text"
                placeholder="Wpisz nazwę..."
                v-model="name"
                class="edit-fileds__input"
                v-error="{ errors, input_key: 'name' }"
            />
            <ErrorMessage
                v-if="isError('name', this.errors)"
                :errorMsgs="passErrorMsg('name', this.errors)"
            />
        </section>
        <section v-if="attachment.type_id === 2" class="edit-fileds__text">
            <label class="edit-fileds__label">Treść</label>
            <section class="edit-fileds__wyswig">
                <section class="wyswig-actions">
                    <button
                        @click="editor.chain().focus().toggleBold().run()"
                        class="wyswig-actions__btn"
                        :class="{
                            'is-active':
                                editor !== null
                                    ? editor.isActive('bold')
                                    : false,
                        }"
                    >
                        <img
                            class="wyswig-actions__icon"
                            src="../../../../../assets/icons/components/bold.svg"
                        />
                    </button>
                    <button
                        @click="editor.chain().focus().toggleItalic().run()"
                        class="wyswig-actions__btn"
                        :class="{
                            'is-active':
                                editor !== null
                                    ? editor.isActive('italic')
                                    : false,
                        }"
                    >
                        <img
                            src="../../../../../assets/icons/components/italic.svg"
                            class="wyswig-actions__icon"
                        />
                    </button>
                    <button
                        @click="editor.chain().focus().toggleStrike().run()"
                        class="wyswig-actions__btn"
                        :class="{
                            'is-active':
                                editor !== null
                                    ? editor.isActive('strike')
                                    : false,
                        }"
                    >
                        <img
                            src="../../../../../assets/icons/components/strike.svg"
                            class="wyswig-actions__icon"
                        />
                    </button>
                    <button
                        @click="editor.chain().focus().unsetAllMarks().run()"
                        class="wyswig-actions__btn"
                    >
                        <img
                            src="../../../../../assets/icons/components/edit.svg"
                            class="wyswig-actions__icon"
                        />
                    </button>
                    <button
                        @click="editor.chain().focus().clearNodes().run()"
                        class="wyswig-actions__btn"
                    >
                        <img
                            src="../../../../../assets/icons/components/edit.svg"
                            class="wyswig-actions__icon"
                        />
                    </button>
                    <button
                        @click="
                            editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 1 })
                                .run()
                        "
                        class="wyswig-actions__btn"
                        :class="{
                            'is-active':
                                editor !== null
                                    ? editor.isActive('heading', { level: 1 })
                                    : false,
                        }"
                    >
                        <img
                            src="../../../../../assets/icons/components/edit.svg"
                            class="wyswig-actions__icon"
                        />
                    </button>
                    <button
                        @click="
                            editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 2 })
                                .run()
                        "
                        class="wyswig-actions__btn"
                        :class="{
                            'is-active':
                                editor !== null
                                    ? editor.isActive('heading', { level: 2 })
                                    : false,
                        }"
                    >
                        <img
                            src="../../../../../assets/icons/components/edit.svg"
                            class="wyswig-actions__icon"
                        />
                    </button>
                    <button
                        @click="editor.chain().focus().toggleBulletList().run()"
                        class="wyswig-actions__btn"
                        :class="{
                            'is-active':
                                editor !== null
                                    ? editor.isActive('bulletList')
                                    : false,
                        }"
                    >
                        <img
                            src="../../../../../assets/icons/components/bullet-list.svg"
                            class="wyswig-actions__icon"
                        />
                    </button>
                    <button
                        @click="
                            editor.chain().focus().toggleOrderedList().run()
                        "
                        class="wyswig-actions__btn"
                        :class="{
                            'is-active':
                                editor !== null
                                    ? editor.isActive('orderedList')
                                    : false,
                        }"
                    >
                        <img
                            src="../../../../../assets/icons/components/ordered-list.svg"
                            class="wyswig-actions__icon"
                        />
                    </button>
                    <button
                        @click="editor.chain().focus().setHardBreak().run()"
                        class="wyswig-actions__btn"
                    >
                        <img
                            src="../../../../../assets/icons/components/edit.svg"
                            class="wyswig-actions__icon"
                        />
                    </button>
                    <button
                        @click="editor.chain().focus().undo().run()"
                        class="wyswig-actions__btn"
                    >
                        <img
                            src="../../../../../assets/icons/components/undo.svg"
                            class="wyswig-actions__icon"
                        />
                    </button>
                    <button
                        @click="editor.chain().focus().redo().run()"
                        class="wyswig-actions__btn"
                    >
                        <img
                            src="../../../../../assets/icons/components/redo.svg"
                            class="wyswig-actions__icon"
                        />
                    </button>
                </section>
                <editor-content :editor="editor" />
            </section>
        </section>
        <section class="edit-fileds__bottom">
            <button
                class="edit-fileds__bottom-button"
                @click="this.$emit('closeEdit')"
            >
                Anuluj
            </button>
            <button
                class="
                    edit-fileds__bottom-button edit-fileds__bottom-button--red
                "
                @click="handleSaveClick"
            >
                Zapisz
            </button>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import ErrorMessage from "../../../../BasicControls/ErrorMessage.vue";

import AttachmentService from "../../../../../services/attachments.service";
import {
    passErrorMsg,
    isError,
    setErrors,
} from "../../../../../formValidation/main";

export default {
    name: "EditAttachment",
    data() {
        return {
            name: "",
            editor: null,
            isLoading: false,
            storedNoteData: {},
            errors: [],
        };
    },
    components: {
        EditorContent,
        LoaderInside,
        ErrorMessage,
    },
    props: {
        attachment: Object,
    },
    methods: {
        handleSaveClick() {
            this.isLoading = true;
            this.errors = [];
            if (this.attachment.type_id === 1) {
                const data = this.attachment;
                data.name = this.name;
                AttachmentService.updateFile(this.attachment.id, data)
                    .then(() => {
                        this.$emit("reload", this.name);
                        this.isLoading = false;
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.errors = setErrors(err);
                    });
            } else {
                const data = {
                    name: this.name,
                    description: this.editor.getHTML(),
                    public: this.storedNoteData.public,
                    show_in_register: this.storedNoteData.show_in_register,
                };
                AttachmentService.updateNote(this.attachment.id, data)
                    .then(() => {
                        this.$emit("reload", this.name);
                        this.isLoading = false;
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.errors = setErrors(err);
                    });
            }
        },
        passErrorMsg,
        isError,
        setErrors,
    },
    mounted() {
        this.name = this.attachment.name;
        this.editor = new Editor({
            content: "",
            extensions: [StarterKit],
        });

        if (this.attachment.type_id === 2) {
            this.isLoading = true;
            AttachmentService.getNote(this.attachment.id).then((response) => {
                this.editor.commands.setContent(response.data.note.description);
                this.storedNoteData = response.data;
                this.isLoading = false;
            });
        }
    },
    beforeUnmount() {
        this.editor.destroy();
    },
};
</script>

<style lang="scss" scoped>
.edit-fileds {
    &__name-wrapper {
        padding: 0px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        padding-top: 15px;
        border-top: 1px solid #dde0e7;
    }

    &__text {
        padding: 0px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        padding-top: 18px;
    }

    &__label {
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        margin-bottom: 6px;
        color: #00082c;
    }

    &__input {
        height: 48px;
        padding: 8px 16px 8px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;

        &:focus {
            border: 1px solid #00082c;
        }
    }

    &__bottom {
        padding: 0px 16px 8px 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
    }

    &__bottom-button {
        padding: 0;
        width: 225px;
        height: 46px;
        background: #ffffff;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        color: #00082c;
        margin-bottom: 18px;
        transition: all 0.2s ease-in-out;
        background: #e8ebef;

        &--red {
            border: 0px;
            background: #d80418;
            color: white;
            transition: all 0.2s ease-in-out;

            &:hover {
                background: #ad0313;
            }
        }
    }

    &__wyswig {
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        border-radius: 4px;
        height: 213px;
        max-height: 213px;
        max-width: 468px;
    }
}
</style>

<style lang="scss">
.ProseMirror {
    border-top: 1px solid #dde0e7;
    max-height: 144px !important;
    overflow-y: auto;
    padding: 12.5px 16px;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background: #eef1f7;
        border-radius: 30px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #eef1f7;
    }
    &::-webkit-scrollbar-track {
        background: #ffffff;
        border: 0.8px solid #dce4ee;
        border-radius: 0px;
        box-shadow: inset 0px 0px 0px 0px #f0f0f0;
    }

    &:focus {
        outline: none;
    }
}

.is-active {
    background-color: black !important;

    img {
        filter: brightness(0) invert(1);
    }
}

.wyswig-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 5px;

    &__btn {
        border-radius: 5px;
        width: 21px;
        height: 21px;
        background-color: transparent;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        widows: 13px;
        height: 13px;
    }
}
</style>
