<template>
    <section class="session">
        <h2 class="session__title" v-if="session.number">
            Sesja nr:
            {{
                session.number !== null ? numberConversion(session.number) : "0"
            }}
        </h2>
        <section class="session__top">
            <section class="session__top-left">
                <section class="session__top-box">
                    <img
                        src="../../../../assets/icons/panel/editSession/meeting-place.svg"
                    /><span class="session__top-text session__top-text--name">{{
                        session.name
                    }}</span>
                </section>
                <section class="session__top-box">
                    <img
                        src="../../../../assets/icons/panel/editSession/meeting-date.svg"
                    /><span class="session__top-text">{{ getDate }}</span>
                </section>
                <section class="session__top-box">
                    <img
                        src="../../../../assets/icons/panel/editSession/meeting-time.svg"
                    /><span class="session__top-text"
                        >Godzina: {{ getTime }}</span
                    >
                </section>
            </section>
            <section class="session-top-btn-wrapper">
                <button
                    class="
                        session-top-btn-wrapper__button
                        session-top-btn-wrapper__button--white
                    "
                    @click="isEditMeetingVisible = true"
                >
                    <img src="../../../../assets/icons/components/edit.svg" />
                    <span
                        class="
                            session-top-btn-wrapper__text
                            session-top-btn-wrapper__text--white
                        "
                        >Edytuj dane sesji</span
                    >
                </button>
                <button
                    class="session-top-btn-wrapper__button"
                    @click="isAddVisible = true"
                >
                    <img
                        src="../../../../assets/icons/panel/editSession/add-point.svg"
                    />
                    <span class="session-top-btn-wrapper__text"
                        >Dodaj punkt</span
                    >
                </button>
            </section>
        </section>
        <SessionPointsList
            :items="agendaItems"
            v-if="agendaItems.length > 0 && isLoaded"
            :session="session"
            @end="setList"
            @refreshList="getList"
            @reloadData="getList"
            :firstLevel="groupData.agenda_number_format_1_id"
            :secondLevel="groupData.agenda_number_format_2_id"
            :thirdLevel="groupData.agenda_number_format_3_id"
            :checkedPointIds="point_ids"
            @setPointIds="
                (value) => {
                    setPointsIds(value);
                    this.isAddPointToParent = false;
                }
            "
        />
        <section v-else>
            <p>Brak punktów</p>
        </section>
        <AddPointBottomMenu
            :session="session"
            :errors="errors"
            :isAddPointToParent="isAddPointToParent"
            v-if="point_ids.length === 0 || isAddPointToParent"
            @setSession="(session) => (this.session = session)"
            @addPoint="(point) => this.addPoint(point)"
            @refreshList="getList"
            @cancelAddPointToParent="isAddPointToParent = false"
        />
        <EditPointsBottomMenu
            v-if="point_ids.length > 0 && !isAddPointToParent"
            :point_ids="point_ids"
            @cancel="point_ids = []"
            @checkAll="(points) => (point_ids = points)"
            :agendaItems="agendaItems"
            @addPoint="(point) => this.addPoint(point)"
            @reloadData="getList"
            @refreshList="getList"
            @pointMoved="setList"
            @addPointAsChildren="isAddPointToParent = true"
        />
        <EditMeeting
            v-if="isEditMeetingVisible"
            @setVisible="() => (isEditMeetingVisible = false)"
            :session="session"
            @setSession="(session) => (this.session = session)"
        />
        <EditPoint
            v-if="isAddVisible"
            :isAdd="true"
            @closeEdit="isAddVisible = false"
            @reloadData="getList"
            :order="agendaItems.length"
        />
    </section>
</template>

<script>
import SessionService from "../../../../services/session.service";
import ContactService from "../../../../services/contact.service";
import moment from "moment";
import { setErrors } from "../../../../formValidation/main";
import {
    switchToRoman,
    switchIndexToLetter,
} from "../../../../helpers/index.conversion";

import EditMeeting from "../Home/Modals/EditMeeting.vue";
import AddPointBottomMenu from "./AddPointBottomMenu.vue";
import SessionPointsList from "./SessionPointsList.vue";
import EditPointsBottomMenu from "./EditPointsBottomMenu.vue";
import EditPoint from "./EditPointModal/EditPoint.vue";

export default {
    name: "EditMeetingPoints",
    data() {
        return {
            session: {},
            agendaItems: [],
            isLoaded: false,
            errors: [],
            isEditMeetingVisible: false,
            point_ids: [],
            isAddVisible: false,
            groupData: {},
            isAddPointToParent: false,
        };
    },
    components: {
        AddPointBottomMenu,
        SessionPointsList,
        EditMeeting,
        EditPointsBottomMenu,
        EditPoint,
    },
    mounted() {
        this.getData();
    },
    computed: {
        getDate() {
            return `${moment(this.session.meeting_date).format(
                "dddd"
            )}, ${moment(this.session.meeting_date).format("DD.MM.YYYY")}`;
        },
        getTime() {
            return moment(this.session.meeting_date).format("LT");
        },
    },
    watch: {
        $route() {
            this.getData();
            this.point_ids = [];
        },
    },
    methods: {
        setPointsIds(ids) {
            this.point_ids = ids;
        },
        addPoint(pointName) {
            const newPoint = {
                name: pointName,
                order: this.agendaItems.length,
            };

            if (this.isAddPointToParent) {
                newPoint["parent_id"] = this.point_ids[0];
            }

            this.$store.commit("showLoader");
            this.errors = [];
            SessionService.addPoint(this.session.id, newPoint)
                .then(() => {
                    this.$store.commit("hideLoader");
                    window.scrollTo(0, document.body.scrollHeight);
                    SessionService.getPoints(this.session.id).then(
                        (response) => {
                            this.agendaItems = response.data.data;
                            this.isAddPointToParent = false;
                        }
                    );
                })
                .catch((err) => {
                    this.$store.commit("hideLoader");
                    this.errors = setErrors(err);
                });
        },
        getData() {
            this.$store.commit("showLoader");
            const id = this.$route.params.sessionid;
            const groupID = this.$route.params.groupid;

            if (id !== undefined && groupID !== undefined) {
                ContactService.showGroupData(groupID).then((response) => {
                    this.groupData = response.data;
                    const anyMatched = response.data.sessions.find(
                        (session) => (session.id = id)
                    );
                    if (anyMatched !== undefined) {
                        SessionService.getSession(id).then((response) => {
                            this.session = response.data;
                            this.isLoaded = true;
                            this.$store.commit("hideLoader");
                            this.agendaItems = response.data.agendaItems;
                            this.isLoaded = true;
                        });
                    } else {
                        this.$router.push("/home");
                        this.$store.commit("showError");
                    }
                });
            } else {
                this.$store.commit("hideLoader");
            }
        },
        getList() {
            SessionService.getPoints(this.session.id).then((response) => {
                this.$store.commit("hideLoader");
                this.agendaItems = response.data.data;
            });
        },
        setList() {
            const newList = this.orderRecursive(this.agendaItems);
            const data = {
                items: newList,
            };
            const id = this.$route.params.sessionid;
            if (newList.length > 0) {
                SessionService.movePoints(id, data);
            }
        },
        orderRecursive(list) {
            return list.map((item, key) => {
                item.order = key + 1;
                item.children = this.orderRecursive(item.children);
                return item;
            });
        },
        numberConversion(index) {
            switch (this.groupData.session_number_format_id) {
                case 1:
                    return `${switchToRoman(index)}`;
                case 2:
                    return `${index}.`;
                case 3:
                    return `${index})`;
                case 4:
                    return `${switchIndexToLetter(index)})`;
                default:
                    return `${index}.`;
            }
        },
        setErrors,
    },
};
</script>

<style lang="scss" scoped>
.session {
    padding: 48px;
    background-color: #f4f6fa;
    padding-bottom: 100px;

    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    &__top-left {
        display: flex;
        background-color: #e8ecf2;
        border-radius: 8px;
    }

    &__top-box {
        display: flex;
        align-items: center;
        padding: 14px 32px;
        position: relative;

        @media only screen and (max-width: 1600px) {
            padding: 14px 12px;
        }

        &:nth-child(1)::after,
        &:nth-child(2)::after {
            content: "";
            position: absolute;
            top: 12px;
            right: 0px;
            width: 1px;
            height: 24px;
            background-color: #d1d6df;
        }
    }

    &__title {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 28px;
        line-height: 128%;
        color: #00082c;
    }

    &__top-text {
        margin-left: 11px;
        white-space: nowrap;

        &--name {
            white-space: normal;
            max-width: 222px;
            width: 222px;
        }
    }
}

.session-top-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &__button {
        padding: 13.5px 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #d80418;
        border-radius: 4px;
        border: 0px;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #ad0313;
        }

        &--white {
            border: 1px solid #bbc2ce;
            background-color: white;
            margin-right: 12px;

            &:hover {
                background-color: #e8ebef;
            }
        }
    }

    &__text {
        color: white;
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        margin-left: 8px;

        &--white {
            color: #00082c;
        }
    }
}
</style>
