<template>
    <section class="meetings">
        <section class="meetings-top">
            <section class="meetings-top__left">
                <img
                    src="../../../../../assets/icons/panel/home/warning.svg"
                    class="meetings-top__icon"
                />
                <span class="meetings-top__title">Nadchodzące posiedzenia</span>
            </section>
            <section class="meetings-top__right">
                <section
                    v-for="button in filterButtons"
                    :key="button.id"
                    class="meetings-top__btn-wrapper"
                >
                    <FilterButton
                        :name="button.name"
                        :selected="button.selected"
                        @click="button.handler"
                    />
                </section>
            </section>
        </section>
        <section class="meetings__bottom" v-if="currentView === 'all'">
            <SmallLoader v-if="isLoading" />
            <section v-for="meeting in meetings" :key="meeting.id">
                <MeetingsWrapper
                    :name="meeting.name"
                    :list="meeting.meetingsList"
                />
            </section>
        </section>
        <section class="meetings__bottom" v-else>
            <SmallLoader v-if="isLoading" />
            <MeetingsWrapper
                :name="meetings[currentView].name"
                :list="meetings[currentView].meetingsList"
            />
        </section>
    </section>
</template>

<script>
import MeetingsService from "../../../../../services/meetings.service";

import MeetingsWrapper from "./MeetingsWrapper.vue";
import FilterButton from "./FilterButton";
import SmallLoader from "../../../../BasicControls/SmallLoader.vue";

import moment from "moment";

export default {
    name: "Meetings",
    data() {
        return {
            filterButtons: [
                {
                    id: 0,
                    name: "Wszystkie",
                    selected: true,
                    handler: () => {
                        this.reloadAllData();
                        this.handleChangeFilterButton(0);
                    },
                },
                {
                    id: 1,
                    name: "Dzisiaj",
                    selected: false,
                    handler: () => {
                        this.sessionsToday();
                        this.currentView = 0;
                        this.handleChangeFilterButton(1);
                    },
                },
                {
                    id: 2,
                    name: "Jutro",
                    selected: false,
                    handler: () => {
                        this.sessionsTomorrow();
                        this.currentView = 1;
                        this.handleChangeFilterButton(2);
                    },
                },
                {
                    id: 3,
                    name: "Ten tydzień",
                    selected: false,
                    handler: () => {
                        this.sessionsThisWeek();
                        this.currentView = 2;
                        this.handleChangeFilterButton(3);
                    },
                },
                {
                    id: 4,
                    name: "Ten miesiąc",
                    selected: false,
                    handler: () => {
                        this.sessionsThisMonth();
                        this.currentView = 3;
                        this.handleChangeFilterButton(4);
                    },
                },
            ],
            isLoading: false,
            currentView: "all",
            meetings: [
                {
                    id: 0,
                    filterDate: "today",
                    name: "dzisiaj",
                    meetingsList: [],
                },
                {
                    id: 1,
                    filterDate: "tommorow",
                    name: "jutro",
                    meetingsList: [],
                },
                {
                    id: 2,
                    filterDate: "thisWeek",
                    name: "Ten tydzień",
                    meetingsList: [],
                },
                {
                    id: 3,
                    filterDate: "thisMonth",
                    name: "Ten miesiąc",
                    meetingsList: [],
                },
                {
                    id: 4,
                    filterDate: "other",
                    name: "Pozostałe",
                    meetingsList: [],
                },
            ],
        };
    },
    components: {
        MeetingsWrapper,
        FilterButton,
        SmallLoader,
    },
    mounted() {
        this.isLoading = true;
        this.reloadAllData();
    },
    computed: {
        meetingsReload() {
            return this.$store.state.appStore.groups;
        },
    },
    watch: {
        meetingsReload() {
            this.meetings[0].meetingsList = [];
            this.meetings[1].meetingsList = [];
            this.meetings[2].meetingsList = [];
            this.meetings[3].meetingsList = [];
            this.reloadAllData();
        },
    },
    methods: {
        reloadAllData() {
            this.isLoading = true;
            MeetingsService.sessions().then((response) => {
                this.isLoading = false;
                this.currentView = "all";
                this.meetings[0].meetingsList = [];
                this.meetings[1].meetingsList = [];
                this.meetings[2].meetingsList = [];
                this.meetings[3].meetingsList = [];

                response.data.data.forEach((item) => {
                    const today = moment().startOf("day").format();
                    const tomorrow = moment()
                        .add(1, "days")
                        .startOf("day")
                        .format();
                    const endOfWeek = moment().endOf("isoWeek").format();
                    const endOfMonth = moment().endOf("month").format();
                    const inToday = moment().endOf("day").format();

                    const itemDate = moment(item.meeting_date).format();

                    if (moment(itemDate).isBetween(today, inToday)) {
                        this.meetings[0].meetingsList.push(item);
                    }

                    const endOfTommorrow = moment(tomorrow)
                        .endOf("day")
                        .format();
                    if (moment(itemDate).isBetween(tomorrow, endOfTommorrow)) {
                        this.meetings[1].meetingsList.push(item);
                    }

                    if (moment(itemDate).isBetween(endOfTommorrow, endOfWeek)) {
                        this.meetings[2].meetingsList.push(item);
                    }

                    if (moment(itemDate).isBetween(endOfWeek, endOfMonth)) {
                        this.meetings[3].meetingsList.push(item);
                    }

                    if (moment(itemDate).isSameOrAfter(endOfMonth)) {
                        this.meetings[4].meetingsList.push(item);
                    }
                });
            });
        },
        sessionsToday() {
            this.isLoading = true;
            MeetingsService.sessionsToday().then((response) => {
                this.meetings[0].meetingsList = response.data.data;
                this.isLoading = false;
                this.isTodayLoaded = true;
            });
        },
        sessionsTomorrow() {
            this.isLoading = true;
            MeetingsService.sessionsTomorrow().then((response) => {
                this.meetings[1].meetingsList = response.data.data;
                this.isLoading = false;
                this.isTommorowLoaded = true;
            });
        },
        sessionsThisWeek() {
            this.isLoading = true;
            MeetingsService.sessionsThisWeek().then((response) => {
                this.meetings[2].meetingsList = response.data.data;
                this.isLoading = false;
                this.isThisWeekLoaded = true;
            });
        },
        sessionsThisMonth() {
            this.isLoading = true;
            MeetingsService.sessionsThisMonth().then((response) => {
                this.meetings[3].meetingsList = response.data.data;
                this.isLoading = false;
                this.isThisMonthLoaded = true;
            });
        },
        handleChangeFilterButton(id) {
            this.filterButtons = this.filterButtons.map((item) => {
                if (item.id === id) {
                    item.selected = true;
                } else {
                    item.selected = false;
                }
                return item;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/scss/breakpoints.scss";

.meetings {
    max-height: 86vh;
    margin-top: 24px;
    background-color: white;
    background: #ffffff;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    border-radius: 12px;
    box-sizing: border-box;
    width: 995px;
    max-width: initial;
    width: calc(100vw - 924px);

    @media only screen and(max-width:1850px) {
        margin-right: 0px;
        max-width: initial;
        width: 100%;
    }

    &__bottom {
        max-height: 70vh;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #eef1f7;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #eef1f7;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }
    }
}

.meetings-top {
    border-bottom: 1px solid #dce4ee;
    padding: 20px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icon {
        margin-right: 12px;
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 132%;
        color: #00082c;
    }

    &__right {
        display: flex;
    }
}
</style>
