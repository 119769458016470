import ApiService from "./api.service";

class AttachmentsService extends ApiService {
    addFile(id, data) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };
        return this.post(`/agenda-items/${id}/attachment/file`, data, headers);
    }

    addNote(id, data) {
        return this.post(`/agenda-items/${id}/attachment/note`, data);
    }

    getNote(id) {
        return this.get(`/attachment/note/${id}`);
    }

    downloadAttachment(id) {
        return this.get(`/attachment-download/${id}`, { responseType: "blob" });
    }

    updateFile(id, data) {
        return this.put(`/attachment/file/${id}`, data);
    }

    updateNote(id, data) {
        return this.put(`/attachment/note/${id}`, data);
    }

    deleteFile(id) {
        return this.delete(`/attachment/file/${id}`);
    }

    deleteNote(id) {
        return this.delete(`/attachment/note/${id}`);
    }
}

export default new AttachmentsService();
