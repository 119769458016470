import ApiService from "./api.service";

class SessionService extends ApiService {
    getSession(id) {
        return this.get(`/sessions/${id}`);
    }
    getSessionsBetweenDates(date_from, date_to) {
        return this.post("/sessions/between", { date_from, date_to });
    }
    update(id, data) {
        return this.patch(`/sessions/${id}`, data);
    }
    getGroups() {
        return this.get("/groups");
    }
    getPoints(id) {
        return this.get(`/sessions/${id}/agenda-items`);
    }
    getPoint(id) {
        return this.get(`/agenda-items/${id}`);
    }
    addPoint(id, data) {
        return this.post(`/sessions/${id}/agenda-items`, data);
    }
    removePoint(id) {
        return this.delete(`/agenda-items/${id}`);
    }
    removeFewPoints(ids) {
        return this.delete(`/agenda-items/bulk`, { agenda_items_ids: ids });
    }
    movePoints(id, data) {
        return this.post(`/sessions/${id}/agenda-items/bulk`, data);
    }
    editPoint(id, data) {
        return this.put(`/agenda-items/${id}`, data);
    }
    duplicatePoints(id, item_id) {
        return this.post(`/sessions/${id}/agenda-items/copy`, {
            id: item_id,
        });
    }
}

export default new SessionService();
