<template>
    <button class="button-only-icon">
        <img
            :src="require(`../../assets/icons/components/${icon}.svg`)"
            class="button-only-icon__icon"
        />
    </button>
</template>

<script>
export default {
    name: "ButtonOnlyWithIcon",
    props: {
        icon: String,
    },
};
</script>

<style lang="scss" scoped>
.button-only-icon {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #bbc2ce;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #e8ebef;
    }
}
</style>
