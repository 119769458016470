<template>
    <section class="news-data">
        <img
            src="../../../../../assets/imgs/news.png"
            class="news-data__thumbnail"
        />
        <section>
            <h5 class="news-data__title">
                {{ data.title }}
            </h5>
            <section class="news-data__wrapper">
                <img
                    class="news-data__icon"
                    src="../../../../../assets/icons/panel/home/callendar-gray.svg"
                />
                <span class="news-data__date">{{ data.date }}</span>
            </section>
        </section>
    </section>
</template>

<script>
export default {
    name: "Information",
    props: {
        data: Object,
    },
};
</script>

<style lang="scss" scoped>
.news-data {
    margin: 0px 16px;
    padding: 28px 0px;
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #dce4ee;
    display: flex;
    align-items: center;

    &__thumbnail {
        width: 119px;
        margin-right: 24px;
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 132%;
        color: #00082c;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    &__date {
        font-weight: 500;
        font-size: 12px;
        line-height: 145%;
        color: #666b80;
        margin-left: 8px;
    }
}
</style>
