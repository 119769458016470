import axios from "axios";

axios.defaults.headers.common = {
    Accept: "application/json",
    "Content-Type": "application/json",
};

export default class ApiService {
    post(url, data = {}, headers = {}) {
        return axios.post(url, data, headers);
    }

    get(url, headers = {}) {
        return axios.get(url, headers);
    }

    patch(url, data) {
        return axios.patch(url, data);
    }

    put(url, data) {
        return axios.put(url, data);
    }

    delete(url, data) {
        return axios.delete(url, { data: data });
    }
}
